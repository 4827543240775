/*!
 * Made with <3 by Webpixels
 * Please purchase a license or subscription before using this theme
 * Website: https://webpixels.io
 * -----------------------------------------------------------------
 * Our utilities are built using the Bootstrap API
 * Learn more: https://webpixels.io/docs/css
 */
.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .15s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.bg-soft-primary {
  background-color: #cfd1d4 !important;
}

.bg-soft-secondary {
  background-color: #e6f5d0 !important;
}

.bg-soft-success {
  background-color: #ccf5e7 !important;
}

.bg-soft-info {
  background-color: #ccf6ff !important;
}

.bg-soft-warning {
  background-color: #ffe8cc !important;
}

.bg-soft-danger {
  background-color: #ffd6e0 !important;
}

.bg-soft-light {
  background-color: #fdfdfd !important;
}

.bg-soft-dark {
  background-color: #cfd1d4 !important;
}

.bg-none {
  background-image: none !important;
}

.bg-between {
  background-position: 0, 100%;
  background-repeat: no-repeat;
  background-size: auto 90%;
}

@media (min-width: 576px) {
  .bg-sm-none {
    background-image: none !important;
  }

  .bg-sm-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

@media (min-width: 768px) {
  .bg-md-none {
    background-image: none !important;
  }

  .bg-md-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

@media (min-width: 992px) {
  .bg-lg-none {
    background-image: none !important;
  }

  .bg-lg-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

@media (min-width: 1200px) {
  .bg-xl-none {
    background-image: none !important;
  }

  .bg-xl-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

@media (min-width: 1400px) {
  .bg-xxl-none {
    background-image: none !important;
  }

  .bg-xxl-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

.gradient-top {
  background-image: linear-gradient(to top, var(--x-gradient)) !important;
}

.gradient-top-right {
  background-image: linear-gradient(to top right, var(--x-gradient)) !important;
}

.gradient-right {
  background-image: linear-gradient(to right, var(--x-gradient)) !important;
}

.gradient-bottom-right {
  background-image: linear-gradient(to bottom right, var(--x-gradient)) !important;
}

.gradient-bottom {
  background-image: linear-gradient(to bottom, var(--x-gradient)) !important;
}

.gradient-bottom-left {
  background-image: linear-gradient(to bottom left, var(--x-gradient)) !important;
}

.gradient-left {
  background-image: linear-gradient(to left, var(--x-gradient)) !important;
}

.gradient-top-left {
  background-image: linear-gradient(to top left, var(--x-gradient)) !important;
}

.start-transparent, .start-transparent-hover:hover {
  --x-start-color: transparent;
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue, .start-blue-hover:hover {
  --x-start-color: var(--x-blue);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo, .start-indigo-hover:hover {
  --x-start-color: var(--x-indigo);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple, .start-purple-hover:hover {
  --x-start-color: var(--x-purple);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink, .start-pink-hover:hover {
  --x-start-color: var(--x-pink);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red, .start-red-hover:hover {
  --x-start-color: var(--x-red);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange, .start-orange-hover:hover {
  --x-start-color: var(--x-orange);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow, .start-yellow-hover:hover {
  --x-start-color: var(--x-yellow);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green, .start-green-hover:hover {
  --x-start-color: var(--x-green);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal, .start-teal-hover:hover {
  --x-start-color: var(--x-teal);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan, .start-cyan-hover:hover {
  --x-start-color: var(--x-cyan);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-black, .start-black-hover:hover {
  --x-start-color: var(--x-black);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-white, .start-white-hover:hover {
  --x-start-color: var(--x-white);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray, .start-gray-hover:hover {
  --x-start-color: var(--x-gray);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-dark, .start-gray-dark-hover:hover {
  --x-start-color: var(--x-gray-dark);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.middle-transparent, .middle-transparent-hover:hover {
  --x-middle-color: transparent;
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.middle-blue, .middle-blue-hover:hover {
  --x-middle-color: var(--x-blue);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(0, 153, 255, 0));
}

.middle-indigo, .middle-indigo-hover:hover {
  --x-middle-color: var(--x-indigo);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(92, 96, 245, 0));
}

.middle-purple, .middle-purple-hover:hover {
  --x-middle-color: var(--x-purple);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(137, 87, 255, 0));
}

.middle-pink, .middle-pink-hover:hover {
  --x-middle-color: var(--x-pink);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(255, 87, 154, 0));
}

.middle-red, .middle-red-hover:hover {
  --x-middle-color: var(--x-red);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(255, 51, 102, 0));
}

.middle-orange, .middle-orange-hover:hover {
  --x-middle-color: var(--x-orange);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(255, 140, 0, 0));
}

.middle-yellow, .middle-yellow-hover:hover {
  --x-middle-color: var(--x-yellow);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(255, 187, 0, 0));
}

.middle-green, .middle-green-hover:hover {
  --x-middle-color: var(--x-green);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(0, 204, 136, 0));
}

.middle-teal, .middle-teal-hover:hover {
  --x-middle-color: var(--x-teal);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(34, 221, 221, 0));
}

.middle-cyan, .middle-cyan-hover:hover {
  --x-middle-color: var(--x-cyan);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(0, 212, 255, 0));
}

.middle-black, .middle-black-hover:hover {
  --x-middle-color: var(--x-black);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.middle-white, .middle-white-hover:hover {
  --x-middle-color: var(--x-white);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(255, 255, 255, 0));
}

.middle-gray, .middle-gray-hover:hover {
  --x-middle-color: var(--x-gray);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(75, 85, 99, 0));
}

.middle-gray-dark, .middle-gray-dark-hover:hover {
  --x-middle-color: var(--x-gray-dark);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(31, 41, 55, 0));
}

.end-transparent, .end-transparent-hover:hover {
  --x-end-color: transparent;
}

.end-blue, .end-blue-hover:hover {
  --x-end-color: var(--x-blue);
}

.end-indigo, .end-indigo-hover:hover {
  --x-end-color: var(--x-indigo);
}

.end-purple, .end-purple-hover:hover {
  --x-end-color: var(--x-purple);
}

.end-pink, .end-pink-hover:hover {
  --x-end-color: var(--x-pink);
}

.end-red, .end-red-hover:hover {
  --x-end-color: var(--x-red);
}

.end-orange, .end-orange-hover:hover {
  --x-end-color: var(--x-orange);
}

.end-yellow, .end-yellow-hover:hover {
  --x-end-color: var(--x-yellow);
}

.end-green, .end-green-hover:hover {
  --x-end-color: var(--x-green);
}

.end-teal, .end-teal-hover:hover {
  --x-end-color: var(--x-teal);
}

.end-cyan, .end-cyan-hover:hover {
  --x-end-color: var(--x-cyan);
}

.end-black, .end-black-hover:hover {
  --x-end-color: var(--x-black);
}

.end-white, .end-white-hover:hover {
  --x-end-color: var(--x-white);
}

.end-gray, .end-gray-hover:hover {
  --x-end-color: var(--x-gray);
}

.end-gray-dark, .end-gray-dark-hover:hover {
  --x-end-color: var(--x-gray-dark);
}

.filter {
  --bs-blur: 0;
  --bs-contrast: 0;
  --bs-grayscale: 0;
  --bs-hue-rotate: 0;
  --bs-drop-shadow: 0;
  filter: blur(var(--bs-blur));
}

.transform {
  --x-translate-x: 0;
  --x-translate-y: 0;
  --x-translate-z: 0;
  --x-perspective: 0;
  --x-rotate: 0;
  --x-rotate-x: 0;
  --x-rotate-y: 0;
  --x-skew-x: 0;
  --x-skew-y: 0;
  --x-scale-x: 1;
  --x-scale-y: 1;
  transform: translateX(var(--x-translate-x)) translateY(var(--x-translate-y)) perspective(var(--x-perspective)) rotate(var(--x-rotate)) rotateX(var(--x-rotate-x)) rotateY(var(--x-rotate-y)) skewX(var(--x-skew-x)) skewY(var(--x-skew-y)) scaleX(var(--x-scale-x)) scaleY(var(--x-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.h-calc {
  --bs-h: 0px;
  height: calc(100vh - var(--bs-h));
}

.w-calc {
  --bs-w: 0px;
  width: calc(100vw - var(--bs-w));
}

@media (min-width: 576px) {
  .h-sm-calc {
    --bs-h-sm: 0px;
    height: calc(100vh - var(--bs-h-sm));
  }

  .w-sm-calc {
    --bs-w-sm: 0px;
    width: calc(100vw - var(--bs-w-sm));
  }
}

@media (min-width: 768px) {
  .h-md-calc {
    --bs-h-md: 0px;
    height: calc(100vh - var(--bs-h-md));
  }

  .w-md-calc {
    --bs-w-md: 0px;
    width: calc(100vw - var(--bs-w-md));
  }
}

@media (min-width: 992px) {
  .h-lg-calc {
    --bs-h-lg: 0px;
    height: calc(100vh - var(--bs-h-lg));
  }

  .w-lg-calc {
    --bs-w-lg: 0px;
    width: calc(100vw - var(--bs-w-lg));
  }
}

@media (min-width: 1200px) {
  .h-xl-calc {
    --bs-h-xl: 0px;
    height: calc(100vh - var(--bs-h-xl));
  }

  .w-xl-calc {
    --bs-w-xl: 0px;
    width: calc(100vw - var(--bs-w-xl));
  }
}

@media (min-width: 1400px) {
  .h-xxl-calc {
    --bs-h-xxl: 0px;
    height: calc(100vh - var(--bs-h-xxl));
  }

  .w-xxl-calc {
    --bs-w-xxl: 0px;
    width: calc(100vw - var(--bs-w-xxl));
  }
}

.floatfix:before, .floatfix:after {
  content: "";
  display: table;
}

.floatfix:after {
  clear: both;
}

.scrollable-x {
  white-space: nowrap;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;
  overflow-y: hidden;
}

.scrollable-x::-webkit-scrollbar {
  display: none;
}

.scrollable-y {
  white-space: nowrap;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
}

.scrollable-y::-webkit-scrollbar {
  display: none;
}

.border-body {
  --x-border-color: var(--x-body-bg);
}

.offset-start-screen-sm {
  padding-left: max(.75rem, 50% - 320px + .75rem);
}

.offset-end-screen-sm {
  padding-right: max(.75rem, 50% - 320px + .75rem);
}

.offset-start-screen-md {
  padding-left: max(.75rem, 50% - 384px + .75rem);
}

.offset-end-screen-md {
  padding-right: max(.75rem, 50% - 384px + .75rem);
}

.offset-start-screen-lg {
  padding-left: max(.75rem, 50% - 512px + .75rem);
}

.offset-end-screen-lg {
  padding-right: max(.75rem, 50% - 512px + .75rem);
}

.offset-start-screen-xl {
  padding-left: max(.75rem, 50% - 640px + .75rem);
}

.offset-end-screen-xl {
  padding-right: max(.75rem, 50% - 640px + .75rem);
}

.offset-start-screen-xxl {
  padding-left: max(.75rem, 50% - 768px + .75rem);
}

.offset-end-screen-xxl {
  padding-right: max(.75rem, 50% - 768px + .75rem);
}

[class*="faded"], [class*="blured"] {
  position: relative;
}

[class*="faded"]:after, [class*="blured"]:after {
  content: "";
  width: 100%;
  height: 3.9375rem;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

.faded-top:after {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#a6000000", GradientType= 0);
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%);
  top: 0;
}

.faded-bottom:after {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#a6000000", GradientType= 0);
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%);
  bottom: 0;
}

.blured-top:after {
  -webkit-backdrop-filter: blur(10px) saturate(200%);
  backdrop-filter: blur(10px) saturate(200%);
  background: rgba(255, 255, 255, .75);
  top: 0;
}

.blured-bottom:after {
  -webkit-backdrop-filter: blur(10px) saturate(200%);
  backdrop-filter: blur(10px) saturate(200%);
  background: rgba(255, 255, 255, .75);
  bottom: 0;
}

[class*="hover-"] {
  transition: all .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  [class*="hover-"] {
    transition: none;
  }
}

[class*="shadow"] {
  transition: all .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  [class*="shadow"] {
    transition: none;
  }
}

a.text-muted:hover, a.text-heading:hover, a.text-current:hover {
  color: #0e1421;
}

.txt-limit {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.svg-fluid svg {
  max-width: 100%;
  height: 100%;
}

.svg-current svg [fill]:not([fill="none"]) {
  fill: currentColor !important;
}

.img-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.img-grayscale {
  filter: grayscale();
}

.img-saturate {
  filter: saturate(150%);
}

.svg-icon {
  align-self: center;
  display: inline-flex;
}

.svg-icon svg {
  width: 1em;
  height: 1em;
  position: relative;
}

.svg-icon[class*="text-"] > svg [fill]:not([fill="none"]) {
  fill: currentColor !important;
}

.svg-align-baseline svg {
  top: .125em;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialised {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.group-item-invisible .group-item {
  visibility: hidden;
}

.group-item-visible-hover:hover .group-item {
  visibility: visible;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow, .shadow-hover:hover {
  box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-1, .shadow-1-hover:hover {
  box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-2, .shadow-2-hover:hover {
  box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-3, .shadow-3-hover:hover {
  box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-4, .shadow-4-hover:hover {
  box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-5, .shadow-5-hover:hover {
  box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-6, .shadow-6-hover:hover {
  box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-inset, .shadow-inset-hover:hover {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
}

.shadow-outline, .shadow-outline-hover:hover, .shadow-focus, .shadow-focus-hover:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, .5) !important;
}

.shadow-none, .shadow-none-hover:hover {
  box-shadow: none !important;
}

.focus-ring-primary {
  --x-focus-ring-color: rgba(var(--x-primary-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-secondary {
  --x-focus-ring-color: rgba(var(--x-secondary-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-success {
  --x-focus-ring-color: rgba(var(--x-success-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-info {
  --x-focus-ring-color: rgba(var(--x-info-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-warning {
  --x-focus-ring-color: rgba(var(--x-warning-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-danger {
  --x-focus-ring-color: rgba(var(--x-danger-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-light {
  --x-focus-ring-color: rgba(var(--x-light-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-dark {
  --x-focus-ring-color: rgba(var(--x-dark-rgb), var(--x-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.top-1 {
  top: .25rem !important;
}

.top-2 {
  top: .5rem !important;
}

.top-3 {
  top: .75rem !important;
}

.top-4 {
  top: 1rem !important;
}

.top-5 {
  top: 1.25rem !important;
}

.top-6 {
  top: 1.5rem !important;
}

.top-7 {
  top: 1.75rem !important;
}

.top-8 {
  top: 2rem !important;
}

.top-10 {
  top: 2.5rem !important;
}

.top-12 {
  top: 3rem !important;
}

.top-14 {
  top: 3.5rem !important;
}

.top-16 {
  top: 4rem !important;
}

.top-18 {
  top: 4.5rem !important;
}

.top-20 {
  top: 5rem !important;
}

.top-24 {
  top: 6rem !important;
}

.top-32 {
  top: 8rem !important;
}

.top-40 {
  top: 10rem !important;
}

.top-48 {
  top: 12rem !important;
}

.top-56 {
  top: 14rem !important;
}

.top-64 {
  top: 16rem !important;
}

.top-72 {
  top: 18rem !important;
}

.top-80 {
  top: 20rem !important;
}

.top-px {
  top: 1px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-primary-rgb), var(--x-border-opacity)) !important;
}

.border-secondary {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-secondary-rgb), var(--x-border-opacity)) !important;
}

.border-success {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-success-rgb), var(--x-border-opacity)) !important;
}

.border-info {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-info-rgb), var(--x-border-opacity)) !important;
}

.border-warning {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-warning-rgb), var(--x-border-opacity)) !important;
}

.border-danger {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-danger-rgb), var(--x-border-opacity)) !important;
}

.border-light {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-light-rgb), var(--x-border-opacity)) !important;
}

.border-dark {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-dark-rgb), var(--x-border-opacity)) !important;
}

.border-black {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-black-rgb), var(--x-border-opacity)) !important;
}

.border-white {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-white-rgb), var(--x-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--x-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--x-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--x-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--x-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--x-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--x-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--x-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--x-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10, .border-opacity-10-hover:hover {
  --x-border-opacity: .1;
}

.border-opacity-25, .border-opacity-25-hover:hover {
  --x-border-opacity: .25;
}

.border-opacity-50, .border-opacity-50-hover:hover {
  --x-border-opacity: .5;
}

.border-opacity-75, .border-opacity-75-hover:hover {
  --x-border-opacity: .75;
}

.border-opacity-100, .border-opacity-100-hover:hover {
  --x-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-screen-sm {
  max-width: 640px !important;
}

.mw-screen-md {
  max-width: 768px !important;
}

.mw-screen-lg {
  max-width: 1024px !important;
}

.mw-screen-xl {
  max-width: 1280px !important;
}

.mw-screen-xxl {
  max-width: 1536px !important;
}

.mw-0 {
  max-width: 0 !important;
}

.mw-full {
  max-width: 100% !important;
}

.mw-read {
  max-width: 65ch !important;
}

.mw-min {
  max-width: -webkit-min-content !important;
  max-width: -moz-min-content !important;
  max-width: min-content !important;
}

.mw-max {
  max-width: -webkit-max-content !important;
  max-width: -moz-max-content !important;
  max-width: max-content !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-initial {
  flex: 0 auto !important;
}

.flex-none {
  flex: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.m-48 {
  margin: 12rem !important;
}

.m-56 {
  margin: 14rem !important;
}

.m-64 {
  margin: 16rem !important;
}

.m-72 {
  margin: 18rem !important;
}

.m-80 {
  margin: 20rem !important;
}

.m-px {
  margin: 1px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-14 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-18 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-24 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mx-32 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.mx-40 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.mx-48 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.mx-56 {
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.mx-64 {
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.mx-72 {
  margin-left: 18rem !important;
  margin-right: 18rem !important;
}

.mx-80 {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-72 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-80 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mt-32 {
  margin-top: 8rem !important;
}

.mt-40 {
  margin-top: 10rem !important;
}

.mt-48 {
  margin-top: 12rem !important;
}

.mt-56 {
  margin-top: 14rem !important;
}

.mt-64 {
  margin-top: 16rem !important;
}

.mt-72 {
  margin-top: 18rem !important;
}

.mt-80 {
  margin-top: 20rem !important;
}

.mt-px {
  margin-top: 1px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: .75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-24 {
  margin-right: 6rem !important;
}

.me-32 {
  margin-right: 8rem !important;
}

.me-40 {
  margin-right: 10rem !important;
}

.me-48 {
  margin-right: 12rem !important;
}

.me-56 {
  margin-right: 14rem !important;
}

.me-64 {
  margin-right: 16rem !important;
}

.me-72 {
  margin-right: 18rem !important;
}

.me-80 {
  margin-right: 20rem !important;
}

.me-px {
  margin-right: 1px !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.mb-40 {
  margin-bottom: 10rem !important;
}

.mb-48 {
  margin-bottom: 12rem !important;
}

.mb-56 {
  margin-bottom: 14rem !important;
}

.mb-64 {
  margin-bottom: 16rem !important;
}

.mb-72 {
  margin-bottom: 18rem !important;
}

.mb-80 {
  margin-bottom: 20rem !important;
}

.mb-px {
  margin-bottom: 1px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: .75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-24 {
  margin-left: 6rem !important;
}

.ms-32 {
  margin-left: 8rem !important;
}

.ms-40 {
  margin-left: 10rem !important;
}

.ms-48 {
  margin-left: 12rem !important;
}

.ms-56 {
  margin-left: 14rem !important;
}

.ms-64 {
  margin-left: 16rem !important;
}

.ms-72 {
  margin-left: 18rem !important;
}

.ms-80 {
  margin-left: 20rem !important;
}

.ms-px {
  margin-left: 1px !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.m-n24 {
  margin: -6rem !important;
}

.m-n32 {
  margin: -8rem !important;
}

.m-n40 {
  margin: -10rem !important;
}

.m-n48 {
  margin: -12rem !important;
}

.m-n56 {
  margin: -14rem !important;
}

.m-n64 {
  margin: -16rem !important;
}

.m-n72 {
  margin: -18rem !important;
}

.m-n80 {
  margin: -20rem !important;
}

.m-npx {
  margin: -1px !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -.75rem !important;
  margin-right: -.75rem !important;
}

.mx-n4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.mx-n6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n7 {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.mx-n8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.mx-n10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.mx-n12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.mx-n14 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.mx-n16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.mx-n18 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.mx-n20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-n24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.mx-n32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.mx-n40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.mx-n48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.mx-n56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.mx-n64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.mx-n72 {
  margin-left: -18rem !important;
  margin-right: -18rem !important;
}

.mx-n80 {
  margin-left: -20rem !important;
  margin-right: -20rem !important;
}

.mx-npx {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -.75rem !important;
  margin-bottom: -.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.my-n56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.my-n64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.my-n72 {
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.my-n80 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.my-npx {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mt-n18 {
  margin-top: -4.5rem !important;
}

.mt-n20 {
  margin-top: -5rem !important;
}

.mt-n24 {
  margin-top: -6rem !important;
}

.mt-n32 {
  margin-top: -8rem !important;
}

.mt-n40 {
  margin-top: -10rem !important;
}

.mt-n48 {
  margin-top: -12rem !important;
}

.mt-n56 {
  margin-top: -14rem !important;
}

.mt-n64 {
  margin-top: -16rem !important;
}

.mt-n72 {
  margin-top: -18rem !important;
}

.mt-n80 {
  margin-top: -20rem !important;
}

.mt-npx {
  margin-top: -1px !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n12 {
  margin-right: -3rem !important;
}

.me-n14 {
  margin-right: -3.5rem !important;
}

.me-n16 {
  margin-right: -4rem !important;
}

.me-n18 {
  margin-right: -4.5rem !important;
}

.me-n20 {
  margin-right: -5rem !important;
}

.me-n24 {
  margin-right: -6rem !important;
}

.me-n32 {
  margin-right: -8rem !important;
}

.me-n40 {
  margin-right: -10rem !important;
}

.me-n48 {
  margin-right: -12rem !important;
}

.me-n56 {
  margin-right: -14rem !important;
}

.me-n64 {
  margin-right: -16rem !important;
}

.me-n72 {
  margin-right: -18rem !important;
}

.me-n80 {
  margin-right: -20rem !important;
}

.me-npx {
  margin-right: -1px !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.mb-n18 {
  margin-bottom: -4.5rem !important;
}

.mb-n20 {
  margin-bottom: -5rem !important;
}

.mb-n24 {
  margin-bottom: -6rem !important;
}

.mb-n32 {
  margin-bottom: -8rem !important;
}

.mb-n40 {
  margin-bottom: -10rem !important;
}

.mb-n48 {
  margin-bottom: -12rem !important;
}

.mb-n56 {
  margin-bottom: -14rem !important;
}

.mb-n64 {
  margin-bottom: -16rem !important;
}

.mb-n72 {
  margin-bottom: -18rem !important;
}

.mb-n80 {
  margin-bottom: -20rem !important;
}

.mb-npx {
  margin-bottom: -1px !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.ms-n14 {
  margin-left: -3.5rem !important;
}

.ms-n16 {
  margin-left: -4rem !important;
}

.ms-n18 {
  margin-left: -4.5rem !important;
}

.ms-n20 {
  margin-left: -5rem !important;
}

.ms-n24 {
  margin-left: -6rem !important;
}

.ms-n32 {
  margin-left: -8rem !important;
}

.ms-n40 {
  margin-left: -10rem !important;
}

.ms-n48 {
  margin-left: -12rem !important;
}

.ms-n56 {
  margin-left: -14rem !important;
}

.ms-n64 {
  margin-left: -16rem !important;
}

.ms-n72 {
  margin-left: -18rem !important;
}

.ms-n80 {
  margin-left: -20rem !important;
}

.ms-npx {
  margin-left: -1px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.p-48 {
  padding: 12rem !important;
}

.p-56 {
  padding: 14rem !important;
}

.p-64 {
  padding: 16rem !important;
}

.p-72 {
  padding: 18rem !important;
}

.p-80 {
  padding: 20rem !important;
}

.p-px {
  padding: 1px !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-14 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-18 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.px-24 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.px-32 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.px-40 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.px-48 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.px-56 {
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.px-64 {
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.px-72 {
  padding-left: 18rem !important;
  padding-right: 18rem !important;
}

.px-80 {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.px-px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-72 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.py-80 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pt-32 {
  padding-top: 8rem !important;
}

.pt-40 {
  padding-top: 10rem !important;
}

.pt-48 {
  padding-top: 12rem !important;
}

.pt-56 {
  padding-top: 14rem !important;
}

.pt-64 {
  padding-top: 16rem !important;
}

.pt-72 {
  padding-top: 18rem !important;
}

.pt-80 {
  padding-top: 20rem !important;
}

.pt-px {
  padding-top: 1px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: .75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pe-24 {
  padding-right: 6rem !important;
}

.pe-32 {
  padding-right: 8rem !important;
}

.pe-40 {
  padding-right: 10rem !important;
}

.pe-48 {
  padding-right: 12rem !important;
}

.pe-56 {
  padding-right: 14rem !important;
}

.pe-64 {
  padding-right: 16rem !important;
}

.pe-72 {
  padding-right: 18rem !important;
}

.pe-80 {
  padding-right: 20rem !important;
}

.pe-px {
  padding-right: 1px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.pb-40 {
  padding-bottom: 10rem !important;
}

.pb-48 {
  padding-bottom: 12rem !important;
}

.pb-56 {
  padding-bottom: 14rem !important;
}

.pb-64 {
  padding-bottom: 16rem !important;
}

.pb-72 {
  padding-bottom: 18rem !important;
}

.pb-80 {
  padding-bottom: 20rem !important;
}

.pb-px {
  padding-bottom: 1px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: .75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.ps-24 {
  padding-left: 6rem !important;
}

.ps-32 {
  padding-left: 8rem !important;
}

.ps-40 {
  padding-left: 10rem !important;
}

.ps-48 {
  padding-left: 12rem !important;
}

.ps-56 {
  padding-left: 14rem !important;
}

.ps-64 {
  padding-left: 16rem !important;
}

.ps-72 {
  padding-left: 18rem !important;
}

.ps-80 {
  padding-left: 20rem !important;
}

.ps-px {
  padding-left: 1px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: .75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.gap-24 {
  gap: 6rem !important;
}

.gap-32 {
  gap: 8rem !important;
}

.gap-40 {
  gap: 10rem !important;
}

.gap-48 {
  gap: 12rem !important;
}

.gap-56 {
  gap: 14rem !important;
}

.gap-64 {
  gap: 16rem !important;
}

.gap-72 {
  gap: 18rem !important;
}

.gap-80 {
  gap: 20rem !important;
}

.gap-px {
  gap: 1px !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: .75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.row-gap-12 {
  row-gap: 3rem !important;
}

.row-gap-14 {
  row-gap: 3.5rem !important;
}

.row-gap-16 {
  row-gap: 4rem !important;
}

.row-gap-18 {
  row-gap: 4.5rem !important;
}

.row-gap-20 {
  row-gap: 5rem !important;
}

.row-gap-24 {
  row-gap: 6rem !important;
}

.row-gap-32 {
  row-gap: 8rem !important;
}

.row-gap-40 {
  row-gap: 10rem !important;
}

.row-gap-48 {
  row-gap: 12rem !important;
}

.row-gap-56 {
  row-gap: 14rem !important;
}

.row-gap-64 {
  row-gap: 16rem !important;
}

.row-gap-72 {
  row-gap: 18rem !important;
}

.row-gap-80 {
  row-gap: 20rem !important;
}

.row-gap-px {
  row-gap: 1px !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: .75rem !important;
}

.column-gap-4 {
  column-gap: 1rem !important;
}

.column-gap-5 {
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  column-gap: 1.75rem !important;
}

.column-gap-8 {
  column-gap: 2rem !important;
}

.column-gap-10 {
  column-gap: 2.5rem !important;
}

.column-gap-12 {
  column-gap: 3rem !important;
}

.column-gap-14 {
  column-gap: 3.5rem !important;
}

.column-gap-16 {
  column-gap: 4rem !important;
}

.column-gap-18 {
  column-gap: 4.5rem !important;
}

.column-gap-20 {
  column-gap: 5rem !important;
}

.column-gap-24 {
  column-gap: 6rem !important;
}

.column-gap-32 {
  column-gap: 8rem !important;
}

.column-gap-40 {
  column-gap: 10rem !important;
}

.column-gap-48 {
  column-gap: 12rem !important;
}

.column-gap-56 {
  column-gap: 14rem !important;
}

.column-gap-64 {
  column-gap: 16rem !important;
}

.column-gap-72 {
  column-gap: 18rem !important;
}

.column-gap-80 {
  column-gap: 20rem !important;
}

.column-gap-px {
  column-gap: 1px !important;
}

.font-monospace {
  font-family: var(--x-font-monospace) !important;
}

.font-base {
  font-family: var(--x-font-sans-serif) !important;
}

.font-display {
  font-family: var(--x-font-display) !important;
}

.font-serif {
  font-family: var(--x-font-serif) !important;
}

.font-code {
  font-family: var(--x-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-3 {
  font-size: calc(1.2625rem + .15vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: .875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium, .fw-semibold {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-xxs {
  line-height: 1.125 !important;
}

.lh-xs {
  line-height: 1.2 !important;
}

.lh-sm {
  line-height: 1.375 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.625 !important;
}

.lh-xl {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary, .text-primary-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
}

.text-secondary, .text-secondary-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
}

.text-success, .text-success-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
}

.text-info, .text-info-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
}

.text-warning, .text-warning-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
}

.text-danger, .text-danger-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
}

.text-light, .text-light-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
}

.text-dark, .text-dark-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
}

.text-black, .text-black-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
}

.text-white, .text-white-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
}

.text-body, .text-body-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
}

.text-muted, .text-muted-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-secondary-color) !important;
}

.text-black-50, .text-black-50-hover:hover {
  --x-text-opacity: 1;
  color: rgba(0, 0, 0, .5) !important;
}

.text-white-50, .text-white-50-hover:hover {
  --x-text-opacity: 1;
  color: rgba(255, 255, 255, .5) !important;
}

.text-body-secondary, .text-body-secondary-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-secondary-color) !important;
}

.text-body-tertiary, .text-body-tertiary-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-tertiary-color) !important;
}

.text-body-emphasis, .text-body-emphasis-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-emphasis-color) !important;
}

.text-reset, .text-reset-hover:hover {
  --x-text-opacity: 1;
  color: inherit !important;
}

.text-heading, .text-heading-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-heading-color) !important;
}

.text-opacity-25, .text-opacity-25-hover:hover {
  --x-text-opacity: .25;
}

.text-opacity-50, .text-opacity-50-hover:hover {
  --x-text-opacity: .5;
}

.text-opacity-75, .text-opacity-75-hover:hover {
  --x-text-opacity: .75;
}

.text-opacity-100, .text-opacity-100-hover:hover {
  --x-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--x-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--x-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--x-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--x-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--x-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--x-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--x-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--x-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --x-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --x-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --x-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --x-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --x-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-primary-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-primary-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-secondary-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-secondary-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-success {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-success-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-success-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-info {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-info-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-info-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-warning {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-warning-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-warning-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-danger {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-danger-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-danger-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-light {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-light-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-light-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-dark {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-dark-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-dark-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --x-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --x-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --x-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --x-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --x-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --x-link-underline-opacity: 1;
}

.bg-primary, .bg-primary-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-primary-rgb), var(--x-bg-opacity)) !important;
}

.bg-secondary, .bg-secondary-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-secondary-rgb), var(--x-bg-opacity)) !important;
}

.bg-success, .bg-success-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-success-rgb), var(--x-bg-opacity)) !important;
}

.bg-info, .bg-info-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
}

.bg-warning, .bg-warning-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-warning-rgb), var(--x-bg-opacity)) !important;
}

.bg-danger, .bg-danger-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
}

.bg-light, .bg-light-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
}

.bg-dark, .bg-dark-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
}

.bg-black, .bg-black-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
}

.bg-white, .bg-white-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
}

.bg-body, .bg-body-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-body-bg-rgb), var(--x-bg-opacity)) !important;
}

.bg-transparent, .bg-transparent-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
}

.bg-body-secondary, .bg-body-secondary-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-secondary-bg-rgb), var(--x-bg-opacity)) !important;
}

.bg-body-tertiary, .bg-body-tertiary-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-tertiary-bg-rgb), var(--x-bg-opacity)) !important;
}

.bg-opacity-10, .bg-opacity-10-hover:hover {
  --x-bg-opacity: .1;
}

.bg-opacity-25, .bg-opacity-25-hover:hover {
  --x-bg-opacity: .25;
}

.bg-opacity-50, .bg-opacity-50-hover:hover {
  --x-bg-opacity: .5;
}

.bg-opacity-75, .bg-opacity-75-hover:hover {
  --x-bg-opacity: .75;
}

.bg-opacity-100, .bg-opacity-100-hover:hover {
  --x-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--x-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--x-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--x-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--x-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--x-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--x-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--x-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--x-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--x-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: .375rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: .25rem !important;
}

.rounded-2 {
  border-radius: .5rem !important;
}

.rounded-3 {
  border-radius: .75rem !important;
}

.rounded-4 {
  border-radius: 1rem !important;
}

.rounded-5 {
  border-radius: 1.5rem !important;
}

.rounded-6 {
  border-radius: 3rem !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-card {
  border-radius: var(--x-border-radius-xl) !important;
}

.rounded-top {
  border-top-left-radius: .375rem !important;
  border-top-right-radius: .375rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-top-2 {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.rounded-top-3 {
  border-top-left-radius: .75rem !important;
  border-top-right-radius: .75rem !important;
}

.rounded-top-4 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-top-5 {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.rounded-top-6 {
  border-top-left-radius: 3rem !important;
  border-top-right-radius: 3rem !important;
}

.rounded-top-pill {
  border-top-left-radius: 50rem !important;
  border-top-right-radius: 50rem !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-card {
  border-top-left-radius: var(--x-border-radius-xl) !important;
  border-top-right-radius: var(--x-border-radius-xl) !important;
}

.rounded-end {
  border-top-right-radius: .375rem !important;
  border-bottom-right-radius: .375rem !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-end-2 {
  border-top-right-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.rounded-end-3 {
  border-top-right-radius: .75rem !important;
  border-bottom-right-radius: .75rem !important;
}

.rounded-end-4 {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-end-5 {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-end-6 {
  border-top-right-radius: 3rem !important;
  border-bottom-right-radius: 3rem !important;
}

.rounded-end-pill {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-card {
  border-top-right-radius: var(--x-border-radius-xl) !important;
  border-bottom-right-radius: var(--x-border-radius-xl) !important;
}

.rounded-bottom {
  border-bottom-right-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: .75rem !important;
  border-bottom-left-radius: .75rem !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-bottom-6 {
  border-bottom-right-radius: 3rem !important;
  border-bottom-left-radius: 3rem !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-card {
  border-bottom-right-radius: var(--x-border-radius-xl) !important;
  border-bottom-left-radius: var(--x-border-radius-xl) !important;
}

.rounded-start {
  border-top-left-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-start-2 {
  border-top-left-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.rounded-start-3 {
  border-top-left-radius: .75rem !important;
  border-bottom-left-radius: .75rem !important;
}

.rounded-start-4 {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-start-5 {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-start-6 {
  border-top-left-radius: 3rem !important;
  border-bottom-left-radius: 3rem !important;
}

.rounded-start-pill {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-card {
  border-bottom-left-radius: var(--x-border-radius-xl) !important;
  border-top-left-radius: var(--x-border-radius-xl) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.rounded-top-start {
  border-top-left-radius: .375rem !important;
}

.rounded-top-start-0 {
  border-top-left-radius: 0 !important;
}

.rounded-top-start-1 {
  border-top-left-radius: .25rem !important;
}

.rounded-top-start-2 {
  border-top-left-radius: .5rem !important;
}

.rounded-top-start-3 {
  border-top-left-radius: .75rem !important;
}

.rounded-top-start-4 {
  border-top-left-radius: 1rem !important;
}

.rounded-top-start-5 {
  border-top-left-radius: 1.5rem !important;
}

.rounded-top-start-6 {
  border-top-left-radius: 3rem !important;
}

.rounded-top-start-pill {
  border-top-left-radius: 50rem !important;
}

.rounded-top-start-circle {
  border-top-left-radius: 50% !important;
}

.rounded-top-start-card {
  border-top-left-radius: var(--x-border-radius-xl) !important;
}

.rounded-top-end {
  border-top-right-radius: .375rem !important;
}

.rounded-top-end-0 {
  border-top-right-radius: 0 !important;
}

.rounded-top-end-1 {
  border-top-right-radius: .25rem !important;
}

.rounded-top-end-2 {
  border-top-right-radius: .5rem !important;
}

.rounded-top-end-3 {
  border-top-right-radius: .75rem !important;
}

.rounded-top-end-4 {
  border-top-right-radius: 1rem !important;
}

.rounded-top-end-5 {
  border-top-right-radius: 1.5rem !important;
}

.rounded-top-end-6 {
  border-top-right-radius: 3rem !important;
}

.rounded-top-end-pill {
  border-top-right-radius: 50rem !important;
}

.rounded-top-end-circle {
  border-top-right-radius: 50% !important;
}

.rounded-top-end-card {
  border-top-right-radius: var(--x-border-radius-xl) !important;
}

.rounded-bottom-end {
  border-bottom-right-radius: .375rem !important;
}

.rounded-bottom-end-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-end-1 {
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom-end-2 {
  border-bottom-right-radius: .5rem !important;
}

.rounded-bottom-end-3 {
  border-bottom-right-radius: .75rem !important;
}

.rounded-bottom-end-4 {
  border-bottom-right-radius: 1rem !important;
}

.rounded-bottom-end-5 {
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-bottom-end-6 {
  border-bottom-right-radius: 3rem !important;
}

.rounded-bottom-end-pill {
  border-bottom-right-radius: 50rem !important;
}

.rounded-bottom-end-circle {
  border-bottom-right-radius: 50% !important;
}

.rounded-bottom-end-card {
  border-bottom-right-radius: var(--x-border-radius-xl) !important;
}

.rounded-bottom-start {
  border-bottom-left-radius: .375rem !important;
}

.rounded-bottom-start-0 {
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-start-1 {
  border-bottom-left-radius: .25rem !important;
}

.rounded-bottom-start-2 {
  border-bottom-left-radius: .5rem !important;
}

.rounded-bottom-start-3 {
  border-bottom-left-radius: .75rem !important;
}

.rounded-bottom-start-4 {
  border-bottom-left-radius: 1rem !important;
}

.rounded-bottom-start-5 {
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-bottom-start-6 {
  border-bottom-left-radius: 3rem !important;
}

.rounded-bottom-start-pill {
  border-bottom-left-radius: 50rem !important;
}

.rounded-bottom-start-circle {
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-start-card {
  border-bottom-left-radius: var(--x-border-radius-xl) !important;
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0) !important;
}

.border-current {
  border-color: currentColor !important;
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-none {
  border-style: none !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-text {
  cursor: text !important;
}

.text-xxs {
  font-size: .625rem !important;
}

.text-xs {
  font-size: .75rem !important;
}

.text-sm {
  font-size: .875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-md {
  font-size: 1.125rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: calc(1.275rem + .3vw) !important;
}

.text-2xl {
  font-size: calc(1.325rem + .9vw) !important;
}

.ls-tighter {
  letter-spacing: -.05em !important;
}

.ls-tight {
  letter-spacing: -.025em !important;
}

.ls-normal {
  letter-spacing: 0 !important;
}

.ls-wide {
  letter-spacing: .025em !important;
}

.ls-wider {
  letter-spacing: .05em !important;
}

.ls-widest {
  letter-spacing: .1em !important;
}

.text-transparent {
  color: rgba(0, 0, 0, 0) !important;
}

.text-current {
  color: currentColor !important;
}

.bg-current {
  background-color: currentColor !important;
}

.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.bg-clip-border {
  background-clip: border-box !important;
}

.bg-clip-padding {
  background-clip: padding-box !important;
}

.bg-clip-content {
  background-clip: content-box !important;
}

.bg-clip-text {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.shadow-soft-1, .shadow-soft-1-hover:hover {
  box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-2, .shadow-soft-2-hover:hover {
  box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-3, .shadow-soft-3-hover:hover {
  box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-4, .shadow-soft-4-hover:hover {
  box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-5, .shadow-soft-5-hover:hover {
  box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-6, .shadow-soft-6-hover:hover {
  box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-none, .shadow-soft-none-hover:hover {
  box-shadow: none !important;
}

.w-rem-0 {
  width: 0 !important;
}

.w-rem-1 {
  width: .25rem !important;
}

.w-rem-2 {
  width: .5rem !important;
}

.w-rem-3 {
  width: .75rem !important;
}

.w-rem-4 {
  width: 1rem !important;
}

.w-rem-5 {
  width: 1.25rem !important;
}

.w-rem-6 {
  width: 1.5rem !important;
}

.w-rem-7 {
  width: 1.75rem !important;
}

.w-rem-8 {
  width: 2rem !important;
}

.w-rem-10 {
  width: 2.5rem !important;
}

.w-rem-12 {
  width: 3rem !important;
}

.w-rem-14 {
  width: 3.5rem !important;
}

.w-rem-16 {
  width: 4rem !important;
}

.w-rem-18 {
  width: 4.5rem !important;
}

.w-rem-20 {
  width: 5rem !important;
}

.w-rem-24 {
  width: 6rem !important;
}

.w-rem-32 {
  width: 8rem !important;
}

.w-rem-40 {
  width: 10rem !important;
}

.w-rem-48 {
  width: 12rem !important;
}

.w-rem-56 {
  width: 14rem !important;
}

.w-rem-64 {
  width: 16rem !important;
}

.w-rem-72 {
  width: 18rem !important;
}

.w-rem-80 {
  width: 20rem !important;
}

.w-rem-px {
  width: 1px !important;
}

.w-rem-25 {
  width: 25% !important;
}

.w-rem-50 {
  width: 50% !important;
}

.w-rem-75 {
  width: 75% !important;
}

.w-rem-100 {
  width: 100% !important;
}

.w-rem-screen-sm {
  width: 640px !important;
}

.w-rem-screen-md {
  width: 768px !important;
}

.w-rem-screen-lg {
  width: 1024px !important;
}

.w-rem-screen-xl {
  width: 1280px !important;
}

.w-rem-screen-xxl {
  width: 1536px !important;
}

.w-rem-auto {
  width: auto !important;
}

.w-rem-full {
  width: 100% !important;
}

.w-rem-screen {
  width: 100vw !important;
}

.w-rem-min {
  width: -webkit-min-content !important;
  width: -moz-min-content !important;
  width: min-content !important;
}

.w-rem-max {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.h-rem-0 {
  height: 0 !important;
}

.h-rem-1 {
  height: .25rem !important;
}

.h-rem-2 {
  height: .5rem !important;
}

.h-rem-3 {
  height: .75rem !important;
}

.h-rem-4 {
  height: 1rem !important;
}

.h-rem-5 {
  height: 1.25rem !important;
}

.h-rem-6 {
  height: 1.5rem !important;
}

.h-rem-7 {
  height: 1.75rem !important;
}

.h-rem-8 {
  height: 2rem !important;
}

.h-rem-10 {
  height: 2.5rem !important;
}

.h-rem-12 {
  height: 3rem !important;
}

.h-rem-14 {
  height: 3.5rem !important;
}

.h-rem-16 {
  height: 4rem !important;
}

.h-rem-18 {
  height: 4.5rem !important;
}

.h-rem-20 {
  height: 5rem !important;
}

.h-rem-24 {
  height: 6rem !important;
}

.h-rem-32 {
  height: 8rem !important;
}

.h-rem-40 {
  height: 10rem !important;
}

.h-rem-48 {
  height: 12rem !important;
}

.h-rem-56 {
  height: 14rem !important;
}

.h-rem-64 {
  height: 16rem !important;
}

.h-rem-72 {
  height: 18rem !important;
}

.h-rem-80 {
  height: 20rem !important;
}

.h-rem-px {
  height: 1px !important;
}

.scroll-auto {
  scroll-behaviour: auto !important;
}

.scroll-smooth {
  scroll-behaviour: smooth !important;
}

.scroll-snap-start {
  scroll-snap-align: start !important;
}

.scroll-snap-end {
  scroll-snap-align: end !important;
}

.scroll-snap-center {
  scroll-snap-align: center !important;
}

.scroll-snap-none {
  scroll-snap-align: none !important;
}

.scroll-snap-normal {
  scroll-snap-stop: normal !important;
}

.scroll-snap-always {
  scroll-snap-stop: always !important;
}

.scroll-snap-none {
  scroll-snap-type: none !important;
}

.scroll-snap-x {
  scroll-snap-type: x var(--x-scroll-snap-strictness) !important;
}

.scroll-snap-y {
  scroll-snap-type: y var(--x-scroll-snap-strictness) !important;
}

.scroll-snap-both {
  scroll-snap-type: both var(--x-scroll-snap-strictness) !important;
}

.scroll-snap-mandatory {
  --x-scroll-snap-strictness: mandatory;
}

.scroll-snap-proximity {
  --x-scroll-snap-strictness: proximity;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke !important;
}

.transition-opacity {
  transition-property: opacity !important;
}

.transition-shadow {
  transition-property: box-shadow !important;
}

.transition-transform {
  transition-property: transform !important;
}

.ease-base {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.ease-linear {
  transition-timing-function: linear !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.duration-base {
  transition-duration: .15s !important;
}

.duration-75 {
  transition-duration: 75ms !important;
}

.duration-100 {
  transition-duration: .1s !important;
}

.duration-150 {
  transition-duration: .15s !important;
}

.duration-300 {
  transition-duration: .3s !important;
}

.duration-500 {
  transition-duration: .5s !important;
}

.duration-700 {
  transition-duration: .7s !important;
}

.duration-1000 {
  transition-duration: 1s !important;
}

.delay-75 {
  transition-delay: 75ms !important;
}

.delay-100 {
  transition-delay: .1s !important;
}

.delay-150 {
  transition-delay: .15s !important;
}

.delay-300 {
  transition-delay: .3s !important;
}

.delay-500 {
  transition-delay: .5s !important;
}

.delay-700 {
  transition-delay: .7s !important;
}

.delay-1000 {
  transition-delay: 1s !important;
}

.animation-none {
  animation: none !important;
}

.animation-spin {
  animation: 1s linear infinite spin !important;
}

.animation-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping !important;
}

.animation-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse !important;
}

.animation-bounce {
  animation: 1s infinite bounce !important;
}

.animation-slide-left {
  animation: 3s infinite slideLeft !important;
}

.animation-slide-right {
  animation: 3s infinite slideRight !important;
}

.animation-move-slow-left {
  animation: 15s linear infinite slideLeft !important;
}

.animation-move-slow-right {
  animation: 15s linear infinite slideRight !important;
}

.blur-5 {
  --bs-blur: 5px !important;
}

.blur-10 {
  --bs-blur: 10px !important;
}

.blur-25 {
  --bs-blur: 25px !important;
}

.blur-50 {
  --bs-blur: 50px !important;
}

.blur-75 {
  --bs-blur: 75px !important;
}

.blur-100 {
  --bs-blur: 100px !important;
}

.blur-125 {
  --bs-blur: 125px !important;
}

.blur-150 {
  --bs-blur: 150px !important;
}

.blur-200 {
  --bs-blur: 200px !important;
}

.transform-none {
  transform: none !important;
}

.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: 100% 0 !important;
}

.origin-right {
  transform-origin: 100% !important;
}

.origin-bottom-right {
  transform-origin: 100% 100% !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: 0 100% !important;
}

.origin-left {
  transform-origin: 0 !important;
}

.origin-top-left {
  transform-origin: 0 0 !important;
}

.scale-0, .scale-0-hover:hover {
  --bs-scale-x: 0 !important;
  --bs-scale-y: 0 !important;
}

.scale-25, .scale-25-hover:hover {
  --bs-scale-x: .25 !important;
  --bs-scale-y: .25 !important;
}

.scale-50, .scale-50-hover:hover {
  --bs-scale-x: .5 !important;
  --bs-scale-y: .5 !important;
}

.scale-75, .scale-75-hover:hover {
  --bs-scale-x: .75 !important;
  --bs-scale-y: .75 !important;
}

.scale-100, .scale-100-hover:hover {
  --bs-scale-x: 1 !important;
  --bs-scale-y: 1 !important;
}

.scale-105, .scale-105-hover:hover {
  --bs-scale-x: 1.05 !important;
  --bs-scale-y: 1.05 !important;
}

.scale-110, .scale-110-hover:hover {
  --bs-scale-x: 1.1 !important;
  --bs-scale-y: 1.1 !important;
}

.rotate-0, .rotate-0-hover:hover {
  --bs-rotate: 0deg !important;
}

.rotate-1, .rotate-1-hover:hover {
  --bs-rotate: 1deg !important;
}

.rotate-2, .rotate-2-hover:hover {
  --bs-rotate: 2deg !important;
}

.rotate-3, .rotate-3-hover:hover {
  --bs-rotate: 3deg !important;
}

.rotate-6, .rotate-6-hover:hover {
  --bs-rotate: 6deg !important;
}

.rotate-12, .rotate-12-hover:hover {
  --bs-rotate: 12deg !important;
}

.rotate-30, .rotate-30-hover:hover {
  --bs-rotate: 30deg !important;
}

.rotate-45, .rotate-45-hover:hover {
  --bs-rotate: 45deg !important;
}

.rotate-90, .rotate-90-hover:hover {
  --bs-rotate: 90deg !important;
}

.rotate-180, .rotate-180-hover:hover {
  --bs-rotate: 180deg !important;
}

.rotate-n1, .rotate-n1-hover:hover {
  --bs-rotate: -1deg !important;
}

.rotate-n2, .rotate-n2-hover:hover {
  --bs-rotate: -2deg !important;
}

.rotate-n3, .rotate-n3-hover:hover {
  --bs-rotate: -3deg !important;
}

.rotate-n6, .rotate-n6-hover:hover {
  --bs-rotate: -6deg !important;
}

.rotate-n12, .rotate-n12-hover:hover {
  --bs-rotate: -12deg !important;
}

.rotate-n30, .rotate-n30-hover:hover {
  --bs-rotate: -30deg !important;
}

.rotate-n45, .rotate-n45-hover:hover {
  --bs-rotate: -45deg !important;
}

.rotate-n90, .rotate-n90-hover:hover {
  --bs-rotate: -90deg !important;
}

.rotate-n180, .rotate-n180-hover:hover {
  --bs-rotate: -180deg !important;
}

.rotate-y-n1, .rotate-y-n1-hover:hover {
  --bs-rotate-y: -1deg !important;
}

.rotate-y-n2, .rotate-y-n2-hover:hover {
  --bs-rotate-y: -2deg !important;
}

.rotate-y-n3, .rotate-y-n3-hover:hover {
  --bs-rotate-y: -3deg !important;
}

.rotate-y-n6, .rotate-y-n6-hover:hover {
  --bs-rotate-y: -6deg !important;
}

.rotate-y-n12, .rotate-y-n12-hover:hover {
  --bs-rotate-y: -12deg !important;
}

.rotate-y-n30, .rotate-y-n30-hover:hover {
  --bs-rotate-y: -30deg !important;
}

.rotate-y-n45, .rotate-y-n45-hover:hover {
  --bs-rotate-y: -45deg !important;
}

.rotate-y-n90, .rotate-y-n90-hover:hover {
  --bs-rotate-y: -90deg !important;
}

.rotate-y-n180, .rotate-y-n180-hover:hover {
  --bs-rotate-y: -180deg !important;
}

.rotate-x-n1 {
  --bs-rotate-x: -1deg !important;
}

.rotate-x-n2 {
  --bs-rotate-x: -2deg !important;
}

.rotate-x-n3 {
  --bs-rotate-x: -3deg !important;
}

.rotate-x-n6 {
  --bs-rotate-x: -6deg !important;
}

.rotate-x-n12 {
  --bs-rotate-x: -12deg !important;
}

.rotate-x-n30 {
  --bs-rotate-x: -30deg !important;
}

.rotate-x-n45 {
  --bs-rotate-x: -45deg !important;
}

.rotate-x-n90 {
  --bs-rotate-x: -90deg !important;
}

.rotate-x-n180 {
  --bs-rotate-x: -180deg !important;
}

.translate-y-0, .translate-y-0-hover:hover {
  --bs-translate-y: 0 !important;
}

.translate-y-25, .translate-y-25-hover:hover {
  --bs-translate-y: 25% !important;
}

.translate-y-50, .translate-y-50-hover:hover {
  --bs-translate-y: 50% !important;
}

.translate-y-75, .translate-y-75-hover:hover {
  --bs-translate-y: 75% !important;
}

.translate-y-100, .translate-y-100-hover:hover {
  --bs-translate-y: 100% !important;
}

.translate-y-n25, .translate-y-n25-hover:hover {
  --bs-translate-y: -25% !important;
}

.translate-y-n50, .translate-y-n50-hover:hover {
  --bs-translate-y: -50% !important;
}

.translate-y-n75, .translate-y-n75-hover:hover {
  --bs-translate-y: -75% !important;
}

.translate-y-n100, .translate-y-n100-hover:hover {
  --bs-translate-y: -100% !important;
}

.translate-x-0, .translate-x-0-hover:hover {
  --bs-translate-x: 0 !important;
}

.translate-x-25, .translate-x-25-hover:hover {
  --bs-translate-x: 25% !important;
}

.translate-x-50, .translate-x-50-hover:hover {
  --bs-translate-x: 50% !important;
}

.translate-x-75, .translate-x-75-hover:hover {
  --bs-translate-x: 75% !important;
}

.translate-x-100, .translate-x-100-hover:hover {
  --bs-translate-x: 100% !important;
}

.translate-x-n25, .translate-x-n25-hover:hover {
  --bs-translate-x: -25% !important;
}

.translate-x-n50, .translate-x-n50-hover:hover {
  --bs-translate-x: -50% !important;
}

.translate-x-n75, .translate-x-n75-hover:hover {
  --bs-translate-x: -75% !important;
}

.translate-x-n100, .translate-x-n100-hover:hover {
  --bs-translate-x: -100% !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .overflow-sm-auto {
    overflow: auto !important;
  }

  .overflow-sm-hidden {
    overflow: hidden !important;
  }

  .overflow-sm-visible {
    overflow: visible !important;
  }

  .overflow-sm-scroll {
    overflow: scroll !important;
  }

  .overflow-x-sm-auto {
    overflow-x: auto !important;
  }

  .overflow-x-sm-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-sm-visible {
    overflow-x: visible !important;
  }

  .overflow-x-sm-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-sm-auto {
    overflow-y: auto !important;
  }

  .overflow-y-sm-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-sm-visible {
    overflow-y: visible !important;
  }

  .overflow-y-sm-scroll {
    overflow-y: scroll !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .shadow-sm, .shadow-sm-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-1, .shadow-sm-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-2, .shadow-sm-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-3, .shadow-sm-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-4, .shadow-sm-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-5, .shadow-sm-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-6, .shadow-sm-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-inset, .shadow-sm-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-sm-outline, .shadow-sm-outline-hover:hover, .shadow-sm-focus, .shadow-sm-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, .5) !important;
  }

  .shadow-sm-none, .shadow-sm-none-hover:hover {
    box-shadow: none !important;
  }

  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-sm-0 {
    top: 0 !important;
  }

  .top-sm-50 {
    top: 50% !important;
  }

  .top-sm-100 {
    top: 100% !important;
  }

  .top-sm-1 {
    top: .25rem !important;
  }

  .top-sm-2 {
    top: .5rem !important;
  }

  .top-sm-3 {
    top: .75rem !important;
  }

  .top-sm-4 {
    top: 1rem !important;
  }

  .top-sm-5 {
    top: 1.25rem !important;
  }

  .top-sm-6 {
    top: 1.5rem !important;
  }

  .top-sm-7 {
    top: 1.75rem !important;
  }

  .top-sm-8 {
    top: 2rem !important;
  }

  .top-sm-10 {
    top: 2.5rem !important;
  }

  .top-sm-12 {
    top: 3rem !important;
  }

  .top-sm-14 {
    top: 3.5rem !important;
  }

  .top-sm-16 {
    top: 4rem !important;
  }

  .top-sm-18 {
    top: 4.5rem !important;
  }

  .top-sm-20 {
    top: 5rem !important;
  }

  .top-sm-24 {
    top: 6rem !important;
  }

  .top-sm-32 {
    top: 8rem !important;
  }

  .top-sm-40 {
    top: 10rem !important;
  }

  .top-sm-48 {
    top: 12rem !important;
  }

  .top-sm-56 {
    top: 14rem !important;
  }

  .top-sm-64 {
    top: 16rem !important;
  }

  .top-sm-72 {
    top: 18rem !important;
  }

  .top-sm-80 {
    top: 20rem !important;
  }

  .top-sm-px {
    top: 1px !important;
  }

  .bottom-sm-0 {
    bottom: 0 !important;
  }

  .bottom-sm-50 {
    bottom: 50% !important;
  }

  .bottom-sm-100 {
    bottom: 100% !important;
  }

  .start-sm-0 {
    left: 0 !important;
  }

  .start-sm-50 {
    left: 50% !important;
  }

  .start-sm-100 {
    left: 100% !important;
  }

  .end-sm-0 {
    right: 0 !important;
  }

  .end-sm-50 {
    right: 50% !important;
  }

  .end-sm-100 {
    right: 100% !important;
  }

  .border-sm {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-sm-0 {
    border: 0 !important;
  }

  .border-top-sm {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-sm-0 {
    border-top: 0 !important;
  }

  .border-end-sm {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-sm-0 {
    border-right: 0 !important;
  }

  .border-bottom-sm {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }

  .border-start-sm {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-sm-0 {
    border-left: 0 !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .mw-sm-screen-sm {
    max-width: 640px !important;
  }

  .mw-sm-screen-md {
    max-width: 768px !important;
  }

  .mw-sm-screen-lg {
    max-width: 1024px !important;
  }

  .mw-sm-screen-xl {
    max-width: 1280px !important;
  }

  .mw-sm-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-sm-0 {
    max-width: 0 !important;
  }

  .mw-sm-full {
    max-width: 100% !important;
  }

  .mw-sm-read {
    max-width: 65ch !important;
  }

  .mw-sm-min {
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .mw-sm-max {
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .vh-sm-100 {
    height: 100vh !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-initial {
    flex: 0 auto !important;
  }

  .flex-sm-none {
    flex: none !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: .75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-18 {
    margin: 4.5rem !important;
  }

  .m-sm-20 {
    margin: 5rem !important;
  }

  .m-sm-24 {
    margin: 6rem !important;
  }

  .m-sm-32 {
    margin: 8rem !important;
  }

  .m-sm-40 {
    margin: 10rem !important;
  }

  .m-sm-48 {
    margin: 12rem !important;
  }

  .m-sm-56 {
    margin: 14rem !important;
  }

  .m-sm-64 {
    margin: 16rem !important;
  }

  .m-sm-72 {
    margin: 18rem !important;
  }

  .m-sm-80 {
    margin: 20rem !important;
  }

  .m-sm-px {
    margin: 1px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-sm-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-sm-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-sm-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-sm-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-sm-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-sm-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-sm-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-sm-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-sm-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-sm-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-sm-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-sm-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-sm-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-sm-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-sm-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-sm-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-sm-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-sm-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-sm-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-sm-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: .75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-20 {
    margin-top: 5rem !important;
  }

  .mt-sm-24 {
    margin-top: 6rem !important;
  }

  .mt-sm-32 {
    margin-top: 8rem !important;
  }

  .mt-sm-40 {
    margin-top: 10rem !important;
  }

  .mt-sm-48 {
    margin-top: 12rem !important;
  }

  .mt-sm-56 {
    margin-top: 14rem !important;
  }

  .mt-sm-64 {
    margin-top: 16rem !important;
  }

  .mt-sm-72 {
    margin-top: 18rem !important;
  }

  .mt-sm-80 {
    margin-top: 20rem !important;
  }

  .mt-sm-px {
    margin-top: 1px !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: .75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-12 {
    margin-right: 3rem !important;
  }

  .me-sm-14 {
    margin-right: 3.5rem !important;
  }

  .me-sm-16 {
    margin-right: 4rem !important;
  }

  .me-sm-18 {
    margin-right: 4.5rem !important;
  }

  .me-sm-20 {
    margin-right: 5rem !important;
  }

  .me-sm-24 {
    margin-right: 6rem !important;
  }

  .me-sm-32 {
    margin-right: 8rem !important;
  }

  .me-sm-40 {
    margin-right: 10rem !important;
  }

  .me-sm-48 {
    margin-right: 12rem !important;
  }

  .me-sm-56 {
    margin-right: 14rem !important;
  }

  .me-sm-64 {
    margin-right: 16rem !important;
  }

  .me-sm-72 {
    margin-right: 18rem !important;
  }

  .me-sm-80 {
    margin-right: 20rem !important;
  }

  .me-sm-px {
    margin-right: 1px !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: .75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-24 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-32 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-40 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-48 {
    margin-bottom: 12rem !important;
  }

  .mb-sm-56 {
    margin-bottom: 14rem !important;
  }

  .mb-sm-64 {
    margin-bottom: 16rem !important;
  }

  .mb-sm-72 {
    margin-bottom: 18rem !important;
  }

  .mb-sm-80 {
    margin-bottom: 20rem !important;
  }

  .mb-sm-px {
    margin-bottom: 1px !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: .75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-12 {
    margin-left: 3rem !important;
  }

  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-16 {
    margin-left: 4rem !important;
  }

  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-20 {
    margin-left: 5rem !important;
  }

  .ms-sm-24 {
    margin-left: 6rem !important;
  }

  .ms-sm-32 {
    margin-left: 8rem !important;
  }

  .ms-sm-40 {
    margin-left: 10rem !important;
  }

  .ms-sm-48 {
    margin-left: 12rem !important;
  }

  .ms-sm-56 {
    margin-left: 14rem !important;
  }

  .ms-sm-64 {
    margin-left: 16rem !important;
  }

  .ms-sm-72 {
    margin-left: 18rem !important;
  }

  .ms-sm-80 {
    margin-left: 20rem !important;
  }

  .ms-sm-px {
    margin-left: 1px !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n12 {
    margin: -3rem !important;
  }

  .m-sm-n14 {
    margin: -3.5rem !important;
  }

  .m-sm-n16 {
    margin: -4rem !important;
  }

  .m-sm-n18 {
    margin: -4.5rem !important;
  }

  .m-sm-n20 {
    margin: -5rem !important;
  }

  .m-sm-n24 {
    margin: -6rem !important;
  }

  .m-sm-n32 {
    margin: -8rem !important;
  }

  .m-sm-n40 {
    margin: -10rem !important;
  }

  .m-sm-n48 {
    margin: -12rem !important;
  }

  .m-sm-n56 {
    margin: -14rem !important;
  }

  .m-sm-n64 {
    margin: -16rem !important;
  }

  .m-sm-n72 {
    margin: -18rem !important;
  }

  .m-sm-n80 {
    margin: -20rem !important;
  }

  .m-sm-npx {
    margin: -1px !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-sm-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-sm-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-sm-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-sm-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-sm-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-sm-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-sm-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-sm-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-sm-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-sm-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-sm-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-sm-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-sm-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-sm-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-sm-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-sm-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-sm-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-sm-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-sm-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-sm-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-sm-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3rem !important;
  }

  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n16 {
    margin-top: -4rem !important;
  }

  .mt-sm-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n20 {
    margin-top: -5rem !important;
  }

  .mt-sm-n24 {
    margin-top: -6rem !important;
  }

  .mt-sm-n32 {
    margin-top: -8rem !important;
  }

  .mt-sm-n40 {
    margin-top: -10rem !important;
  }

  .mt-sm-n48 {
    margin-top: -12rem !important;
  }

  .mt-sm-n56 {
    margin-top: -14rem !important;
  }

  .mt-sm-n64 {
    margin-top: -16rem !important;
  }

  .mt-sm-n72 {
    margin-top: -18rem !important;
  }

  .mt-sm-n80 {
    margin-top: -20rem !important;
  }

  .mt-sm-npx {
    margin-top: -1px !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n12 {
    margin-right: -3rem !important;
  }

  .me-sm-n14 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n16 {
    margin-right: -4rem !important;
  }

  .me-sm-n18 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n20 {
    margin-right: -5rem !important;
  }

  .me-sm-n24 {
    margin-right: -6rem !important;
  }

  .me-sm-n32 {
    margin-right: -8rem !important;
  }

  .me-sm-n40 {
    margin-right: -10rem !important;
  }

  .me-sm-n48 {
    margin-right: -12rem !important;
  }

  .me-sm-n56 {
    margin-right: -14rem !important;
  }

  .me-sm-n64 {
    margin-right: -16rem !important;
  }

  .me-sm-n72 {
    margin-right: -18rem !important;
  }

  .me-sm-n80 {
    margin-right: -20rem !important;
  }

  .me-sm-npx {
    margin-right: -1px !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-sm-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-sm-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-sm-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-sm-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-sm-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-sm-npx {
    margin-bottom: -1px !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n12 {
    margin-left: -3rem !important;
  }

  .ms-sm-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n16 {
    margin-left: -4rem !important;
  }

  .ms-sm-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n20 {
    margin-left: -5rem !important;
  }

  .ms-sm-n24 {
    margin-left: -6rem !important;
  }

  .ms-sm-n32 {
    margin-left: -8rem !important;
  }

  .ms-sm-n40 {
    margin-left: -10rem !important;
  }

  .ms-sm-n48 {
    margin-left: -12rem !important;
  }

  .ms-sm-n56 {
    margin-left: -14rem !important;
  }

  .ms-sm-n64 {
    margin-left: -16rem !important;
  }

  .ms-sm-n72 {
    margin-left: -18rem !important;
  }

  .ms-sm-n80 {
    margin-left: -20rem !important;
  }

  .ms-sm-npx {
    margin-left: -1px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: .75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .p-sm-18 {
    padding: 4.5rem !important;
  }

  .p-sm-20 {
    padding: 5rem !important;
  }

  .p-sm-24 {
    padding: 6rem !important;
  }

  .p-sm-32 {
    padding: 8rem !important;
  }

  .p-sm-40 {
    padding: 10rem !important;
  }

  .p-sm-48 {
    padding: 12rem !important;
  }

  .p-sm-56 {
    padding: 14rem !important;
  }

  .p-sm-64 {
    padding: 16rem !important;
  }

  .p-sm-72 {
    padding: 18rem !important;
  }

  .p-sm-80 {
    padding: 20rem !important;
  }

  .p-sm-px {
    padding: 1px !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-sm-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-sm-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-sm-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-sm-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-sm-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-sm-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-sm-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-sm-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-sm-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-sm-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-sm-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-sm-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-sm-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-sm-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-sm-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-sm-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-sm-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-sm-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-sm-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-sm-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-sm-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-sm-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-sm-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: .75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-20 {
    padding-top: 5rem !important;
  }

  .pt-sm-24 {
    padding-top: 6rem !important;
  }

  .pt-sm-32 {
    padding-top: 8rem !important;
  }

  .pt-sm-40 {
    padding-top: 10rem !important;
  }

  .pt-sm-48 {
    padding-top: 12rem !important;
  }

  .pt-sm-56 {
    padding-top: 14rem !important;
  }

  .pt-sm-64 {
    padding-top: 16rem !important;
  }

  .pt-sm-72 {
    padding-top: 18rem !important;
  }

  .pt-sm-80 {
    padding-top: 20rem !important;
  }

  .pt-sm-px {
    padding-top: 1px !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: .75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-12 {
    padding-right: 3rem !important;
  }

  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-16 {
    padding-right: 4rem !important;
  }

  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-20 {
    padding-right: 5rem !important;
  }

  .pe-sm-24 {
    padding-right: 6rem !important;
  }

  .pe-sm-32 {
    padding-right: 8rem !important;
  }

  .pe-sm-40 {
    padding-right: 10rem !important;
  }

  .pe-sm-48 {
    padding-right: 12rem !important;
  }

  .pe-sm-56 {
    padding-right: 14rem !important;
  }

  .pe-sm-64 {
    padding-right: 16rem !important;
  }

  .pe-sm-72 {
    padding-right: 18rem !important;
  }

  .pe-sm-80 {
    padding-right: 20rem !important;
  }

  .pe-sm-px {
    padding-right: 1px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: .75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-24 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-32 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-40 {
    padding-bottom: 10rem !important;
  }

  .pb-sm-48 {
    padding-bottom: 12rem !important;
  }

  .pb-sm-56 {
    padding-bottom: 14rem !important;
  }

  .pb-sm-64 {
    padding-bottom: 16rem !important;
  }

  .pb-sm-72 {
    padding-bottom: 18rem !important;
  }

  .pb-sm-80 {
    padding-bottom: 20rem !important;
  }

  .pb-sm-px {
    padding-bottom: 1px !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: .75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-12 {
    padding-left: 3rem !important;
  }

  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-16 {
    padding-left: 4rem !important;
  }

  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-20 {
    padding-left: 5rem !important;
  }

  .ps-sm-24 {
    padding-left: 6rem !important;
  }

  .ps-sm-32 {
    padding-left: 8rem !important;
  }

  .ps-sm-40 {
    padding-left: 10rem !important;
  }

  .ps-sm-48 {
    padding-left: 12rem !important;
  }

  .ps-sm-56 {
    padding-left: 14rem !important;
  }

  .ps-sm-64 {
    padding-left: 16rem !important;
  }

  .ps-sm-72 {
    padding-left: 18rem !important;
  }

  .ps-sm-80 {
    padding-left: 20rem !important;
  }

  .ps-sm-px {
    padding-left: 1px !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: .75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .gap-sm-18 {
    gap: 4.5rem !important;
  }

  .gap-sm-20 {
    gap: 5rem !important;
  }

  .gap-sm-24 {
    gap: 6rem !important;
  }

  .gap-sm-32 {
    gap: 8rem !important;
  }

  .gap-sm-40 {
    gap: 10rem !important;
  }

  .gap-sm-48 {
    gap: 12rem !important;
  }

  .gap-sm-56 {
    gap: 14rem !important;
  }

  .gap-sm-64 {
    gap: 16rem !important;
  }

  .gap-sm-72 {
    gap: 18rem !important;
  }

  .gap-sm-80 {
    gap: 20rem !important;
  }

  .gap-sm-px {
    gap: 1px !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: .75rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-sm-12 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-sm-16 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-sm-20 {
    row-gap: 5rem !important;
  }

  .row-gap-sm-24 {
    row-gap: 6rem !important;
  }

  .row-gap-sm-32 {
    row-gap: 8rem !important;
  }

  .row-gap-sm-40 {
    row-gap: 10rem !important;
  }

  .row-gap-sm-48 {
    row-gap: 12rem !important;
  }

  .row-gap-sm-56 {
    row-gap: 14rem !important;
  }

  .row-gap-sm-64 {
    row-gap: 16rem !important;
  }

  .row-gap-sm-72 {
    row-gap: 18rem !important;
  }

  .row-gap-sm-80 {
    row-gap: 20rem !important;
  }

  .row-gap-sm-px {
    row-gap: 1px !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: .75rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-sm-8 {
    column-gap: 2rem !important;
  }

  .column-gap-sm-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-sm-12 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-sm-16 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-sm-20 {
    column-gap: 5rem !important;
  }

  .column-gap-sm-24 {
    column-gap: 6rem !important;
  }

  .column-gap-sm-32 {
    column-gap: 8rem !important;
  }

  .column-gap-sm-40 {
    column-gap: 10rem !important;
  }

  .column-gap-sm-48 {
    column-gap: 12rem !important;
  }

  .column-gap-sm-56 {
    column-gap: 14rem !important;
  }

  .column-gap-sm-64 {
    column-gap: 16rem !important;
  }

  .column-gap-sm-72 {
    column-gap: 18rem !important;
  }

  .column-gap-sm-80 {
    column-gap: 20rem !important;
  }

  .column-gap-sm-px {
    column-gap: 1px !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .rounded-sm {
    border-radius: .375rem !important;
  }

  .rounded-sm-0 {
    border-radius: 0 !important;
  }

  .rounded-sm-1 {
    border-radius: .25rem !important;
  }

  .rounded-sm-2 {
    border-radius: .5rem !important;
  }

  .rounded-sm-3 {
    border-radius: .75rem !important;
  }

  .rounded-sm-4 {
    border-radius: 1rem !important;
  }

  .rounded-sm-5 {
    border-radius: 1.5rem !important;
  }

  .rounded-sm-6 {
    border-radius: 3rem !important;
  }

  .rounded-sm-pill {
    border-radius: 50rem !important;
  }

  .rounded-sm-circle {
    border-radius: 50% !important;
  }

  .rounded-sm-card {
    border-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-sm {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-sm-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-sm-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-sm-2 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-sm-3 {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-sm-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-sm-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-sm-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-sm-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-sm-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-sm-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-end-sm {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-sm-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-sm-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-sm-2 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-sm-3 {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-end-sm-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-sm-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-end-sm-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-sm-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-sm-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-sm-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-sm {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-sm-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-sm-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-sm-2 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-sm-3 {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-sm-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-sm-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-sm-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-sm-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-sm-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-sm-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-start-sm {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-sm-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-sm-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-sm-2 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-sm-3 {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-start-sm-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-sm-5 {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-start-sm-6 {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-sm-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-sm-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-sm-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-start-sm {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-sm-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-sm-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-sm-2 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-sm-3 {
    border-top-left-radius: .75rem !important;
  }

  .rounded-top-start-sm-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-sm-5 {
    border-top-left-radius: 1.5rem !important;
  }

  .rounded-top-start-sm-6 {
    border-top-left-radius: 3rem !important;
  }

  .rounded-top-start-sm-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-sm-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-sm-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-end-sm {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-sm-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-sm-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-sm-2 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-sm-3 {
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-end-sm-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-sm-5 {
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-end-sm-6 {
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-end-sm-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-sm-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-sm-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-end-sm {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-sm-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-sm-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-sm-2 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-sm-3 {
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-bottom-end-sm-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-sm-5 {
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-bottom-end-sm-6 {
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-end-sm-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-sm-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-sm-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-start-sm {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-sm-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-sm-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-sm-2 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-sm-3 {
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-start-sm-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-sm-5 {
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-start-sm-6 {
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-start-sm-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-sm-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-sm-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .shadow-soft-sm-1, .shadow-soft-sm-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-2, .shadow-soft-sm-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-3, .shadow-soft-sm-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-4, .shadow-soft-sm-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-5, .shadow-soft-sm-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-6, .shadow-soft-sm-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-none, .shadow-soft-sm-none-hover:hover {
    box-shadow: none !important;
  }

  .w-rem-sm-0 {
    width: 0 !important;
  }

  .w-rem-sm-1 {
    width: .25rem !important;
  }

  .w-rem-sm-2 {
    width: .5rem !important;
  }

  .w-rem-sm-3 {
    width: .75rem !important;
  }

  .w-rem-sm-4 {
    width: 1rem !important;
  }

  .w-rem-sm-5 {
    width: 1.25rem !important;
  }

  .w-rem-sm-6 {
    width: 1.5rem !important;
  }

  .w-rem-sm-7 {
    width: 1.75rem !important;
  }

  .w-rem-sm-8 {
    width: 2rem !important;
  }

  .w-rem-sm-10 {
    width: 2.5rem !important;
  }

  .w-rem-sm-12 {
    width: 3rem !important;
  }

  .w-rem-sm-14 {
    width: 3.5rem !important;
  }

  .w-rem-sm-16 {
    width: 4rem !important;
  }

  .w-rem-sm-18 {
    width: 4.5rem !important;
  }

  .w-rem-sm-20 {
    width: 5rem !important;
  }

  .w-rem-sm-24 {
    width: 6rem !important;
  }

  .w-rem-sm-32 {
    width: 8rem !important;
  }

  .w-rem-sm-40 {
    width: 10rem !important;
  }

  .w-rem-sm-48 {
    width: 12rem !important;
  }

  .w-rem-sm-56 {
    width: 14rem !important;
  }

  .w-rem-sm-64 {
    width: 16rem !important;
  }

  .w-rem-sm-72 {
    width: 18rem !important;
  }

  .w-rem-sm-80 {
    width: 20rem !important;
  }

  .w-rem-sm-px {
    width: 1px !important;
  }

  .w-rem-sm-25 {
    width: 25% !important;
  }

  .w-rem-sm-50 {
    width: 50% !important;
  }

  .w-rem-sm-75 {
    width: 75% !important;
  }

  .w-rem-sm-100 {
    width: 100% !important;
  }

  .w-rem-sm-screen-sm {
    width: 640px !important;
  }

  .w-rem-sm-screen-md {
    width: 768px !important;
  }

  .w-rem-sm-screen-lg {
    width: 1024px !important;
  }

  .w-rem-sm-screen-xl {
    width: 1280px !important;
  }

  .w-rem-sm-screen-xxl {
    width: 1536px !important;
  }

  .w-rem-sm-auto {
    width: auto !important;
  }

  .w-rem-sm-full {
    width: 100% !important;
  }

  .w-rem-sm-screen {
    width: 100vw !important;
  }

  .w-rem-sm-min {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .w-rem-sm-max {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .h-rem-sm-0 {
    height: 0 !important;
  }

  .h-rem-sm-1 {
    height: .25rem !important;
  }

  .h-rem-sm-2 {
    height: .5rem !important;
  }

  .h-rem-sm-3 {
    height: .75rem !important;
  }

  .h-rem-sm-4 {
    height: 1rem !important;
  }

  .h-rem-sm-5 {
    height: 1.25rem !important;
  }

  .h-rem-sm-6 {
    height: 1.5rem !important;
  }

  .h-rem-sm-7 {
    height: 1.75rem !important;
  }

  .h-rem-sm-8 {
    height: 2rem !important;
  }

  .h-rem-sm-10 {
    height: 2.5rem !important;
  }

  .h-rem-sm-12 {
    height: 3rem !important;
  }

  .h-rem-sm-14 {
    height: 3.5rem !important;
  }

  .h-rem-sm-16 {
    height: 4rem !important;
  }

  .h-rem-sm-18 {
    height: 4.5rem !important;
  }

  .h-rem-sm-20 {
    height: 5rem !important;
  }

  .h-rem-sm-24 {
    height: 6rem !important;
  }

  .h-rem-sm-32 {
    height: 8rem !important;
  }

  .h-rem-sm-40 {
    height: 10rem !important;
  }

  .h-rem-sm-48 {
    height: 12rem !important;
  }

  .h-rem-sm-56 {
    height: 14rem !important;
  }

  .h-rem-sm-64 {
    height: 16rem !important;
  }

  .h-rem-sm-72 {
    height: 18rem !important;
  }

  .h-rem-sm-80 {
    height: 20rem !important;
  }

  .h-rem-sm-px {
    height: 1px !important;
  }

  .transition-sm {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-sm-none {
    transition-property: none !important;
  }

  .transition-sm-all {
    transition-property: all !important;
  }

  .transition-sm-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-sm-opacity {
    transition-property: opacity !important;
  }

  .transition-sm-shadow {
    transition-property: box-shadow !important;
  }

  .transition-sm-transform {
    transition-property: transform !important;
  }

  .ease-sm-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-sm-linear {
    transition-timing-function: linear !important;
  }

  .ease-sm-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-sm-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-sm-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-sm-none {
    transform: none !important;
  }

  .scale-sm-0, .scale-sm-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }

  .scale-sm-25, .scale-sm-25-hover:hover {
    --bs-scale-x: .25 !important;
    --bs-scale-y: .25 !important;
  }

  .scale-sm-50, .scale-sm-50-hover:hover {
    --bs-scale-x: .5 !important;
    --bs-scale-y: .5 !important;
  }

  .scale-sm-75, .scale-sm-75-hover:hover {
    --bs-scale-x: .75 !important;
    --bs-scale-y: .75 !important;
  }

  .scale-sm-100, .scale-sm-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }

  .scale-sm-105, .scale-sm-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }

  .scale-sm-110, .scale-sm-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }

  .rotate-sm-0, .rotate-sm-0-hover:hover {
    --bs-rotate: 0deg !important;
  }

  .rotate-sm-1, .rotate-sm-1-hover:hover {
    --bs-rotate: 1deg !important;
  }

  .rotate-sm-2, .rotate-sm-2-hover:hover {
    --bs-rotate: 2deg !important;
  }

  .rotate-sm-3, .rotate-sm-3-hover:hover {
    --bs-rotate: 3deg !important;
  }

  .rotate-sm-6, .rotate-sm-6-hover:hover {
    --bs-rotate: 6deg !important;
  }

  .rotate-sm-12, .rotate-sm-12-hover:hover {
    --bs-rotate: 12deg !important;
  }

  .rotate-sm-30, .rotate-sm-30-hover:hover {
    --bs-rotate: 30deg !important;
  }

  .rotate-sm-45, .rotate-sm-45-hover:hover {
    --bs-rotate: 45deg !important;
  }

  .rotate-sm-90, .rotate-sm-90-hover:hover {
    --bs-rotate: 90deg !important;
  }

  .rotate-sm-180, .rotate-sm-180-hover:hover {
    --bs-rotate: 180deg !important;
  }

  .rotate-sm-n1, .rotate-sm-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }

  .rotate-sm-n2, .rotate-sm-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }

  .rotate-sm-n3, .rotate-sm-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }

  .rotate-sm-n6, .rotate-sm-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }

  .rotate-sm-n12, .rotate-sm-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }

  .rotate-sm-n30, .rotate-sm-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }

  .rotate-sm-n45, .rotate-sm-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }

  .rotate-sm-n90, .rotate-sm-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }

  .rotate-sm-n180, .rotate-sm-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }

  .rotate-y-sm-n1, .rotate-y-sm-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }

  .rotate-y-sm-n2, .rotate-y-sm-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }

  .rotate-y-sm-n3, .rotate-y-sm-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }

  .rotate-y-sm-n6, .rotate-y-sm-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }

  .rotate-y-sm-n12, .rotate-y-sm-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }

  .rotate-y-sm-n30, .rotate-y-sm-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }

  .rotate-y-sm-n45, .rotate-y-sm-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }

  .rotate-y-sm-n90, .rotate-y-sm-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }

  .rotate-y-sm-n180, .rotate-y-sm-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }

  .rotate-x-sm-n1 {
    --bs-rotate-x: -1deg !important;
  }

  .rotate-x-sm-n2 {
    --bs-rotate-x: -2deg !important;
  }

  .rotate-x-sm-n3 {
    --bs-rotate-x: -3deg !important;
  }

  .rotate-x-sm-n6 {
    --bs-rotate-x: -6deg !important;
  }

  .rotate-x-sm-n12 {
    --bs-rotate-x: -12deg !important;
  }

  .rotate-x-sm-n30 {
    --bs-rotate-x: -30deg !important;
  }

  .rotate-x-sm-n45 {
    --bs-rotate-x: -45deg !important;
  }

  .rotate-x-sm-n90 {
    --bs-rotate-x: -90deg !important;
  }

  .rotate-x-sm-n180 {
    --bs-rotate-x: -180deg !important;
  }

  .translate-y-sm-0, .translate-y-sm-0-hover:hover {
    --bs-translate-y: 0 !important;
  }

  .translate-y-sm-25, .translate-y-sm-25-hover:hover {
    --bs-translate-y: 25% !important;
  }

  .translate-y-sm-50, .translate-y-sm-50-hover:hover {
    --bs-translate-y: 50% !important;
  }

  .translate-y-sm-75, .translate-y-sm-75-hover:hover {
    --bs-translate-y: 75% !important;
  }

  .translate-y-sm-100, .translate-y-sm-100-hover:hover {
    --bs-translate-y: 100% !important;
  }

  .translate-y-sm-n25, .translate-y-sm-n25-hover:hover {
    --bs-translate-y: -25% !important;
  }

  .translate-y-sm-n50, .translate-y-sm-n50-hover:hover {
    --bs-translate-y: -50% !important;
  }

  .translate-y-sm-n75, .translate-y-sm-n75-hover:hover {
    --bs-translate-y: -75% !important;
  }

  .translate-y-sm-n100, .translate-y-sm-n100-hover:hover {
    --bs-translate-y: -100% !important;
  }

  .translate-x-sm-0, .translate-x-sm-0-hover:hover {
    --bs-translate-x: 0 !important;
  }

  .translate-x-sm-25, .translate-x-sm-25-hover:hover {
    --bs-translate-x: 25% !important;
  }

  .translate-x-sm-50, .translate-x-sm-50-hover:hover {
    --bs-translate-x: 50% !important;
  }

  .translate-x-sm-75, .translate-x-sm-75-hover:hover {
    --bs-translate-x: 75% !important;
  }

  .translate-x-sm-100, .translate-x-sm-100-hover:hover {
    --bs-translate-x: 100% !important;
  }

  .translate-x-sm-n25, .translate-x-sm-n25-hover:hover {
    --bs-translate-x: -25% !important;
  }

  .translate-x-sm-n50, .translate-x-sm-n50-hover:hover {
    --bs-translate-x: -50% !important;
  }

  .translate-x-sm-n75, .translate-x-sm-n75-hover:hover {
    --bs-translate-x: -75% !important;
  }

  .translate-x-sm-n100, .translate-x-sm-n100-hover:hover {
    --bs-translate-x: -100% !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .overflow-md-auto {
    overflow: auto !important;
  }

  .overflow-md-hidden {
    overflow: hidden !important;
  }

  .overflow-md-visible {
    overflow: visible !important;
  }

  .overflow-md-scroll {
    overflow: scroll !important;
  }

  .overflow-x-md-auto {
    overflow-x: auto !important;
  }

  .overflow-x-md-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-md-visible {
    overflow-x: visible !important;
  }

  .overflow-x-md-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-md-auto {
    overflow-y: auto !important;
  }

  .overflow-y-md-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-md-visible {
    overflow-y: visible !important;
  }

  .overflow-y-md-scroll {
    overflow-y: scroll !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .shadow-md, .shadow-md-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-1, .shadow-md-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-2, .shadow-md-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-3, .shadow-md-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-4, .shadow-md-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-5, .shadow-md-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-6, .shadow-md-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-inset, .shadow-md-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-md-outline, .shadow-md-outline-hover:hover, .shadow-md-focus, .shadow-md-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, .5) !important;
  }

  .shadow-md-none, .shadow-md-none-hover:hover {
    box-shadow: none !important;
  }

  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-md-0 {
    top: 0 !important;
  }

  .top-md-50 {
    top: 50% !important;
  }

  .top-md-100 {
    top: 100% !important;
  }

  .top-md-1 {
    top: .25rem !important;
  }

  .top-md-2 {
    top: .5rem !important;
  }

  .top-md-3 {
    top: .75rem !important;
  }

  .top-md-4 {
    top: 1rem !important;
  }

  .top-md-5 {
    top: 1.25rem !important;
  }

  .top-md-6 {
    top: 1.5rem !important;
  }

  .top-md-7 {
    top: 1.75rem !important;
  }

  .top-md-8 {
    top: 2rem !important;
  }

  .top-md-10 {
    top: 2.5rem !important;
  }

  .top-md-12 {
    top: 3rem !important;
  }

  .top-md-14 {
    top: 3.5rem !important;
  }

  .top-md-16 {
    top: 4rem !important;
  }

  .top-md-18 {
    top: 4.5rem !important;
  }

  .top-md-20 {
    top: 5rem !important;
  }

  .top-md-24 {
    top: 6rem !important;
  }

  .top-md-32 {
    top: 8rem !important;
  }

  .top-md-40 {
    top: 10rem !important;
  }

  .top-md-48 {
    top: 12rem !important;
  }

  .top-md-56 {
    top: 14rem !important;
  }

  .top-md-64 {
    top: 16rem !important;
  }

  .top-md-72 {
    top: 18rem !important;
  }

  .top-md-80 {
    top: 20rem !important;
  }

  .top-md-px {
    top: 1px !important;
  }

  .bottom-md-0 {
    bottom: 0 !important;
  }

  .bottom-md-50 {
    bottom: 50% !important;
  }

  .bottom-md-100 {
    bottom: 100% !important;
  }

  .start-md-0 {
    left: 0 !important;
  }

  .start-md-50 {
    left: 50% !important;
  }

  .start-md-100 {
    left: 100% !important;
  }

  .end-md-0 {
    right: 0 !important;
  }

  .end-md-50 {
    right: 50% !important;
  }

  .end-md-100 {
    right: 100% !important;
  }

  .border-md {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-md-0 {
    border: 0 !important;
  }

  .border-top-md {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-md-0 {
    border-top: 0 !important;
  }

  .border-end-md {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-md-0 {
    border-right: 0 !important;
  }

  .border-bottom-md {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }

  .border-start-md {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-md-0 {
    border-left: 0 !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .mw-md-screen-sm {
    max-width: 640px !important;
  }

  .mw-md-screen-md {
    max-width: 768px !important;
  }

  .mw-md-screen-lg {
    max-width: 1024px !important;
  }

  .mw-md-screen-xl {
    max-width: 1280px !important;
  }

  .mw-md-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-md-0 {
    max-width: 0 !important;
  }

  .mw-md-full {
    max-width: 100% !important;
  }

  .mw-md-read {
    max-width: 65ch !important;
  }

  .mw-md-min {
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .mw-md-max {
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .vh-md-100 {
    height: 100vh !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-initial {
    flex: 0 auto !important;
  }

  .flex-md-none {
    flex: none !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: .75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-18 {
    margin: 4.5rem !important;
  }

  .m-md-20 {
    margin: 5rem !important;
  }

  .m-md-24 {
    margin: 6rem !important;
  }

  .m-md-32 {
    margin: 8rem !important;
  }

  .m-md-40 {
    margin: 10rem !important;
  }

  .m-md-48 {
    margin: 12rem !important;
  }

  .m-md-56 {
    margin: 14rem !important;
  }

  .m-md-64 {
    margin: 16rem !important;
  }

  .m-md-72 {
    margin: 18rem !important;
  }

  .m-md-80 {
    margin: 20rem !important;
  }

  .m-md-px {
    margin: 1px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-md-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-md-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-md-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-md-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-md-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-md-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-md-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-md-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-md-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-md-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-md-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-md-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-md-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-md-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-md-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-md-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-md-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-md-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-md-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-md-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-md-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-md-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: .75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-18 {
    margin-top: 4.5rem !important;
  }

  .mt-md-20 {
    margin-top: 5rem !important;
  }

  .mt-md-24 {
    margin-top: 6rem !important;
  }

  .mt-md-32 {
    margin-top: 8rem !important;
  }

  .mt-md-40 {
    margin-top: 10rem !important;
  }

  .mt-md-48 {
    margin-top: 12rem !important;
  }

  .mt-md-56 {
    margin-top: 14rem !important;
  }

  .mt-md-64 {
    margin-top: 16rem !important;
  }

  .mt-md-72 {
    margin-top: 18rem !important;
  }

  .mt-md-80 {
    margin-top: 20rem !important;
  }

  .mt-md-px {
    margin-top: 1px !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: .75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-12 {
    margin-right: 3rem !important;
  }

  .me-md-14 {
    margin-right: 3.5rem !important;
  }

  .me-md-16 {
    margin-right: 4rem !important;
  }

  .me-md-18 {
    margin-right: 4.5rem !important;
  }

  .me-md-20 {
    margin-right: 5rem !important;
  }

  .me-md-24 {
    margin-right: 6rem !important;
  }

  .me-md-32 {
    margin-right: 8rem !important;
  }

  .me-md-40 {
    margin-right: 10rem !important;
  }

  .me-md-48 {
    margin-right: 12rem !important;
  }

  .me-md-56 {
    margin-right: 14rem !important;
  }

  .me-md-64 {
    margin-right: 16rem !important;
  }

  .me-md-72 {
    margin-right: 18rem !important;
  }

  .me-md-80 {
    margin-right: 20rem !important;
  }

  .me-md-px {
    margin-right: 1px !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: .75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-20 {
    margin-bottom: 5rem !important;
  }

  .mb-md-24 {
    margin-bottom: 6rem !important;
  }

  .mb-md-32 {
    margin-bottom: 8rem !important;
  }

  .mb-md-40 {
    margin-bottom: 10rem !important;
  }

  .mb-md-48 {
    margin-bottom: 12rem !important;
  }

  .mb-md-56 {
    margin-bottom: 14rem !important;
  }

  .mb-md-64 {
    margin-bottom: 16rem !important;
  }

  .mb-md-72 {
    margin-bottom: 18rem !important;
  }

  .mb-md-80 {
    margin-bottom: 20rem !important;
  }

  .mb-md-px {
    margin-bottom: 1px !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: .75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-12 {
    margin-left: 3rem !important;
  }

  .ms-md-14 {
    margin-left: 3.5rem !important;
  }

  .ms-md-16 {
    margin-left: 4rem !important;
  }

  .ms-md-18 {
    margin-left: 4.5rem !important;
  }

  .ms-md-20 {
    margin-left: 5rem !important;
  }

  .ms-md-24 {
    margin-left: 6rem !important;
  }

  .ms-md-32 {
    margin-left: 8rem !important;
  }

  .ms-md-40 {
    margin-left: 10rem !important;
  }

  .ms-md-48 {
    margin-left: 12rem !important;
  }

  .ms-md-56 {
    margin-left: 14rem !important;
  }

  .ms-md-64 {
    margin-left: 16rem !important;
  }

  .ms-md-72 {
    margin-left: 18rem !important;
  }

  .ms-md-80 {
    margin-left: 20rem !important;
  }

  .ms-md-px {
    margin-left: 1px !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n12 {
    margin: -3rem !important;
  }

  .m-md-n14 {
    margin: -3.5rem !important;
  }

  .m-md-n16 {
    margin: -4rem !important;
  }

  .m-md-n18 {
    margin: -4.5rem !important;
  }

  .m-md-n20 {
    margin: -5rem !important;
  }

  .m-md-n24 {
    margin: -6rem !important;
  }

  .m-md-n32 {
    margin: -8rem !important;
  }

  .m-md-n40 {
    margin: -10rem !important;
  }

  .m-md-n48 {
    margin: -12rem !important;
  }

  .m-md-n56 {
    margin: -14rem !important;
  }

  .m-md-n64 {
    margin: -16rem !important;
  }

  .m-md-n72 {
    margin: -18rem !important;
  }

  .m-md-n80 {
    margin: -20rem !important;
  }

  .m-md-npx {
    margin: -1px !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-md-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-md-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-md-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-md-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-md-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-md-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-md-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-md-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-md-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-md-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-md-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-md-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-md-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-md-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-md-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-md-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-md-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-md-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-md-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-md-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-md-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-md-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n12 {
    margin-top: -3rem !important;
  }

  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n16 {
    margin-top: -4rem !important;
  }

  .mt-md-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n20 {
    margin-top: -5rem !important;
  }

  .mt-md-n24 {
    margin-top: -6rem !important;
  }

  .mt-md-n32 {
    margin-top: -8rem !important;
  }

  .mt-md-n40 {
    margin-top: -10rem !important;
  }

  .mt-md-n48 {
    margin-top: -12rem !important;
  }

  .mt-md-n56 {
    margin-top: -14rem !important;
  }

  .mt-md-n64 {
    margin-top: -16rem !important;
  }

  .mt-md-n72 {
    margin-top: -18rem !important;
  }

  .mt-md-n80 {
    margin-top: -20rem !important;
  }

  .mt-md-npx {
    margin-top: -1px !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .me-md-n12 {
    margin-right: -3rem !important;
  }

  .me-md-n14 {
    margin-right: -3.5rem !important;
  }

  .me-md-n16 {
    margin-right: -4rem !important;
  }

  .me-md-n18 {
    margin-right: -4.5rem !important;
  }

  .me-md-n20 {
    margin-right: -5rem !important;
  }

  .me-md-n24 {
    margin-right: -6rem !important;
  }

  .me-md-n32 {
    margin-right: -8rem !important;
  }

  .me-md-n40 {
    margin-right: -10rem !important;
  }

  .me-md-n48 {
    margin-right: -12rem !important;
  }

  .me-md-n56 {
    margin-right: -14rem !important;
  }

  .me-md-n64 {
    margin-right: -16rem !important;
  }

  .me-md-n72 {
    margin-right: -18rem !important;
  }

  .me-md-n80 {
    margin-right: -20rem !important;
  }

  .me-md-npx {
    margin-right: -1px !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-md-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-md-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-md-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-md-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-md-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-md-npx {
    margin-bottom: -1px !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n12 {
    margin-left: -3rem !important;
  }

  .ms-md-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n16 {
    margin-left: -4rem !important;
  }

  .ms-md-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n20 {
    margin-left: -5rem !important;
  }

  .ms-md-n24 {
    margin-left: -6rem !important;
  }

  .ms-md-n32 {
    margin-left: -8rem !important;
  }

  .ms-md-n40 {
    margin-left: -10rem !important;
  }

  .ms-md-n48 {
    margin-left: -12rem !important;
  }

  .ms-md-n56 {
    margin-left: -14rem !important;
  }

  .ms-md-n64 {
    margin-left: -16rem !important;
  }

  .ms-md-n72 {
    margin-left: -18rem !important;
  }

  .ms-md-n80 {
    margin-left: -20rem !important;
  }

  .ms-md-npx {
    margin-left: -1px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: .75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .p-md-18 {
    padding: 4.5rem !important;
  }

  .p-md-20 {
    padding: 5rem !important;
  }

  .p-md-24 {
    padding: 6rem !important;
  }

  .p-md-32 {
    padding: 8rem !important;
  }

  .p-md-40 {
    padding: 10rem !important;
  }

  .p-md-48 {
    padding: 12rem !important;
  }

  .p-md-56 {
    padding: 14rem !important;
  }

  .p-md-64 {
    padding: 16rem !important;
  }

  .p-md-72 {
    padding: 18rem !important;
  }

  .p-md-80 {
    padding: 20rem !important;
  }

  .p-md-px {
    padding: 1px !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-md-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-md-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-md-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-md-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-md-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-md-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-md-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-md-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-md-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-md-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-md-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-md-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-md-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-md-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-md-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-md-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-md-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-md-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-md-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-md-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-md-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-md-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-md-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: .75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pt-md-18 {
    padding-top: 4.5rem !important;
  }

  .pt-md-20 {
    padding-top: 5rem !important;
  }

  .pt-md-24 {
    padding-top: 6rem !important;
  }

  .pt-md-32 {
    padding-top: 8rem !important;
  }

  .pt-md-40 {
    padding-top: 10rem !important;
  }

  .pt-md-48 {
    padding-top: 12rem !important;
  }

  .pt-md-56 {
    padding-top: 14rem !important;
  }

  .pt-md-64 {
    padding-top: 16rem !important;
  }

  .pt-md-72 {
    padding-top: 18rem !important;
  }

  .pt-md-80 {
    padding-top: 20rem !important;
  }

  .pt-md-px {
    padding-top: 1px !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: .75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-12 {
    padding-right: 3rem !important;
  }

  .pe-md-14 {
    padding-right: 3.5rem !important;
  }

  .pe-md-16 {
    padding-right: 4rem !important;
  }

  .pe-md-18 {
    padding-right: 4.5rem !important;
  }

  .pe-md-20 {
    padding-right: 5rem !important;
  }

  .pe-md-24 {
    padding-right: 6rem !important;
  }

  .pe-md-32 {
    padding-right: 8rem !important;
  }

  .pe-md-40 {
    padding-right: 10rem !important;
  }

  .pe-md-48 {
    padding-right: 12rem !important;
  }

  .pe-md-56 {
    padding-right: 14rem !important;
  }

  .pe-md-64 {
    padding-right: 16rem !important;
  }

  .pe-md-72 {
    padding-right: 18rem !important;
  }

  .pe-md-80 {
    padding-right: 20rem !important;
  }

  .pe-md-px {
    padding-right: 1px !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: .75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-20 {
    padding-bottom: 5rem !important;
  }

  .pb-md-24 {
    padding-bottom: 6rem !important;
  }

  .pb-md-32 {
    padding-bottom: 8rem !important;
  }

  .pb-md-40 {
    padding-bottom: 10rem !important;
  }

  .pb-md-48 {
    padding-bottom: 12rem !important;
  }

  .pb-md-56 {
    padding-bottom: 14rem !important;
  }

  .pb-md-64 {
    padding-bottom: 16rem !important;
  }

  .pb-md-72 {
    padding-bottom: 18rem !important;
  }

  .pb-md-80 {
    padding-bottom: 20rem !important;
  }

  .pb-md-px {
    padding-bottom: 1px !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: .75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-12 {
    padding-left: 3rem !important;
  }

  .ps-md-14 {
    padding-left: 3.5rem !important;
  }

  .ps-md-16 {
    padding-left: 4rem !important;
  }

  .ps-md-18 {
    padding-left: 4.5rem !important;
  }

  .ps-md-20 {
    padding-left: 5rem !important;
  }

  .ps-md-24 {
    padding-left: 6rem !important;
  }

  .ps-md-32 {
    padding-left: 8rem !important;
  }

  .ps-md-40 {
    padding-left: 10rem !important;
  }

  .ps-md-48 {
    padding-left: 12rem !important;
  }

  .ps-md-56 {
    padding-left: 14rem !important;
  }

  .ps-md-64 {
    padding-left: 16rem !important;
  }

  .ps-md-72 {
    padding-left: 18rem !important;
  }

  .ps-md-80 {
    padding-left: 20rem !important;
  }

  .ps-md-px {
    padding-left: 1px !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: .75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .gap-md-18 {
    gap: 4.5rem !important;
  }

  .gap-md-20 {
    gap: 5rem !important;
  }

  .gap-md-24 {
    gap: 6rem !important;
  }

  .gap-md-32 {
    gap: 8rem !important;
  }

  .gap-md-40 {
    gap: 10rem !important;
  }

  .gap-md-48 {
    gap: 12rem !important;
  }

  .gap-md-56 {
    gap: 14rem !important;
  }

  .gap-md-64 {
    gap: 16rem !important;
  }

  .gap-md-72 {
    gap: 18rem !important;
  }

  .gap-md-80 {
    gap: 20rem !important;
  }

  .gap-md-px {
    gap: 1px !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: .75rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1rem !important;
  }

  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-md-8 {
    row-gap: 2rem !important;
  }

  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-md-12 {
    row-gap: 3rem !important;
  }

  .row-gap-md-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-md-16 {
    row-gap: 4rem !important;
  }

  .row-gap-md-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-md-20 {
    row-gap: 5rem !important;
  }

  .row-gap-md-24 {
    row-gap: 6rem !important;
  }

  .row-gap-md-32 {
    row-gap: 8rem !important;
  }

  .row-gap-md-40 {
    row-gap: 10rem !important;
  }

  .row-gap-md-48 {
    row-gap: 12rem !important;
  }

  .row-gap-md-56 {
    row-gap: 14rem !important;
  }

  .row-gap-md-64 {
    row-gap: 16rem !important;
  }

  .row-gap-md-72 {
    row-gap: 18rem !important;
  }

  .row-gap-md-80 {
    row-gap: 20rem !important;
  }

  .row-gap-md-px {
    row-gap: 1px !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    column-gap: .75rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1rem !important;
  }

  .column-gap-md-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-md-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-md-8 {
    column-gap: 2rem !important;
  }

  .column-gap-md-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-md-12 {
    column-gap: 3rem !important;
  }

  .column-gap-md-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-md-16 {
    column-gap: 4rem !important;
  }

  .column-gap-md-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-md-20 {
    column-gap: 5rem !important;
  }

  .column-gap-md-24 {
    column-gap: 6rem !important;
  }

  .column-gap-md-32 {
    column-gap: 8rem !important;
  }

  .column-gap-md-40 {
    column-gap: 10rem !important;
  }

  .column-gap-md-48 {
    column-gap: 12rem !important;
  }

  .column-gap-md-56 {
    column-gap: 14rem !important;
  }

  .column-gap-md-64 {
    column-gap: 16rem !important;
  }

  .column-gap-md-72 {
    column-gap: 18rem !important;
  }

  .column-gap-md-80 {
    column-gap: 20rem !important;
  }

  .column-gap-md-px {
    column-gap: 1px !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .rounded-md {
    border-radius: .375rem !important;
  }

  .rounded-md-0 {
    border-radius: 0 !important;
  }

  .rounded-md-1 {
    border-radius: .25rem !important;
  }

  .rounded-md-2 {
    border-radius: .5rem !important;
  }

  .rounded-md-3 {
    border-radius: .75rem !important;
  }

  .rounded-md-4 {
    border-radius: 1rem !important;
  }

  .rounded-md-5 {
    border-radius: 1.5rem !important;
  }

  .rounded-md-6 {
    border-radius: 3rem !important;
  }

  .rounded-md-pill {
    border-radius: 50rem !important;
  }

  .rounded-md-circle {
    border-radius: 50% !important;
  }

  .rounded-md-card {
    border-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-md-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-md-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-md-2 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-md-3 {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-md-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-md-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-md-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-md-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-md-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-md-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-end-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-md-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-md-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-md-2 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-md-3 {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-end-md-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-md-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-end-md-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-md-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-md-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-md-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-md-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-md-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-md-2 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-md-3 {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-md-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-md-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-md-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-md-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-md-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-md-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-start-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-md-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-md-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-md-2 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-md-3 {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-start-md-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-md-5 {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-start-md-6 {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-md-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-md-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-md-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-start-md {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-md-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-md-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-md-2 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-md-3 {
    border-top-left-radius: .75rem !important;
  }

  .rounded-top-start-md-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-md-5 {
    border-top-left-radius: 1.5rem !important;
  }

  .rounded-top-start-md-6 {
    border-top-left-radius: 3rem !important;
  }

  .rounded-top-start-md-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-md-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-md-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-end-md {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-md-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-md-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-md-2 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-md-3 {
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-end-md-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-md-5 {
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-end-md-6 {
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-end-md-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-md-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-md-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-end-md {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-md-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-md-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-md-2 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-md-3 {
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-bottom-end-md-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-md-5 {
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-bottom-end-md-6 {
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-end-md-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-md-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-md-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-start-md {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-md-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-md-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-md-2 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-md-3 {
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-start-md-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-md-5 {
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-start-md-6 {
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-start-md-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-md-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-md-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .shadow-soft-md-1, .shadow-soft-md-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-2, .shadow-soft-md-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-3, .shadow-soft-md-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-4, .shadow-soft-md-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-5, .shadow-soft-md-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-6, .shadow-soft-md-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-none, .shadow-soft-md-none-hover:hover {
    box-shadow: none !important;
  }

  .w-rem-md-0 {
    width: 0 !important;
  }

  .w-rem-md-1 {
    width: .25rem !important;
  }

  .w-rem-md-2 {
    width: .5rem !important;
  }

  .w-rem-md-3 {
    width: .75rem !important;
  }

  .w-rem-md-4 {
    width: 1rem !important;
  }

  .w-rem-md-5 {
    width: 1.25rem !important;
  }

  .w-rem-md-6 {
    width: 1.5rem !important;
  }

  .w-rem-md-7 {
    width: 1.75rem !important;
  }

  .w-rem-md-8 {
    width: 2rem !important;
  }

  .w-rem-md-10 {
    width: 2.5rem !important;
  }

  .w-rem-md-12 {
    width: 3rem !important;
  }

  .w-rem-md-14 {
    width: 3.5rem !important;
  }

  .w-rem-md-16 {
    width: 4rem !important;
  }

  .w-rem-md-18 {
    width: 4.5rem !important;
  }

  .w-rem-md-20 {
    width: 5rem !important;
  }

  .w-rem-md-24 {
    width: 6rem !important;
  }

  .w-rem-md-32 {
    width: 8rem !important;
  }

  .w-rem-md-40 {
    width: 10rem !important;
  }

  .w-rem-md-48 {
    width: 12rem !important;
  }

  .w-rem-md-56 {
    width: 14rem !important;
  }

  .w-rem-md-64 {
    width: 16rem !important;
  }

  .w-rem-md-72 {
    width: 18rem !important;
  }

  .w-rem-md-80 {
    width: 20rem !important;
  }

  .w-rem-md-px {
    width: 1px !important;
  }

  .w-rem-md-25 {
    width: 25% !important;
  }

  .w-rem-md-50 {
    width: 50% !important;
  }

  .w-rem-md-75 {
    width: 75% !important;
  }

  .w-rem-md-100 {
    width: 100% !important;
  }

  .w-rem-md-screen-sm {
    width: 640px !important;
  }

  .w-rem-md-screen-md {
    width: 768px !important;
  }

  .w-rem-md-screen-lg {
    width: 1024px !important;
  }

  .w-rem-md-screen-xl {
    width: 1280px !important;
  }

  .w-rem-md-screen-xxl {
    width: 1536px !important;
  }

  .w-rem-md-auto {
    width: auto !important;
  }

  .w-rem-md-full {
    width: 100% !important;
  }

  .w-rem-md-screen {
    width: 100vw !important;
  }

  .w-rem-md-min {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .w-rem-md-max {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .h-rem-md-0 {
    height: 0 !important;
  }

  .h-rem-md-1 {
    height: .25rem !important;
  }

  .h-rem-md-2 {
    height: .5rem !important;
  }

  .h-rem-md-3 {
    height: .75rem !important;
  }

  .h-rem-md-4 {
    height: 1rem !important;
  }

  .h-rem-md-5 {
    height: 1.25rem !important;
  }

  .h-rem-md-6 {
    height: 1.5rem !important;
  }

  .h-rem-md-7 {
    height: 1.75rem !important;
  }

  .h-rem-md-8 {
    height: 2rem !important;
  }

  .h-rem-md-10 {
    height: 2.5rem !important;
  }

  .h-rem-md-12 {
    height: 3rem !important;
  }

  .h-rem-md-14 {
    height: 3.5rem !important;
  }

  .h-rem-md-16 {
    height: 4rem !important;
  }

  .h-rem-md-18 {
    height: 4.5rem !important;
  }

  .h-rem-md-20 {
    height: 5rem !important;
  }

  .h-rem-md-24 {
    height: 6rem !important;
  }

  .h-rem-md-32 {
    height: 8rem !important;
  }

  .h-rem-md-40 {
    height: 10rem !important;
  }

  .h-rem-md-48 {
    height: 12rem !important;
  }

  .h-rem-md-56 {
    height: 14rem !important;
  }

  .h-rem-md-64 {
    height: 16rem !important;
  }

  .h-rem-md-72 {
    height: 18rem !important;
  }

  .h-rem-md-80 {
    height: 20rem !important;
  }

  .h-rem-md-px {
    height: 1px !important;
  }

  .transition-md {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-md-none {
    transition-property: none !important;
  }

  .transition-md-all {
    transition-property: all !important;
  }

  .transition-md-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-md-opacity {
    transition-property: opacity !important;
  }

  .transition-md-shadow {
    transition-property: box-shadow !important;
  }

  .transition-md-transform {
    transition-property: transform !important;
  }

  .ease-md-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-md-linear {
    transition-timing-function: linear !important;
  }

  .ease-md-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-md-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-md-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-md-none {
    transform: none !important;
  }

  .scale-md-0, .scale-md-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }

  .scale-md-25, .scale-md-25-hover:hover {
    --bs-scale-x: .25 !important;
    --bs-scale-y: .25 !important;
  }

  .scale-md-50, .scale-md-50-hover:hover {
    --bs-scale-x: .5 !important;
    --bs-scale-y: .5 !important;
  }

  .scale-md-75, .scale-md-75-hover:hover {
    --bs-scale-x: .75 !important;
    --bs-scale-y: .75 !important;
  }

  .scale-md-100, .scale-md-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }

  .scale-md-105, .scale-md-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }

  .scale-md-110, .scale-md-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }

  .rotate-md-0, .rotate-md-0-hover:hover {
    --bs-rotate: 0deg !important;
  }

  .rotate-md-1, .rotate-md-1-hover:hover {
    --bs-rotate: 1deg !important;
  }

  .rotate-md-2, .rotate-md-2-hover:hover {
    --bs-rotate: 2deg !important;
  }

  .rotate-md-3, .rotate-md-3-hover:hover {
    --bs-rotate: 3deg !important;
  }

  .rotate-md-6, .rotate-md-6-hover:hover {
    --bs-rotate: 6deg !important;
  }

  .rotate-md-12, .rotate-md-12-hover:hover {
    --bs-rotate: 12deg !important;
  }

  .rotate-md-30, .rotate-md-30-hover:hover {
    --bs-rotate: 30deg !important;
  }

  .rotate-md-45, .rotate-md-45-hover:hover {
    --bs-rotate: 45deg !important;
  }

  .rotate-md-90, .rotate-md-90-hover:hover {
    --bs-rotate: 90deg !important;
  }

  .rotate-md-180, .rotate-md-180-hover:hover {
    --bs-rotate: 180deg !important;
  }

  .rotate-md-n1, .rotate-md-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }

  .rotate-md-n2, .rotate-md-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }

  .rotate-md-n3, .rotate-md-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }

  .rotate-md-n6, .rotate-md-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }

  .rotate-md-n12, .rotate-md-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }

  .rotate-md-n30, .rotate-md-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }

  .rotate-md-n45, .rotate-md-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }

  .rotate-md-n90, .rotate-md-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }

  .rotate-md-n180, .rotate-md-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }

  .rotate-y-md-n1, .rotate-y-md-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }

  .rotate-y-md-n2, .rotate-y-md-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }

  .rotate-y-md-n3, .rotate-y-md-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }

  .rotate-y-md-n6, .rotate-y-md-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }

  .rotate-y-md-n12, .rotate-y-md-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }

  .rotate-y-md-n30, .rotate-y-md-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }

  .rotate-y-md-n45, .rotate-y-md-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }

  .rotate-y-md-n90, .rotate-y-md-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }

  .rotate-y-md-n180, .rotate-y-md-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }

  .rotate-x-md-n1 {
    --bs-rotate-x: -1deg !important;
  }

  .rotate-x-md-n2 {
    --bs-rotate-x: -2deg !important;
  }

  .rotate-x-md-n3 {
    --bs-rotate-x: -3deg !important;
  }

  .rotate-x-md-n6 {
    --bs-rotate-x: -6deg !important;
  }

  .rotate-x-md-n12 {
    --bs-rotate-x: -12deg !important;
  }

  .rotate-x-md-n30 {
    --bs-rotate-x: -30deg !important;
  }

  .rotate-x-md-n45 {
    --bs-rotate-x: -45deg !important;
  }

  .rotate-x-md-n90 {
    --bs-rotate-x: -90deg !important;
  }

  .rotate-x-md-n180 {
    --bs-rotate-x: -180deg !important;
  }

  .translate-y-md-0, .translate-y-md-0-hover:hover {
    --bs-translate-y: 0 !important;
  }

  .translate-y-md-25, .translate-y-md-25-hover:hover {
    --bs-translate-y: 25% !important;
  }

  .translate-y-md-50, .translate-y-md-50-hover:hover {
    --bs-translate-y: 50% !important;
  }

  .translate-y-md-75, .translate-y-md-75-hover:hover {
    --bs-translate-y: 75% !important;
  }

  .translate-y-md-100, .translate-y-md-100-hover:hover {
    --bs-translate-y: 100% !important;
  }

  .translate-y-md-n25, .translate-y-md-n25-hover:hover {
    --bs-translate-y: -25% !important;
  }

  .translate-y-md-n50, .translate-y-md-n50-hover:hover {
    --bs-translate-y: -50% !important;
  }

  .translate-y-md-n75, .translate-y-md-n75-hover:hover {
    --bs-translate-y: -75% !important;
  }

  .translate-y-md-n100, .translate-y-md-n100-hover:hover {
    --bs-translate-y: -100% !important;
  }

  .translate-x-md-0, .translate-x-md-0-hover:hover {
    --bs-translate-x: 0 !important;
  }

  .translate-x-md-25, .translate-x-md-25-hover:hover {
    --bs-translate-x: 25% !important;
  }

  .translate-x-md-50, .translate-x-md-50-hover:hover {
    --bs-translate-x: 50% !important;
  }

  .translate-x-md-75, .translate-x-md-75-hover:hover {
    --bs-translate-x: 75% !important;
  }

  .translate-x-md-100, .translate-x-md-100-hover:hover {
    --bs-translate-x: 100% !important;
  }

  .translate-x-md-n25, .translate-x-md-n25-hover:hover {
    --bs-translate-x: -25% !important;
  }

  .translate-x-md-n50, .translate-x-md-n50-hover:hover {
    --bs-translate-x: -50% !important;
  }

  .translate-x-md-n75, .translate-x-md-n75-hover:hover {
    --bs-translate-x: -75% !important;
  }

  .translate-x-md-n100, .translate-x-md-n100-hover:hover {
    --bs-translate-x: -100% !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .overflow-lg-auto {
    overflow: auto !important;
  }

  .overflow-lg-hidden {
    overflow: hidden !important;
  }

  .overflow-lg-visible {
    overflow: visible !important;
  }

  .overflow-lg-scroll {
    overflow: scroll !important;
  }

  .overflow-x-lg-auto {
    overflow-x: auto !important;
  }

  .overflow-x-lg-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-lg-visible {
    overflow-x: visible !important;
  }

  .overflow-x-lg-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-lg-auto {
    overflow-y: auto !important;
  }

  .overflow-y-lg-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-lg-visible {
    overflow-y: visible !important;
  }

  .overflow-y-lg-scroll {
    overflow-y: scroll !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .shadow-lg, .shadow-lg-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-1, .shadow-lg-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-2, .shadow-lg-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-3, .shadow-lg-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-4, .shadow-lg-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-5, .shadow-lg-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-6, .shadow-lg-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-inset, .shadow-lg-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-lg-outline, .shadow-lg-outline-hover:hover, .shadow-lg-focus, .shadow-lg-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, .5) !important;
  }

  .shadow-lg-none, .shadow-lg-none-hover:hover {
    box-shadow: none !important;
  }

  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-lg-0 {
    top: 0 !important;
  }

  .top-lg-50 {
    top: 50% !important;
  }

  .top-lg-100 {
    top: 100% !important;
  }

  .top-lg-1 {
    top: .25rem !important;
  }

  .top-lg-2 {
    top: .5rem !important;
  }

  .top-lg-3 {
    top: .75rem !important;
  }

  .top-lg-4 {
    top: 1rem !important;
  }

  .top-lg-5 {
    top: 1.25rem !important;
  }

  .top-lg-6 {
    top: 1.5rem !important;
  }

  .top-lg-7 {
    top: 1.75rem !important;
  }

  .top-lg-8 {
    top: 2rem !important;
  }

  .top-lg-10 {
    top: 2.5rem !important;
  }

  .top-lg-12 {
    top: 3rem !important;
  }

  .top-lg-14 {
    top: 3.5rem !important;
  }

  .top-lg-16 {
    top: 4rem !important;
  }

  .top-lg-18 {
    top: 4.5rem !important;
  }

  .top-lg-20 {
    top: 5rem !important;
  }

  .top-lg-24 {
    top: 6rem !important;
  }

  .top-lg-32 {
    top: 8rem !important;
  }

  .top-lg-40 {
    top: 10rem !important;
  }

  .top-lg-48 {
    top: 12rem !important;
  }

  .top-lg-56 {
    top: 14rem !important;
  }

  .top-lg-64 {
    top: 16rem !important;
  }

  .top-lg-72 {
    top: 18rem !important;
  }

  .top-lg-80 {
    top: 20rem !important;
  }

  .top-lg-px {
    top: 1px !important;
  }

  .bottom-lg-0 {
    bottom: 0 !important;
  }

  .bottom-lg-50 {
    bottom: 50% !important;
  }

  .bottom-lg-100 {
    bottom: 100% !important;
  }

  .start-lg-0 {
    left: 0 !important;
  }

  .start-lg-50 {
    left: 50% !important;
  }

  .start-lg-100 {
    left: 100% !important;
  }

  .end-lg-0 {
    right: 0 !important;
  }

  .end-lg-50 {
    right: 50% !important;
  }

  .end-lg-100 {
    right: 100% !important;
  }

  .border-lg {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-lg-0 {
    border: 0 !important;
  }

  .border-top-lg {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-lg-0 {
    border-top: 0 !important;
  }

  .border-end-lg {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-lg-0 {
    border-right: 0 !important;
  }

  .border-bottom-lg {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }

  .border-start-lg {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-lg-0 {
    border-left: 0 !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .mw-lg-screen-sm {
    max-width: 640px !important;
  }

  .mw-lg-screen-md {
    max-width: 768px !important;
  }

  .mw-lg-screen-lg {
    max-width: 1024px !important;
  }

  .mw-lg-screen-xl {
    max-width: 1280px !important;
  }

  .mw-lg-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-lg-0 {
    max-width: 0 !important;
  }

  .mw-lg-full {
    max-width: 100% !important;
  }

  .mw-lg-read {
    max-width: 65ch !important;
  }

  .mw-lg-min {
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .mw-lg-max {
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .vh-lg-100 {
    height: 100vh !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-initial {
    flex: 0 auto !important;
  }

  .flex-lg-none {
    flex: none !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: .75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-18 {
    margin: 4.5rem !important;
  }

  .m-lg-20 {
    margin: 5rem !important;
  }

  .m-lg-24 {
    margin: 6rem !important;
  }

  .m-lg-32 {
    margin: 8rem !important;
  }

  .m-lg-40 {
    margin: 10rem !important;
  }

  .m-lg-48 {
    margin: 12rem !important;
  }

  .m-lg-56 {
    margin: 14rem !important;
  }

  .m-lg-64 {
    margin: 16rem !important;
  }

  .m-lg-72 {
    margin: 18rem !important;
  }

  .m-lg-80 {
    margin: 20rem !important;
  }

  .m-lg-px {
    margin: 1px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-lg-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-lg-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-lg-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-lg-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-lg-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-lg-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-lg-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-lg-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-lg-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-lg-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-lg-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-lg-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-lg-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-lg-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-lg-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-lg-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-lg-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-lg-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-lg-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-lg-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: .75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-20 {
    margin-top: 5rem !important;
  }

  .mt-lg-24 {
    margin-top: 6rem !important;
  }

  .mt-lg-32 {
    margin-top: 8rem !important;
  }

  .mt-lg-40 {
    margin-top: 10rem !important;
  }

  .mt-lg-48 {
    margin-top: 12rem !important;
  }

  .mt-lg-56 {
    margin-top: 14rem !important;
  }

  .mt-lg-64 {
    margin-top: 16rem !important;
  }

  .mt-lg-72 {
    margin-top: 18rem !important;
  }

  .mt-lg-80 {
    margin-top: 20rem !important;
  }

  .mt-lg-px {
    margin-top: 1px !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: .75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-12 {
    margin-right: 3rem !important;
  }

  .me-lg-14 {
    margin-right: 3.5rem !important;
  }

  .me-lg-16 {
    margin-right: 4rem !important;
  }

  .me-lg-18 {
    margin-right: 4.5rem !important;
  }

  .me-lg-20 {
    margin-right: 5rem !important;
  }

  .me-lg-24 {
    margin-right: 6rem !important;
  }

  .me-lg-32 {
    margin-right: 8rem !important;
  }

  .me-lg-40 {
    margin-right: 10rem !important;
  }

  .me-lg-48 {
    margin-right: 12rem !important;
  }

  .me-lg-56 {
    margin-right: 14rem !important;
  }

  .me-lg-64 {
    margin-right: 16rem !important;
  }

  .me-lg-72 {
    margin-right: 18rem !important;
  }

  .me-lg-80 {
    margin-right: 20rem !important;
  }

  .me-lg-px {
    margin-right: 1px !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: .75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-24 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-32 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-40 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-48 {
    margin-bottom: 12rem !important;
  }

  .mb-lg-56 {
    margin-bottom: 14rem !important;
  }

  .mb-lg-64 {
    margin-bottom: 16rem !important;
  }

  .mb-lg-72 {
    margin-bottom: 18rem !important;
  }

  .mb-lg-80 {
    margin-bottom: 20rem !important;
  }

  .mb-lg-px {
    margin-bottom: 1px !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: .75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-12 {
    margin-left: 3rem !important;
  }

  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-16 {
    margin-left: 4rem !important;
  }

  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-20 {
    margin-left: 5rem !important;
  }

  .ms-lg-24 {
    margin-left: 6rem !important;
  }

  .ms-lg-32 {
    margin-left: 8rem !important;
  }

  .ms-lg-40 {
    margin-left: 10rem !important;
  }

  .ms-lg-48 {
    margin-left: 12rem !important;
  }

  .ms-lg-56 {
    margin-left: 14rem !important;
  }

  .ms-lg-64 {
    margin-left: 16rem !important;
  }

  .ms-lg-72 {
    margin-left: 18rem !important;
  }

  .ms-lg-80 {
    margin-left: 20rem !important;
  }

  .ms-lg-px {
    margin-left: 1px !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n12 {
    margin: -3rem !important;
  }

  .m-lg-n14 {
    margin: -3.5rem !important;
  }

  .m-lg-n16 {
    margin: -4rem !important;
  }

  .m-lg-n18 {
    margin: -4.5rem !important;
  }

  .m-lg-n20 {
    margin: -5rem !important;
  }

  .m-lg-n24 {
    margin: -6rem !important;
  }

  .m-lg-n32 {
    margin: -8rem !important;
  }

  .m-lg-n40 {
    margin: -10rem !important;
  }

  .m-lg-n48 {
    margin: -12rem !important;
  }

  .m-lg-n56 {
    margin: -14rem !important;
  }

  .m-lg-n64 {
    margin: -16rem !important;
  }

  .m-lg-n72 {
    margin: -18rem !important;
  }

  .m-lg-n80 {
    margin: -20rem !important;
  }

  .m-lg-npx {
    margin: -1px !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-lg-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-lg-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-lg-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-lg-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-lg-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-lg-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-lg-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-lg-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-lg-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-lg-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-lg-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-lg-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-lg-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-lg-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-lg-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-lg-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-lg-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-lg-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-lg-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-lg-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-lg-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3rem !important;
  }

  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n16 {
    margin-top: -4rem !important;
  }

  .mt-lg-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n20 {
    margin-top: -5rem !important;
  }

  .mt-lg-n24 {
    margin-top: -6rem !important;
  }

  .mt-lg-n32 {
    margin-top: -8rem !important;
  }

  .mt-lg-n40 {
    margin-top: -10rem !important;
  }

  .mt-lg-n48 {
    margin-top: -12rem !important;
  }

  .mt-lg-n56 {
    margin-top: -14rem !important;
  }

  .mt-lg-n64 {
    margin-top: -16rem !important;
  }

  .mt-lg-n72 {
    margin-top: -18rem !important;
  }

  .mt-lg-n80 {
    margin-top: -20rem !important;
  }

  .mt-lg-npx {
    margin-top: -1px !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n12 {
    margin-right: -3rem !important;
  }

  .me-lg-n14 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n16 {
    margin-right: -4rem !important;
  }

  .me-lg-n18 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n20 {
    margin-right: -5rem !important;
  }

  .me-lg-n24 {
    margin-right: -6rem !important;
  }

  .me-lg-n32 {
    margin-right: -8rem !important;
  }

  .me-lg-n40 {
    margin-right: -10rem !important;
  }

  .me-lg-n48 {
    margin-right: -12rem !important;
  }

  .me-lg-n56 {
    margin-right: -14rem !important;
  }

  .me-lg-n64 {
    margin-right: -16rem !important;
  }

  .me-lg-n72 {
    margin-right: -18rem !important;
  }

  .me-lg-n80 {
    margin-right: -20rem !important;
  }

  .me-lg-npx {
    margin-right: -1px !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-lg-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-lg-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-lg-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-lg-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-lg-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-lg-npx {
    margin-bottom: -1px !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n12 {
    margin-left: -3rem !important;
  }

  .ms-lg-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n16 {
    margin-left: -4rem !important;
  }

  .ms-lg-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n20 {
    margin-left: -5rem !important;
  }

  .ms-lg-n24 {
    margin-left: -6rem !important;
  }

  .ms-lg-n32 {
    margin-left: -8rem !important;
  }

  .ms-lg-n40 {
    margin-left: -10rem !important;
  }

  .ms-lg-n48 {
    margin-left: -12rem !important;
  }

  .ms-lg-n56 {
    margin-left: -14rem !important;
  }

  .ms-lg-n64 {
    margin-left: -16rem !important;
  }

  .ms-lg-n72 {
    margin-left: -18rem !important;
  }

  .ms-lg-n80 {
    margin-left: -20rem !important;
  }

  .ms-lg-npx {
    margin-left: -1px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: .75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .p-lg-18 {
    padding: 4.5rem !important;
  }

  .p-lg-20 {
    padding: 5rem !important;
  }

  .p-lg-24 {
    padding: 6rem !important;
  }

  .p-lg-32 {
    padding: 8rem !important;
  }

  .p-lg-40 {
    padding: 10rem !important;
  }

  .p-lg-48 {
    padding: 12rem !important;
  }

  .p-lg-56 {
    padding: 14rem !important;
  }

  .p-lg-64 {
    padding: 16rem !important;
  }

  .p-lg-72 {
    padding: 18rem !important;
  }

  .p-lg-80 {
    padding: 20rem !important;
  }

  .p-lg-px {
    padding: 1px !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-lg-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-lg-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-lg-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-lg-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-lg-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-lg-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-lg-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-lg-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-lg-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-lg-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-lg-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-lg-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-lg-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-lg-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-lg-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-lg-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-lg-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-lg-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-lg-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-lg-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-lg-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-lg-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-lg-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: .75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-20 {
    padding-top: 5rem !important;
  }

  .pt-lg-24 {
    padding-top: 6rem !important;
  }

  .pt-lg-32 {
    padding-top: 8rem !important;
  }

  .pt-lg-40 {
    padding-top: 10rem !important;
  }

  .pt-lg-48 {
    padding-top: 12rem !important;
  }

  .pt-lg-56 {
    padding-top: 14rem !important;
  }

  .pt-lg-64 {
    padding-top: 16rem !important;
  }

  .pt-lg-72 {
    padding-top: 18rem !important;
  }

  .pt-lg-80 {
    padding-top: 20rem !important;
  }

  .pt-lg-px {
    padding-top: 1px !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: .75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-12 {
    padding-right: 3rem !important;
  }

  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-16 {
    padding-right: 4rem !important;
  }

  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-20 {
    padding-right: 5rem !important;
  }

  .pe-lg-24 {
    padding-right: 6rem !important;
  }

  .pe-lg-32 {
    padding-right: 8rem !important;
  }

  .pe-lg-40 {
    padding-right: 10rem !important;
  }

  .pe-lg-48 {
    padding-right: 12rem !important;
  }

  .pe-lg-56 {
    padding-right: 14rem !important;
  }

  .pe-lg-64 {
    padding-right: 16rem !important;
  }

  .pe-lg-72 {
    padding-right: 18rem !important;
  }

  .pe-lg-80 {
    padding-right: 20rem !important;
  }

  .pe-lg-px {
    padding-right: 1px !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: .75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-24 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-32 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-40 {
    padding-bottom: 10rem !important;
  }

  .pb-lg-48 {
    padding-bottom: 12rem !important;
  }

  .pb-lg-56 {
    padding-bottom: 14rem !important;
  }

  .pb-lg-64 {
    padding-bottom: 16rem !important;
  }

  .pb-lg-72 {
    padding-bottom: 18rem !important;
  }

  .pb-lg-80 {
    padding-bottom: 20rem !important;
  }

  .pb-lg-px {
    padding-bottom: 1px !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: .75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-12 {
    padding-left: 3rem !important;
  }

  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-16 {
    padding-left: 4rem !important;
  }

  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-20 {
    padding-left: 5rem !important;
  }

  .ps-lg-24 {
    padding-left: 6rem !important;
  }

  .ps-lg-32 {
    padding-left: 8rem !important;
  }

  .ps-lg-40 {
    padding-left: 10rem !important;
  }

  .ps-lg-48 {
    padding-left: 12rem !important;
  }

  .ps-lg-56 {
    padding-left: 14rem !important;
  }

  .ps-lg-64 {
    padding-left: 16rem !important;
  }

  .ps-lg-72 {
    padding-left: 18rem !important;
  }

  .ps-lg-80 {
    padding-left: 20rem !important;
  }

  .ps-lg-px {
    padding-left: 1px !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: .75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .gap-lg-18 {
    gap: 4.5rem !important;
  }

  .gap-lg-20 {
    gap: 5rem !important;
  }

  .gap-lg-24 {
    gap: 6rem !important;
  }

  .gap-lg-32 {
    gap: 8rem !important;
  }

  .gap-lg-40 {
    gap: 10rem !important;
  }

  .gap-lg-48 {
    gap: 12rem !important;
  }

  .gap-lg-56 {
    gap: 14rem !important;
  }

  .gap-lg-64 {
    gap: 16rem !important;
  }

  .gap-lg-72 {
    gap: 18rem !important;
  }

  .gap-lg-80 {
    gap: 20rem !important;
  }

  .gap-lg-px {
    gap: 1px !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: .75rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-lg-12 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-lg-16 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-lg-20 {
    row-gap: 5rem !important;
  }

  .row-gap-lg-24 {
    row-gap: 6rem !important;
  }

  .row-gap-lg-32 {
    row-gap: 8rem !important;
  }

  .row-gap-lg-40 {
    row-gap: 10rem !important;
  }

  .row-gap-lg-48 {
    row-gap: 12rem !important;
  }

  .row-gap-lg-56 {
    row-gap: 14rem !important;
  }

  .row-gap-lg-64 {
    row-gap: 16rem !important;
  }

  .row-gap-lg-72 {
    row-gap: 18rem !important;
  }

  .row-gap-lg-80 {
    row-gap: 20rem !important;
  }

  .row-gap-lg-px {
    row-gap: 1px !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: .75rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-lg-8 {
    column-gap: 2rem !important;
  }

  .column-gap-lg-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-lg-12 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-lg-16 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-lg-20 {
    column-gap: 5rem !important;
  }

  .column-gap-lg-24 {
    column-gap: 6rem !important;
  }

  .column-gap-lg-32 {
    column-gap: 8rem !important;
  }

  .column-gap-lg-40 {
    column-gap: 10rem !important;
  }

  .column-gap-lg-48 {
    column-gap: 12rem !important;
  }

  .column-gap-lg-56 {
    column-gap: 14rem !important;
  }

  .column-gap-lg-64 {
    column-gap: 16rem !important;
  }

  .column-gap-lg-72 {
    column-gap: 18rem !important;
  }

  .column-gap-lg-80 {
    column-gap: 20rem !important;
  }

  .column-gap-lg-px {
    column-gap: 1px !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .rounded-lg {
    border-radius: .375rem !important;
  }

  .rounded-lg-0 {
    border-radius: 0 !important;
  }

  .rounded-lg-1 {
    border-radius: .25rem !important;
  }

  .rounded-lg-2 {
    border-radius: .5rem !important;
  }

  .rounded-lg-3 {
    border-radius: .75rem !important;
  }

  .rounded-lg-4 {
    border-radius: 1rem !important;
  }

  .rounded-lg-5 {
    border-radius: 1.5rem !important;
  }

  .rounded-lg-6 {
    border-radius: 3rem !important;
  }

  .rounded-lg-pill {
    border-radius: 50rem !important;
  }

  .rounded-lg-circle {
    border-radius: 50% !important;
  }

  .rounded-lg-card {
    border-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-lg {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-lg-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-lg-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-lg-2 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-lg-3 {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-lg-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-lg-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-lg-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-lg-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-lg-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-lg-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-end-lg {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-lg-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-lg-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-lg-2 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-lg-3 {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-end-lg-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-lg-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-end-lg-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-lg-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-lg-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-lg-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-lg {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-lg-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-lg-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-lg-2 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-lg-3 {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-lg-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-lg-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-lg-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-lg-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-lg-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-lg-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-start-lg {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-lg-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-lg-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-lg-2 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-lg-3 {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-start-lg-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-lg-5 {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-start-lg-6 {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-lg-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-lg-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-lg-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-start-lg {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-lg-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-lg-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-lg-2 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-lg-3 {
    border-top-left-radius: .75rem !important;
  }

  .rounded-top-start-lg-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-lg-5 {
    border-top-left-radius: 1.5rem !important;
  }

  .rounded-top-start-lg-6 {
    border-top-left-radius: 3rem !important;
  }

  .rounded-top-start-lg-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-lg-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-lg-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-end-lg {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-lg-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-lg-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-lg-2 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-lg-3 {
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-end-lg-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-lg-5 {
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-end-lg-6 {
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-end-lg-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-lg-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-lg-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-end-lg {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-lg-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-lg-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-lg-2 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-lg-3 {
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-bottom-end-lg-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-lg-5 {
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-bottom-end-lg-6 {
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-end-lg-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-lg-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-lg-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-start-lg {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-lg-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-lg-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-lg-2 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-lg-3 {
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-start-lg-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-lg-5 {
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-start-lg-6 {
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-start-lg-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-lg-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-lg-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .shadow-soft-lg-1, .shadow-soft-lg-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-2, .shadow-soft-lg-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-3, .shadow-soft-lg-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-4, .shadow-soft-lg-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-5, .shadow-soft-lg-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-6, .shadow-soft-lg-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-none, .shadow-soft-lg-none-hover:hover {
    box-shadow: none !important;
  }

  .w-rem-lg-0 {
    width: 0 !important;
  }

  .w-rem-lg-1 {
    width: .25rem !important;
  }

  .w-rem-lg-2 {
    width: .5rem !important;
  }

  .w-rem-lg-3 {
    width: .75rem !important;
  }

  .w-rem-lg-4 {
    width: 1rem !important;
  }

  .w-rem-lg-5 {
    width: 1.25rem !important;
  }

  .w-rem-lg-6 {
    width: 1.5rem !important;
  }

  .w-rem-lg-7 {
    width: 1.75rem !important;
  }

  .w-rem-lg-8 {
    width: 2rem !important;
  }

  .w-rem-lg-10 {
    width: 2.5rem !important;
  }

  .w-rem-lg-12 {
    width: 3rem !important;
  }

  .w-rem-lg-14 {
    width: 3.5rem !important;
  }

  .w-rem-lg-16 {
    width: 4rem !important;
  }

  .w-rem-lg-18 {
    width: 4.5rem !important;
  }

  .w-rem-lg-20 {
    width: 5rem !important;
  }

  .w-rem-lg-24 {
    width: 6rem !important;
  }

  .w-rem-lg-32 {
    width: 8rem !important;
  }

  .w-rem-lg-40 {
    width: 10rem !important;
  }

  .w-rem-lg-48 {
    width: 12rem !important;
  }

  .w-rem-lg-56 {
    width: 14rem !important;
  }

  .w-rem-lg-64 {
    width: 16rem !important;
  }

  .w-rem-lg-72 {
    width: 18rem !important;
  }

  .w-rem-lg-80 {
    width: 20rem !important;
  }

  .w-rem-lg-px {
    width: 1px !important;
  }

  .w-rem-lg-25 {
    width: 25% !important;
  }

  .w-rem-lg-50 {
    width: 50% !important;
  }

  .w-rem-lg-75 {
    width: 75% !important;
  }

  .w-rem-lg-100 {
    width: 100% !important;
  }

  .w-rem-lg-screen-sm {
    width: 640px !important;
  }

  .w-rem-lg-screen-md {
    width: 768px !important;
  }

  .w-rem-lg-screen-lg {
    width: 1024px !important;
  }

  .w-rem-lg-screen-xl {
    width: 1280px !important;
  }

  .w-rem-lg-screen-xxl {
    width: 1536px !important;
  }

  .w-rem-lg-auto {
    width: auto !important;
  }

  .w-rem-lg-full {
    width: 100% !important;
  }

  .w-rem-lg-screen {
    width: 100vw !important;
  }

  .w-rem-lg-min {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .w-rem-lg-max {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .h-rem-lg-0 {
    height: 0 !important;
  }

  .h-rem-lg-1 {
    height: .25rem !important;
  }

  .h-rem-lg-2 {
    height: .5rem !important;
  }

  .h-rem-lg-3 {
    height: .75rem !important;
  }

  .h-rem-lg-4 {
    height: 1rem !important;
  }

  .h-rem-lg-5 {
    height: 1.25rem !important;
  }

  .h-rem-lg-6 {
    height: 1.5rem !important;
  }

  .h-rem-lg-7 {
    height: 1.75rem !important;
  }

  .h-rem-lg-8 {
    height: 2rem !important;
  }

  .h-rem-lg-10 {
    height: 2.5rem !important;
  }

  .h-rem-lg-12 {
    height: 3rem !important;
  }

  .h-rem-lg-14 {
    height: 3.5rem !important;
  }

  .h-rem-lg-16 {
    height: 4rem !important;
  }

  .h-rem-lg-18 {
    height: 4.5rem !important;
  }

  .h-rem-lg-20 {
    height: 5rem !important;
  }

  .h-rem-lg-24 {
    height: 6rem !important;
  }

  .h-rem-lg-32 {
    height: 8rem !important;
  }

  .h-rem-lg-40 {
    height: 10rem !important;
  }

  .h-rem-lg-48 {
    height: 12rem !important;
  }

  .h-rem-lg-56 {
    height: 14rem !important;
  }

  .h-rem-lg-64 {
    height: 16rem !important;
  }

  .h-rem-lg-72 {
    height: 18rem !important;
  }

  .h-rem-lg-80 {
    height: 20rem !important;
  }

  .h-rem-lg-px {
    height: 1px !important;
  }

  .transition-lg {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-lg-none {
    transition-property: none !important;
  }

  .transition-lg-all {
    transition-property: all !important;
  }

  .transition-lg-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-lg-opacity {
    transition-property: opacity !important;
  }

  .transition-lg-shadow {
    transition-property: box-shadow !important;
  }

  .transition-lg-transform {
    transition-property: transform !important;
  }

  .ease-lg-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-lg-linear {
    transition-timing-function: linear !important;
  }

  .ease-lg-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-lg-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-lg-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-lg-none {
    transform: none !important;
  }

  .scale-lg-0, .scale-lg-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }

  .scale-lg-25, .scale-lg-25-hover:hover {
    --bs-scale-x: .25 !important;
    --bs-scale-y: .25 !important;
  }

  .scale-lg-50, .scale-lg-50-hover:hover {
    --bs-scale-x: .5 !important;
    --bs-scale-y: .5 !important;
  }

  .scale-lg-75, .scale-lg-75-hover:hover {
    --bs-scale-x: .75 !important;
    --bs-scale-y: .75 !important;
  }

  .scale-lg-100, .scale-lg-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }

  .scale-lg-105, .scale-lg-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }

  .scale-lg-110, .scale-lg-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }

  .rotate-lg-0, .rotate-lg-0-hover:hover {
    --bs-rotate: 0deg !important;
  }

  .rotate-lg-1, .rotate-lg-1-hover:hover {
    --bs-rotate: 1deg !important;
  }

  .rotate-lg-2, .rotate-lg-2-hover:hover {
    --bs-rotate: 2deg !important;
  }

  .rotate-lg-3, .rotate-lg-3-hover:hover {
    --bs-rotate: 3deg !important;
  }

  .rotate-lg-6, .rotate-lg-6-hover:hover {
    --bs-rotate: 6deg !important;
  }

  .rotate-lg-12, .rotate-lg-12-hover:hover {
    --bs-rotate: 12deg !important;
  }

  .rotate-lg-30, .rotate-lg-30-hover:hover {
    --bs-rotate: 30deg !important;
  }

  .rotate-lg-45, .rotate-lg-45-hover:hover {
    --bs-rotate: 45deg !important;
  }

  .rotate-lg-90, .rotate-lg-90-hover:hover {
    --bs-rotate: 90deg !important;
  }

  .rotate-lg-180, .rotate-lg-180-hover:hover {
    --bs-rotate: 180deg !important;
  }

  .rotate-lg-n1, .rotate-lg-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }

  .rotate-lg-n2, .rotate-lg-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }

  .rotate-lg-n3, .rotate-lg-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }

  .rotate-lg-n6, .rotate-lg-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }

  .rotate-lg-n12, .rotate-lg-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }

  .rotate-lg-n30, .rotate-lg-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }

  .rotate-lg-n45, .rotate-lg-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }

  .rotate-lg-n90, .rotate-lg-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }

  .rotate-lg-n180, .rotate-lg-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }

  .rotate-y-lg-n1, .rotate-y-lg-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }

  .rotate-y-lg-n2, .rotate-y-lg-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }

  .rotate-y-lg-n3, .rotate-y-lg-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }

  .rotate-y-lg-n6, .rotate-y-lg-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }

  .rotate-y-lg-n12, .rotate-y-lg-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }

  .rotate-y-lg-n30, .rotate-y-lg-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }

  .rotate-y-lg-n45, .rotate-y-lg-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }

  .rotate-y-lg-n90, .rotate-y-lg-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }

  .rotate-y-lg-n180, .rotate-y-lg-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }

  .rotate-x-lg-n1 {
    --bs-rotate-x: -1deg !important;
  }

  .rotate-x-lg-n2 {
    --bs-rotate-x: -2deg !important;
  }

  .rotate-x-lg-n3 {
    --bs-rotate-x: -3deg !important;
  }

  .rotate-x-lg-n6 {
    --bs-rotate-x: -6deg !important;
  }

  .rotate-x-lg-n12 {
    --bs-rotate-x: -12deg !important;
  }

  .rotate-x-lg-n30 {
    --bs-rotate-x: -30deg !important;
  }

  .rotate-x-lg-n45 {
    --bs-rotate-x: -45deg !important;
  }

  .rotate-x-lg-n90 {
    --bs-rotate-x: -90deg !important;
  }

  .rotate-x-lg-n180 {
    --bs-rotate-x: -180deg !important;
  }

  .translate-y-lg-0, .translate-y-lg-0-hover:hover {
    --bs-translate-y: 0 !important;
  }

  .translate-y-lg-25, .translate-y-lg-25-hover:hover {
    --bs-translate-y: 25% !important;
  }

  .translate-y-lg-50, .translate-y-lg-50-hover:hover {
    --bs-translate-y: 50% !important;
  }

  .translate-y-lg-75, .translate-y-lg-75-hover:hover {
    --bs-translate-y: 75% !important;
  }

  .translate-y-lg-100, .translate-y-lg-100-hover:hover {
    --bs-translate-y: 100% !important;
  }

  .translate-y-lg-n25, .translate-y-lg-n25-hover:hover {
    --bs-translate-y: -25% !important;
  }

  .translate-y-lg-n50, .translate-y-lg-n50-hover:hover {
    --bs-translate-y: -50% !important;
  }

  .translate-y-lg-n75, .translate-y-lg-n75-hover:hover {
    --bs-translate-y: -75% !important;
  }

  .translate-y-lg-n100, .translate-y-lg-n100-hover:hover {
    --bs-translate-y: -100% !important;
  }

  .translate-x-lg-0, .translate-x-lg-0-hover:hover {
    --bs-translate-x: 0 !important;
  }

  .translate-x-lg-25, .translate-x-lg-25-hover:hover {
    --bs-translate-x: 25% !important;
  }

  .translate-x-lg-50, .translate-x-lg-50-hover:hover {
    --bs-translate-x: 50% !important;
  }

  .translate-x-lg-75, .translate-x-lg-75-hover:hover {
    --bs-translate-x: 75% !important;
  }

  .translate-x-lg-100, .translate-x-lg-100-hover:hover {
    --bs-translate-x: 100% !important;
  }

  .translate-x-lg-n25, .translate-x-lg-n25-hover:hover {
    --bs-translate-x: -25% !important;
  }

  .translate-x-lg-n50, .translate-x-lg-n50-hover:hover {
    --bs-translate-x: -50% !important;
  }

  .translate-x-lg-n75, .translate-x-lg-n75-hover:hover {
    --bs-translate-x: -75% !important;
  }

  .translate-x-lg-n100, .translate-x-lg-n100-hover:hover {
    --bs-translate-x: -100% !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .overflow-xl-auto {
    overflow: auto !important;
  }

  .overflow-xl-hidden {
    overflow: hidden !important;
  }

  .overflow-xl-visible {
    overflow: visible !important;
  }

  .overflow-xl-scroll {
    overflow: scroll !important;
  }

  .overflow-x-xl-auto {
    overflow-x: auto !important;
  }

  .overflow-x-xl-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-xl-visible {
    overflow-x: visible !important;
  }

  .overflow-x-xl-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-xl-auto {
    overflow-y: auto !important;
  }

  .overflow-y-xl-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-xl-visible {
    overflow-y: visible !important;
  }

  .overflow-y-xl-scroll {
    overflow-y: scroll !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .shadow-xl, .shadow-xl-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-1, .shadow-xl-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-2, .shadow-xl-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-3, .shadow-xl-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-4, .shadow-xl-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-5, .shadow-xl-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-6, .shadow-xl-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-inset, .shadow-xl-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-xl-outline, .shadow-xl-outline-hover:hover, .shadow-xl-focus, .shadow-xl-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, .5) !important;
  }

  .shadow-xl-none, .shadow-xl-none-hover:hover {
    box-shadow: none !important;
  }

  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-xl-0 {
    top: 0 !important;
  }

  .top-xl-50 {
    top: 50% !important;
  }

  .top-xl-100 {
    top: 100% !important;
  }

  .top-xl-1 {
    top: .25rem !important;
  }

  .top-xl-2 {
    top: .5rem !important;
  }

  .top-xl-3 {
    top: .75rem !important;
  }

  .top-xl-4 {
    top: 1rem !important;
  }

  .top-xl-5 {
    top: 1.25rem !important;
  }

  .top-xl-6 {
    top: 1.5rem !important;
  }

  .top-xl-7 {
    top: 1.75rem !important;
  }

  .top-xl-8 {
    top: 2rem !important;
  }

  .top-xl-10 {
    top: 2.5rem !important;
  }

  .top-xl-12 {
    top: 3rem !important;
  }

  .top-xl-14 {
    top: 3.5rem !important;
  }

  .top-xl-16 {
    top: 4rem !important;
  }

  .top-xl-18 {
    top: 4.5rem !important;
  }

  .top-xl-20 {
    top: 5rem !important;
  }

  .top-xl-24 {
    top: 6rem !important;
  }

  .top-xl-32 {
    top: 8rem !important;
  }

  .top-xl-40 {
    top: 10rem !important;
  }

  .top-xl-48 {
    top: 12rem !important;
  }

  .top-xl-56 {
    top: 14rem !important;
  }

  .top-xl-64 {
    top: 16rem !important;
  }

  .top-xl-72 {
    top: 18rem !important;
  }

  .top-xl-80 {
    top: 20rem !important;
  }

  .top-xl-px {
    top: 1px !important;
  }

  .bottom-xl-0 {
    bottom: 0 !important;
  }

  .bottom-xl-50 {
    bottom: 50% !important;
  }

  .bottom-xl-100 {
    bottom: 100% !important;
  }

  .start-xl-0 {
    left: 0 !important;
  }

  .start-xl-50 {
    left: 50% !important;
  }

  .start-xl-100 {
    left: 100% !important;
  }

  .end-xl-0 {
    right: 0 !important;
  }

  .end-xl-50 {
    right: 50% !important;
  }

  .end-xl-100 {
    right: 100% !important;
  }

  .border-xl {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-xl-0 {
    border: 0 !important;
  }

  .border-top-xl {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-xl-0 {
    border-top: 0 !important;
  }

  .border-end-xl {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-xl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xl {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }

  .border-start-xl {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-xl-0 {
    border-left: 0 !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .mw-xl-screen-sm {
    max-width: 640px !important;
  }

  .mw-xl-screen-md {
    max-width: 768px !important;
  }

  .mw-xl-screen-lg {
    max-width: 1024px !important;
  }

  .mw-xl-screen-xl {
    max-width: 1280px !important;
  }

  .mw-xl-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-xl-0 {
    max-width: 0 !important;
  }

  .mw-xl-full {
    max-width: 100% !important;
  }

  .mw-xl-read {
    max-width: 65ch !important;
  }

  .mw-xl-min {
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .mw-xl-max {
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .vh-xl-100 {
    height: 100vh !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-initial {
    flex: 0 auto !important;
  }

  .flex-xl-none {
    flex: none !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: .75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-18 {
    margin: 4.5rem !important;
  }

  .m-xl-20 {
    margin: 5rem !important;
  }

  .m-xl-24 {
    margin: 6rem !important;
  }

  .m-xl-32 {
    margin: 8rem !important;
  }

  .m-xl-40 {
    margin: 10rem !important;
  }

  .m-xl-48 {
    margin: 12rem !important;
  }

  .m-xl-56 {
    margin: 14rem !important;
  }

  .m-xl-64 {
    margin: 16rem !important;
  }

  .m-xl-72 {
    margin: 18rem !important;
  }

  .m-xl-80 {
    margin: 20rem !important;
  }

  .m-xl-px {
    margin: 1px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xl-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xl-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xl-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xl-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xl-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xl-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xl-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-xl-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-xl-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-xl-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-xl-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-xl-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-xl-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-xl-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xl-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xl-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xl-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-xl-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xl-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: .75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-20 {
    margin-top: 5rem !important;
  }

  .mt-xl-24 {
    margin-top: 6rem !important;
  }

  .mt-xl-32 {
    margin-top: 8rem !important;
  }

  .mt-xl-40 {
    margin-top: 10rem !important;
  }

  .mt-xl-48 {
    margin-top: 12rem !important;
  }

  .mt-xl-56 {
    margin-top: 14rem !important;
  }

  .mt-xl-64 {
    margin-top: 16rem !important;
  }

  .mt-xl-72 {
    margin-top: 18rem !important;
  }

  .mt-xl-80 {
    margin-top: 20rem !important;
  }

  .mt-xl-px {
    margin-top: 1px !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: .75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-12 {
    margin-right: 3rem !important;
  }

  .me-xl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xl-16 {
    margin-right: 4rem !important;
  }

  .me-xl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xl-20 {
    margin-right: 5rem !important;
  }

  .me-xl-24 {
    margin-right: 6rem !important;
  }

  .me-xl-32 {
    margin-right: 8rem !important;
  }

  .me-xl-40 {
    margin-right: 10rem !important;
  }

  .me-xl-48 {
    margin-right: 12rem !important;
  }

  .me-xl-56 {
    margin-right: 14rem !important;
  }

  .me-xl-64 {
    margin-right: 16rem !important;
  }

  .me-xl-72 {
    margin-right: 18rem !important;
  }

  .me-xl-80 {
    margin-right: 20rem !important;
  }

  .me-xl-px {
    margin-right: 1px !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: .75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-24 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-32 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-40 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-48 {
    margin-bottom: 12rem !important;
  }

  .mb-xl-56 {
    margin-bottom: 14rem !important;
  }

  .mb-xl-64 {
    margin-bottom: 16rem !important;
  }

  .mb-xl-72 {
    margin-bottom: 18rem !important;
  }

  .mb-xl-80 {
    margin-bottom: 20rem !important;
  }

  .mb-xl-px {
    margin-bottom: 1px !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: .75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-12 {
    margin-left: 3rem !important;
  }

  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-16 {
    margin-left: 4rem !important;
  }

  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-20 {
    margin-left: 5rem !important;
  }

  .ms-xl-24 {
    margin-left: 6rem !important;
  }

  .ms-xl-32 {
    margin-left: 8rem !important;
  }

  .ms-xl-40 {
    margin-left: 10rem !important;
  }

  .ms-xl-48 {
    margin-left: 12rem !important;
  }

  .ms-xl-56 {
    margin-left: 14rem !important;
  }

  .ms-xl-64 {
    margin-left: 16rem !important;
  }

  .ms-xl-72 {
    margin-left: 18rem !important;
  }

  .ms-xl-80 {
    margin-left: 20rem !important;
  }

  .ms-xl-px {
    margin-left: 1px !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n12 {
    margin: -3rem !important;
  }

  .m-xl-n14 {
    margin: -3.5rem !important;
  }

  .m-xl-n16 {
    margin: -4rem !important;
  }

  .m-xl-n18 {
    margin: -4.5rem !important;
  }

  .m-xl-n20 {
    margin: -5rem !important;
  }

  .m-xl-n24 {
    margin: -6rem !important;
  }

  .m-xl-n32 {
    margin: -8rem !important;
  }

  .m-xl-n40 {
    margin: -10rem !important;
  }

  .m-xl-n48 {
    margin: -12rem !important;
  }

  .m-xl-n56 {
    margin: -14rem !important;
  }

  .m-xl-n64 {
    margin: -16rem !important;
  }

  .m-xl-n72 {
    margin: -18rem !important;
  }

  .m-xl-n80 {
    margin: -20rem !important;
  }

  .m-xl-npx {
    margin: -1px !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xl-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-xl-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xl-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-xl-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-xl-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-xl-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-xl-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-xl-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-xl-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-xl-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-xl-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-xl-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-xl-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-xl-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-xl-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xl-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-xl-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xl-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-xl-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-xl-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-xl-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n20 {
    margin-top: -5rem !important;
  }

  .mt-xl-n24 {
    margin-top: -6rem !important;
  }

  .mt-xl-n32 {
    margin-top: -8rem !important;
  }

  .mt-xl-n40 {
    margin-top: -10rem !important;
  }

  .mt-xl-n48 {
    margin-top: -12rem !important;
  }

  .mt-xl-n56 {
    margin-top: -14rem !important;
  }

  .mt-xl-n64 {
    margin-top: -16rem !important;
  }

  .mt-xl-n72 {
    margin-top: -18rem !important;
  }

  .mt-xl-n80 {
    margin-top: -20rem !important;
  }

  .mt-xl-npx {
    margin-top: -1px !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n12 {
    margin-right: -3rem !important;
  }

  .me-xl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n16 {
    margin-right: -4rem !important;
  }

  .me-xl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n20 {
    margin-right: -5rem !important;
  }

  .me-xl-n24 {
    margin-right: -6rem !important;
  }

  .me-xl-n32 {
    margin-right: -8rem !important;
  }

  .me-xl-n40 {
    margin-right: -10rem !important;
  }

  .me-xl-n48 {
    margin-right: -12rem !important;
  }

  .me-xl-n56 {
    margin-right: -14rem !important;
  }

  .me-xl-n64 {
    margin-right: -16rem !important;
  }

  .me-xl-n72 {
    margin-right: -18rem !important;
  }

  .me-xl-n80 {
    margin-right: -20rem !important;
  }

  .me-xl-npx {
    margin-right: -1px !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-xl-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-xl-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-xl-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-xl-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-xl-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-xl-npx {
    margin-bottom: -1px !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n20 {
    margin-left: -5rem !important;
  }

  .ms-xl-n24 {
    margin-left: -6rem !important;
  }

  .ms-xl-n32 {
    margin-left: -8rem !important;
  }

  .ms-xl-n40 {
    margin-left: -10rem !important;
  }

  .ms-xl-n48 {
    margin-left: -12rem !important;
  }

  .ms-xl-n56 {
    margin-left: -14rem !important;
  }

  .ms-xl-n64 {
    margin-left: -16rem !important;
  }

  .ms-xl-n72 {
    margin-left: -18rem !important;
  }

  .ms-xl-n80 {
    margin-left: -20rem !important;
  }

  .ms-xl-npx {
    margin-left: -1px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: .75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .p-xl-18 {
    padding: 4.5rem !important;
  }

  .p-xl-20 {
    padding: 5rem !important;
  }

  .p-xl-24 {
    padding: 6rem !important;
  }

  .p-xl-32 {
    padding: 8rem !important;
  }

  .p-xl-40 {
    padding: 10rem !important;
  }

  .p-xl-48 {
    padding: 12rem !important;
  }

  .p-xl-56 {
    padding: 14rem !important;
  }

  .p-xl-64 {
    padding: 16rem !important;
  }

  .p-xl-72 {
    padding: 18rem !important;
  }

  .p-xl-80 {
    padding: 20rem !important;
  }

  .p-xl-px {
    padding: 1px !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-xl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-xl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xl-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xl-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xl-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xl-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xl-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-xl-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-xl-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-xl-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-xl-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-xl-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-xl-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-xl-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-xl-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-xl-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xl-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xl-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xl-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-xl-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-xl-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-xl-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: .75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-20 {
    padding-top: 5rem !important;
  }

  .pt-xl-24 {
    padding-top: 6rem !important;
  }

  .pt-xl-32 {
    padding-top: 8rem !important;
  }

  .pt-xl-40 {
    padding-top: 10rem !important;
  }

  .pt-xl-48 {
    padding-top: 12rem !important;
  }

  .pt-xl-56 {
    padding-top: 14rem !important;
  }

  .pt-xl-64 {
    padding-top: 16rem !important;
  }

  .pt-xl-72 {
    padding-top: 18rem !important;
  }

  .pt-xl-80 {
    padding-top: 20rem !important;
  }

  .pt-xl-px {
    padding-top: 1px !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: .75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-12 {
    padding-right: 3rem !important;
  }

  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-16 {
    padding-right: 4rem !important;
  }

  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-20 {
    padding-right: 5rem !important;
  }

  .pe-xl-24 {
    padding-right: 6rem !important;
  }

  .pe-xl-32 {
    padding-right: 8rem !important;
  }

  .pe-xl-40 {
    padding-right: 10rem !important;
  }

  .pe-xl-48 {
    padding-right: 12rem !important;
  }

  .pe-xl-56 {
    padding-right: 14rem !important;
  }

  .pe-xl-64 {
    padding-right: 16rem !important;
  }

  .pe-xl-72 {
    padding-right: 18rem !important;
  }

  .pe-xl-80 {
    padding-right: 20rem !important;
  }

  .pe-xl-px {
    padding-right: 1px !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: .75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-24 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-32 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-40 {
    padding-bottom: 10rem !important;
  }

  .pb-xl-48 {
    padding-bottom: 12rem !important;
  }

  .pb-xl-56 {
    padding-bottom: 14rem !important;
  }

  .pb-xl-64 {
    padding-bottom: 16rem !important;
  }

  .pb-xl-72 {
    padding-bottom: 18rem !important;
  }

  .pb-xl-80 {
    padding-bottom: 20rem !important;
  }

  .pb-xl-px {
    padding-bottom: 1px !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: .75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-12 {
    padding-left: 3rem !important;
  }

  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-16 {
    padding-left: 4rem !important;
  }

  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-20 {
    padding-left: 5rem !important;
  }

  .ps-xl-24 {
    padding-left: 6rem !important;
  }

  .ps-xl-32 {
    padding-left: 8rem !important;
  }

  .ps-xl-40 {
    padding-left: 10rem !important;
  }

  .ps-xl-48 {
    padding-left: 12rem !important;
  }

  .ps-xl-56 {
    padding-left: 14rem !important;
  }

  .ps-xl-64 {
    padding-left: 16rem !important;
  }

  .ps-xl-72 {
    padding-left: 18rem !important;
  }

  .ps-xl-80 {
    padding-left: 20rem !important;
  }

  .ps-xl-px {
    padding-left: 1px !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: .75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .gap-xl-18 {
    gap: 4.5rem !important;
  }

  .gap-xl-20 {
    gap: 5rem !important;
  }

  .gap-xl-24 {
    gap: 6rem !important;
  }

  .gap-xl-32 {
    gap: 8rem !important;
  }

  .gap-xl-40 {
    gap: 10rem !important;
  }

  .gap-xl-48 {
    gap: 12rem !important;
  }

  .gap-xl-56 {
    gap: 14rem !important;
  }

  .gap-xl-64 {
    gap: 16rem !important;
  }

  .gap-xl-72 {
    gap: 18rem !important;
  }

  .gap-xl-80 {
    gap: 20rem !important;
  }

  .gap-xl-px {
    gap: 1px !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: .75rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xl-20 {
    row-gap: 5rem !important;
  }

  .row-gap-xl-24 {
    row-gap: 6rem !important;
  }

  .row-gap-xl-32 {
    row-gap: 8rem !important;
  }

  .row-gap-xl-40 {
    row-gap: 10rem !important;
  }

  .row-gap-xl-48 {
    row-gap: 12rem !important;
  }

  .row-gap-xl-56 {
    row-gap: 14rem !important;
  }

  .row-gap-xl-64 {
    row-gap: 16rem !important;
  }

  .row-gap-xl-72 {
    row-gap: 18rem !important;
  }

  .row-gap-xl-80 {
    row-gap: 20rem !important;
  }

  .row-gap-xl-px {
    row-gap: 1px !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: .75rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-xl-8 {
    column-gap: 2rem !important;
  }

  .column-gap-xl-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xl-12 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xl-16 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xl-20 {
    column-gap: 5rem !important;
  }

  .column-gap-xl-24 {
    column-gap: 6rem !important;
  }

  .column-gap-xl-32 {
    column-gap: 8rem !important;
  }

  .column-gap-xl-40 {
    column-gap: 10rem !important;
  }

  .column-gap-xl-48 {
    column-gap: 12rem !important;
  }

  .column-gap-xl-56 {
    column-gap: 14rem !important;
  }

  .column-gap-xl-64 {
    column-gap: 16rem !important;
  }

  .column-gap-xl-72 {
    column-gap: 18rem !important;
  }

  .column-gap-xl-80 {
    column-gap: 20rem !important;
  }

  .column-gap-xl-px {
    column-gap: 1px !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .rounded-xl {
    border-radius: .375rem !important;
  }

  .rounded-xl-0 {
    border-radius: 0 !important;
  }

  .rounded-xl-1 {
    border-radius: .25rem !important;
  }

  .rounded-xl-2 {
    border-radius: .5rem !important;
  }

  .rounded-xl-3 {
    border-radius: .75rem !important;
  }

  .rounded-xl-4 {
    border-radius: 1rem !important;
  }

  .rounded-xl-5 {
    border-radius: 1.5rem !important;
  }

  .rounded-xl-6 {
    border-radius: 3rem !important;
  }

  .rounded-xl-pill {
    border-radius: 50rem !important;
  }

  .rounded-xl-circle {
    border-radius: 50% !important;
  }

  .rounded-xl-card {
    border-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-xl {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-xl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-xl-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-xl-2 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-xl-3 {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-xl-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-xl-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-xl-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-xl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-xl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-xl-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-end-xl {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-xl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-xl-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-xl-2 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-xl-3 {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-end-xl-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-xl-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-end-xl-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-xl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-xl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-xl-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-xl {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-xl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-xl-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-xl-2 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-xl-3 {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-xl-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-xl-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-xl-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-xl-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-xl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-xl-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-start-xl {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-xl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-xl-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-xl-2 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-xl-3 {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-start-xl-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-xl-5 {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-start-xl-6 {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-xl-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-xl-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-xl-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-start-xl {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-xl-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-xl-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-xl-2 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-xl-3 {
    border-top-left-radius: .75rem !important;
  }

  .rounded-top-start-xl-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-xl-5 {
    border-top-left-radius: 1.5rem !important;
  }

  .rounded-top-start-xl-6 {
    border-top-left-radius: 3rem !important;
  }

  .rounded-top-start-xl-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-xl-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-xl-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-end-xl {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-xl-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-xl-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-xl-2 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-xl-3 {
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-end-xl-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-xl-5 {
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-end-xl-6 {
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-end-xl-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-xl-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-xl-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-end-xl {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-xl-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-xl-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-xl-2 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-xl-3 {
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-bottom-end-xl-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-xl-5 {
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-bottom-end-xl-6 {
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-end-xl-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-xl-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-xl-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-start-xl {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-xl-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-xl-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-xl-2 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-xl-3 {
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-start-xl-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-xl-5 {
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-start-xl-6 {
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-start-xl-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-xl-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-xl-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .shadow-soft-xl-1, .shadow-soft-xl-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-2, .shadow-soft-xl-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-3, .shadow-soft-xl-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-4, .shadow-soft-xl-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-5, .shadow-soft-xl-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-6, .shadow-soft-xl-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-none, .shadow-soft-xl-none-hover:hover {
    box-shadow: none !important;
  }

  .w-rem-xl-0 {
    width: 0 !important;
  }

  .w-rem-xl-1 {
    width: .25rem !important;
  }

  .w-rem-xl-2 {
    width: .5rem !important;
  }

  .w-rem-xl-3 {
    width: .75rem !important;
  }

  .w-rem-xl-4 {
    width: 1rem !important;
  }

  .w-rem-xl-5 {
    width: 1.25rem !important;
  }

  .w-rem-xl-6 {
    width: 1.5rem !important;
  }

  .w-rem-xl-7 {
    width: 1.75rem !important;
  }

  .w-rem-xl-8 {
    width: 2rem !important;
  }

  .w-rem-xl-10 {
    width: 2.5rem !important;
  }

  .w-rem-xl-12 {
    width: 3rem !important;
  }

  .w-rem-xl-14 {
    width: 3.5rem !important;
  }

  .w-rem-xl-16 {
    width: 4rem !important;
  }

  .w-rem-xl-18 {
    width: 4.5rem !important;
  }

  .w-rem-xl-20 {
    width: 5rem !important;
  }

  .w-rem-xl-24 {
    width: 6rem !important;
  }

  .w-rem-xl-32 {
    width: 8rem !important;
  }

  .w-rem-xl-40 {
    width: 10rem !important;
  }

  .w-rem-xl-48 {
    width: 12rem !important;
  }

  .w-rem-xl-56 {
    width: 14rem !important;
  }

  .w-rem-xl-64 {
    width: 16rem !important;
  }

  .w-rem-xl-72 {
    width: 18rem !important;
  }

  .w-rem-xl-80 {
    width: 20rem !important;
  }

  .w-rem-xl-px {
    width: 1px !important;
  }

  .w-rem-xl-25 {
    width: 25% !important;
  }

  .w-rem-xl-50 {
    width: 50% !important;
  }

  .w-rem-xl-75 {
    width: 75% !important;
  }

  .w-rem-xl-100 {
    width: 100% !important;
  }

  .w-rem-xl-screen-sm {
    width: 640px !important;
  }

  .w-rem-xl-screen-md {
    width: 768px !important;
  }

  .w-rem-xl-screen-lg {
    width: 1024px !important;
  }

  .w-rem-xl-screen-xl {
    width: 1280px !important;
  }

  .w-rem-xl-screen-xxl {
    width: 1536px !important;
  }

  .w-rem-xl-auto {
    width: auto !important;
  }

  .w-rem-xl-full {
    width: 100% !important;
  }

  .w-rem-xl-screen {
    width: 100vw !important;
  }

  .w-rem-xl-min {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .w-rem-xl-max {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .h-rem-xl-0 {
    height: 0 !important;
  }

  .h-rem-xl-1 {
    height: .25rem !important;
  }

  .h-rem-xl-2 {
    height: .5rem !important;
  }

  .h-rem-xl-3 {
    height: .75rem !important;
  }

  .h-rem-xl-4 {
    height: 1rem !important;
  }

  .h-rem-xl-5 {
    height: 1.25rem !important;
  }

  .h-rem-xl-6 {
    height: 1.5rem !important;
  }

  .h-rem-xl-7 {
    height: 1.75rem !important;
  }

  .h-rem-xl-8 {
    height: 2rem !important;
  }

  .h-rem-xl-10 {
    height: 2.5rem !important;
  }

  .h-rem-xl-12 {
    height: 3rem !important;
  }

  .h-rem-xl-14 {
    height: 3.5rem !important;
  }

  .h-rem-xl-16 {
    height: 4rem !important;
  }

  .h-rem-xl-18 {
    height: 4.5rem !important;
  }

  .h-rem-xl-20 {
    height: 5rem !important;
  }

  .h-rem-xl-24 {
    height: 6rem !important;
  }

  .h-rem-xl-32 {
    height: 8rem !important;
  }

  .h-rem-xl-40 {
    height: 10rem !important;
  }

  .h-rem-xl-48 {
    height: 12rem !important;
  }

  .h-rem-xl-56 {
    height: 14rem !important;
  }

  .h-rem-xl-64 {
    height: 16rem !important;
  }

  .h-rem-xl-72 {
    height: 18rem !important;
  }

  .h-rem-xl-80 {
    height: 20rem !important;
  }

  .h-rem-xl-px {
    height: 1px !important;
  }

  .transition-xl {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-xl-none {
    transition-property: none !important;
  }

  .transition-xl-all {
    transition-property: all !important;
  }

  .transition-xl-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-xl-opacity {
    transition-property: opacity !important;
  }

  .transition-xl-shadow {
    transition-property: box-shadow !important;
  }

  .transition-xl-transform {
    transition-property: transform !important;
  }

  .ease-xl-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-xl-linear {
    transition-timing-function: linear !important;
  }

  .ease-xl-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-xl-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-xl-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-xl-none {
    transform: none !important;
  }

  .scale-xl-0, .scale-xl-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }

  .scale-xl-25, .scale-xl-25-hover:hover {
    --bs-scale-x: .25 !important;
    --bs-scale-y: .25 !important;
  }

  .scale-xl-50, .scale-xl-50-hover:hover {
    --bs-scale-x: .5 !important;
    --bs-scale-y: .5 !important;
  }

  .scale-xl-75, .scale-xl-75-hover:hover {
    --bs-scale-x: .75 !important;
    --bs-scale-y: .75 !important;
  }

  .scale-xl-100, .scale-xl-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }

  .scale-xl-105, .scale-xl-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }

  .scale-xl-110, .scale-xl-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }

  .rotate-xl-0, .rotate-xl-0-hover:hover {
    --bs-rotate: 0deg !important;
  }

  .rotate-xl-1, .rotate-xl-1-hover:hover {
    --bs-rotate: 1deg !important;
  }

  .rotate-xl-2, .rotate-xl-2-hover:hover {
    --bs-rotate: 2deg !important;
  }

  .rotate-xl-3, .rotate-xl-3-hover:hover {
    --bs-rotate: 3deg !important;
  }

  .rotate-xl-6, .rotate-xl-6-hover:hover {
    --bs-rotate: 6deg !important;
  }

  .rotate-xl-12, .rotate-xl-12-hover:hover {
    --bs-rotate: 12deg !important;
  }

  .rotate-xl-30, .rotate-xl-30-hover:hover {
    --bs-rotate: 30deg !important;
  }

  .rotate-xl-45, .rotate-xl-45-hover:hover {
    --bs-rotate: 45deg !important;
  }

  .rotate-xl-90, .rotate-xl-90-hover:hover {
    --bs-rotate: 90deg !important;
  }

  .rotate-xl-180, .rotate-xl-180-hover:hover {
    --bs-rotate: 180deg !important;
  }

  .rotate-xl-n1, .rotate-xl-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }

  .rotate-xl-n2, .rotate-xl-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }

  .rotate-xl-n3, .rotate-xl-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }

  .rotate-xl-n6, .rotate-xl-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }

  .rotate-xl-n12, .rotate-xl-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }

  .rotate-xl-n30, .rotate-xl-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }

  .rotate-xl-n45, .rotate-xl-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }

  .rotate-xl-n90, .rotate-xl-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }

  .rotate-xl-n180, .rotate-xl-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }

  .rotate-y-xl-n1, .rotate-y-xl-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }

  .rotate-y-xl-n2, .rotate-y-xl-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }

  .rotate-y-xl-n3, .rotate-y-xl-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }

  .rotate-y-xl-n6, .rotate-y-xl-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }

  .rotate-y-xl-n12, .rotate-y-xl-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }

  .rotate-y-xl-n30, .rotate-y-xl-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }

  .rotate-y-xl-n45, .rotate-y-xl-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }

  .rotate-y-xl-n90, .rotate-y-xl-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }

  .rotate-y-xl-n180, .rotate-y-xl-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }

  .rotate-x-xl-n1 {
    --bs-rotate-x: -1deg !important;
  }

  .rotate-x-xl-n2 {
    --bs-rotate-x: -2deg !important;
  }

  .rotate-x-xl-n3 {
    --bs-rotate-x: -3deg !important;
  }

  .rotate-x-xl-n6 {
    --bs-rotate-x: -6deg !important;
  }

  .rotate-x-xl-n12 {
    --bs-rotate-x: -12deg !important;
  }

  .rotate-x-xl-n30 {
    --bs-rotate-x: -30deg !important;
  }

  .rotate-x-xl-n45 {
    --bs-rotate-x: -45deg !important;
  }

  .rotate-x-xl-n90 {
    --bs-rotate-x: -90deg !important;
  }

  .rotate-x-xl-n180 {
    --bs-rotate-x: -180deg !important;
  }

  .translate-y-xl-0, .translate-y-xl-0-hover:hover {
    --bs-translate-y: 0 !important;
  }

  .translate-y-xl-25, .translate-y-xl-25-hover:hover {
    --bs-translate-y: 25% !important;
  }

  .translate-y-xl-50, .translate-y-xl-50-hover:hover {
    --bs-translate-y: 50% !important;
  }

  .translate-y-xl-75, .translate-y-xl-75-hover:hover {
    --bs-translate-y: 75% !important;
  }

  .translate-y-xl-100, .translate-y-xl-100-hover:hover {
    --bs-translate-y: 100% !important;
  }

  .translate-y-xl-n25, .translate-y-xl-n25-hover:hover {
    --bs-translate-y: -25% !important;
  }

  .translate-y-xl-n50, .translate-y-xl-n50-hover:hover {
    --bs-translate-y: -50% !important;
  }

  .translate-y-xl-n75, .translate-y-xl-n75-hover:hover {
    --bs-translate-y: -75% !important;
  }

  .translate-y-xl-n100, .translate-y-xl-n100-hover:hover {
    --bs-translate-y: -100% !important;
  }

  .translate-x-xl-0, .translate-x-xl-0-hover:hover {
    --bs-translate-x: 0 !important;
  }

  .translate-x-xl-25, .translate-x-xl-25-hover:hover {
    --bs-translate-x: 25% !important;
  }

  .translate-x-xl-50, .translate-x-xl-50-hover:hover {
    --bs-translate-x: 50% !important;
  }

  .translate-x-xl-75, .translate-x-xl-75-hover:hover {
    --bs-translate-x: 75% !important;
  }

  .translate-x-xl-100, .translate-x-xl-100-hover:hover {
    --bs-translate-x: 100% !important;
  }

  .translate-x-xl-n25, .translate-x-xl-n25-hover:hover {
    --bs-translate-x: -25% !important;
  }

  .translate-x-xl-n50, .translate-x-xl-n50-hover:hover {
    --bs-translate-x: -50% !important;
  }

  .translate-x-xl-n75, .translate-x-xl-n75-hover:hover {
    --bs-translate-x: -75% !important;
  }

  .translate-x-xl-n100, .translate-x-xl-n100-hover:hover {
    --bs-translate-x: -100% !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .overflow-xxl-auto {
    overflow: auto !important;
  }

  .overflow-xxl-hidden {
    overflow: hidden !important;
  }

  .overflow-xxl-visible {
    overflow: visible !important;
  }

  .overflow-xxl-scroll {
    overflow: scroll !important;
  }

  .overflow-x-xxl-auto {
    overflow-x: auto !important;
  }

  .overflow-x-xxl-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-xxl-visible {
    overflow-x: visible !important;
  }

  .overflow-x-xxl-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-xxl-auto {
    overflow-y: auto !important;
  }

  .overflow-y-xxl-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-xxl-visible {
    overflow-y: visible !important;
  }

  .overflow-y-xxl-scroll {
    overflow-y: scroll !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .shadow-xxl, .shadow-xxl-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-1, .shadow-xxl-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-2, .shadow-xxl-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-3, .shadow-xxl-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-4, .shadow-xxl-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-5, .shadow-xxl-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-6, .shadow-xxl-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-inset, .shadow-xxl-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-xxl-outline, .shadow-xxl-outline-hover:hover, .shadow-xxl-focus, .shadow-xxl-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, .5) !important;
  }

  .shadow-xxl-none, .shadow-xxl-none-hover:hover {
    box-shadow: none !important;
  }

  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-xxl-0 {
    top: 0 !important;
  }

  .top-xxl-50 {
    top: 50% !important;
  }

  .top-xxl-100 {
    top: 100% !important;
  }

  .top-xxl-1 {
    top: .25rem !important;
  }

  .top-xxl-2 {
    top: .5rem !important;
  }

  .top-xxl-3 {
    top: .75rem !important;
  }

  .top-xxl-4 {
    top: 1rem !important;
  }

  .top-xxl-5 {
    top: 1.25rem !important;
  }

  .top-xxl-6 {
    top: 1.5rem !important;
  }

  .top-xxl-7 {
    top: 1.75rem !important;
  }

  .top-xxl-8 {
    top: 2rem !important;
  }

  .top-xxl-10 {
    top: 2.5rem !important;
  }

  .top-xxl-12 {
    top: 3rem !important;
  }

  .top-xxl-14 {
    top: 3.5rem !important;
  }

  .top-xxl-16 {
    top: 4rem !important;
  }

  .top-xxl-18 {
    top: 4.5rem !important;
  }

  .top-xxl-20 {
    top: 5rem !important;
  }

  .top-xxl-24 {
    top: 6rem !important;
  }

  .top-xxl-32 {
    top: 8rem !important;
  }

  .top-xxl-40 {
    top: 10rem !important;
  }

  .top-xxl-48 {
    top: 12rem !important;
  }

  .top-xxl-56 {
    top: 14rem !important;
  }

  .top-xxl-64 {
    top: 16rem !important;
  }

  .top-xxl-72 {
    top: 18rem !important;
  }

  .top-xxl-80 {
    top: 20rem !important;
  }

  .top-xxl-px {
    top: 1px !important;
  }

  .bottom-xxl-0 {
    bottom: 0 !important;
  }

  .bottom-xxl-50 {
    bottom: 50% !important;
  }

  .bottom-xxl-100 {
    bottom: 100% !important;
  }

  .start-xxl-0 {
    left: 0 !important;
  }

  .start-xxl-50 {
    left: 50% !important;
  }

  .start-xxl-100 {
    left: 100% !important;
  }

  .end-xxl-0 {
    right: 0 !important;
  }

  .end-xxl-50 {
    right: 50% !important;
  }

  .end-xxl-100 {
    right: 100% !important;
  }

  .border-xxl {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-xxl-0 {
    border: 0 !important;
  }

  .border-top-xxl {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-xxl-0 {
    border-top: 0 !important;
  }

  .border-end-xxl {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-xxl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xxl {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }

  .border-start-xxl {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-xxl-0 {
    border-left: 0 !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .mw-xxl-screen-sm {
    max-width: 640px !important;
  }

  .mw-xxl-screen-md {
    max-width: 768px !important;
  }

  .mw-xxl-screen-lg {
    max-width: 1024px !important;
  }

  .mw-xxl-screen-xl {
    max-width: 1280px !important;
  }

  .mw-xxl-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-xxl-0 {
    max-width: 0 !important;
  }

  .mw-xxl-full {
    max-width: 100% !important;
  }

  .mw-xxl-read {
    max-width: 65ch !important;
  }

  .mw-xxl-min {
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .mw-xxl-max {
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .vh-xxl-100 {
    height: 100vh !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-initial {
    flex: 0 auto !important;
  }

  .flex-xxl-none {
    flex: none !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: .75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-18 {
    margin: 4.5rem !important;
  }

  .m-xxl-20 {
    margin: 5rem !important;
  }

  .m-xxl-24 {
    margin: 6rem !important;
  }

  .m-xxl-32 {
    margin: 8rem !important;
  }

  .m-xxl-40 {
    margin: 10rem !important;
  }

  .m-xxl-48 {
    margin: 12rem !important;
  }

  .m-xxl-56 {
    margin: 14rem !important;
  }

  .m-xxl-64 {
    margin: 16rem !important;
  }

  .m-xxl-72 {
    margin: 18rem !important;
  }

  .m-xxl-80 {
    margin: 20rem !important;
  }

  .m-xxl-px {
    margin: 1px !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xxl-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xxl-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xxl-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xxl-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xxl-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xxl-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xxl-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-xxl-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-xxl-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-xxl-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-xxl-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-xxl-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-xxl-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-xxl-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xxl-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xxl-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xxl-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-xxl-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xxl-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: .75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-20 {
    margin-top: 5rem !important;
  }

  .mt-xxl-24 {
    margin-top: 6rem !important;
  }

  .mt-xxl-32 {
    margin-top: 8rem !important;
  }

  .mt-xxl-40 {
    margin-top: 10rem !important;
  }

  .mt-xxl-48 {
    margin-top: 12rem !important;
  }

  .mt-xxl-56 {
    margin-top: 14rem !important;
  }

  .mt-xxl-64 {
    margin-top: 16rem !important;
  }

  .mt-xxl-72 {
    margin-top: 18rem !important;
  }

  .mt-xxl-80 {
    margin-top: 20rem !important;
  }

  .mt-xxl-px {
    margin-top: 1px !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: .75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-12 {
    margin-right: 3rem !important;
  }

  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-16 {
    margin-right: 4rem !important;
  }

  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-20 {
    margin-right: 5rem !important;
  }

  .me-xxl-24 {
    margin-right: 6rem !important;
  }

  .me-xxl-32 {
    margin-right: 8rem !important;
  }

  .me-xxl-40 {
    margin-right: 10rem !important;
  }

  .me-xxl-48 {
    margin-right: 12rem !important;
  }

  .me-xxl-56 {
    margin-right: 14rem !important;
  }

  .me-xxl-64 {
    margin-right: 16rem !important;
  }

  .me-xxl-72 {
    margin-right: 18rem !important;
  }

  .me-xxl-80 {
    margin-right: 20rem !important;
  }

  .me-xxl-px {
    margin-right: 1px !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: .75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-24 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-32 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-40 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-48 {
    margin-bottom: 12rem !important;
  }

  .mb-xxl-56 {
    margin-bottom: 14rem !important;
  }

  .mb-xxl-64 {
    margin-bottom: 16rem !important;
  }

  .mb-xxl-72 {
    margin-bottom: 18rem !important;
  }

  .mb-xxl-80 {
    margin-bottom: 20rem !important;
  }

  .mb-xxl-px {
    margin-bottom: 1px !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: .75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3rem !important;
  }

  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-16 {
    margin-left: 4rem !important;
  }

  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-20 {
    margin-left: 5rem !important;
  }

  .ms-xxl-24 {
    margin-left: 6rem !important;
  }

  .ms-xxl-32 {
    margin-left: 8rem !important;
  }

  .ms-xxl-40 {
    margin-left: 10rem !important;
  }

  .ms-xxl-48 {
    margin-left: 12rem !important;
  }

  .ms-xxl-56 {
    margin-left: 14rem !important;
  }

  .ms-xxl-64 {
    margin-left: 16rem !important;
  }

  .ms-xxl-72 {
    margin-left: 18rem !important;
  }

  .ms-xxl-80 {
    margin-left: 20rem !important;
  }

  .ms-xxl-px {
    margin-left: 1px !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n12 {
    margin: -3rem !important;
  }

  .m-xxl-n14 {
    margin: -3.5rem !important;
  }

  .m-xxl-n16 {
    margin: -4rem !important;
  }

  .m-xxl-n18 {
    margin: -4.5rem !important;
  }

  .m-xxl-n20 {
    margin: -5rem !important;
  }

  .m-xxl-n24 {
    margin: -6rem !important;
  }

  .m-xxl-n32 {
    margin: -8rem !important;
  }

  .m-xxl-n40 {
    margin: -10rem !important;
  }

  .m-xxl-n48 {
    margin: -12rem !important;
  }

  .m-xxl-n56 {
    margin: -14rem !important;
  }

  .m-xxl-n64 {
    margin: -16rem !important;
  }

  .m-xxl-n72 {
    margin: -18rem !important;
  }

  .m-xxl-n80 {
    margin: -20rem !important;
  }

  .m-xxl-npx {
    margin: -1px !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xxl-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-xxl-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xxl-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-xxl-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-xxl-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-xxl-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-xxl-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-xxl-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-xxl-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-xxl-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-xxl-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-xxl-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-xxl-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-xxl-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-xxl-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xxl-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xxl-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-xxl-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xxl-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-xxl-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-xxl-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-xxl-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -5rem !important;
  }

  .mt-xxl-n24 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n32 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n40 {
    margin-top: -10rem !important;
  }

  .mt-xxl-n48 {
    margin-top: -12rem !important;
  }

  .mt-xxl-n56 {
    margin-top: -14rem !important;
  }

  .mt-xxl-n64 {
    margin-top: -16rem !important;
  }

  .mt-xxl-n72 {
    margin-top: -18rem !important;
  }

  .mt-xxl-n80 {
    margin-top: -20rem !important;
  }

  .mt-xxl-npx {
    margin-top: -1px !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n12 {
    margin-right: -3rem !important;
  }

  .me-xxl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n16 {
    margin-right: -4rem !important;
  }

  .me-xxl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n20 {
    margin-right: -5rem !important;
  }

  .me-xxl-n24 {
    margin-right: -6rem !important;
  }

  .me-xxl-n32 {
    margin-right: -8rem !important;
  }

  .me-xxl-n40 {
    margin-right: -10rem !important;
  }

  .me-xxl-n48 {
    margin-right: -12rem !important;
  }

  .me-xxl-n56 {
    margin-right: -14rem !important;
  }

  .me-xxl-n64 {
    margin-right: -16rem !important;
  }

  .me-xxl-n72 {
    margin-right: -18rem !important;
  }

  .me-xxl-n80 {
    margin-right: -20rem !important;
  }

  .me-xxl-npx {
    margin-right: -1px !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-xxl-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-xxl-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-xxl-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-xxl-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-xxl-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-xxl-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-xxl-npx {
    margin-bottom: -1px !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -5rem !important;
  }

  .ms-xxl-n24 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n32 {
    margin-left: -8rem !important;
  }

  .ms-xxl-n40 {
    margin-left: -10rem !important;
  }

  .ms-xxl-n48 {
    margin-left: -12rem !important;
  }

  .ms-xxl-n56 {
    margin-left: -14rem !important;
  }

  .ms-xxl-n64 {
    margin-left: -16rem !important;
  }

  .ms-xxl-n72 {
    margin-left: -18rem !important;
  }

  .ms-xxl-n80 {
    margin-left: -20rem !important;
  }

  .ms-xxl-npx {
    margin-left: -1px !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: .75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .p-xxl-18 {
    padding: 4.5rem !important;
  }

  .p-xxl-20 {
    padding: 5rem !important;
  }

  .p-xxl-24 {
    padding: 6rem !important;
  }

  .p-xxl-32 {
    padding: 8rem !important;
  }

  .p-xxl-40 {
    padding: 10rem !important;
  }

  .p-xxl-48 {
    padding: 12rem !important;
  }

  .p-xxl-56 {
    padding: 14rem !important;
  }

  .p-xxl-64 {
    padding: 16rem !important;
  }

  .p-xxl-72 {
    padding: 18rem !important;
  }

  .p-xxl-80 {
    padding: 20rem !important;
  }

  .p-xxl-px {
    padding: 1px !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xxl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xxl-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xxl-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xxl-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xxl-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xxl-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xxl-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-xxl-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-xxl-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-xxl-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-xxl-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-xxl-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-xxl-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-xxl-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-xxl-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-xxl-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xxl-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xxl-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xxl-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-xxl-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-xxl-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-xxl-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: .75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-20 {
    padding-top: 5rem !important;
  }

  .pt-xxl-24 {
    padding-top: 6rem !important;
  }

  .pt-xxl-32 {
    padding-top: 8rem !important;
  }

  .pt-xxl-40 {
    padding-top: 10rem !important;
  }

  .pt-xxl-48 {
    padding-top: 12rem !important;
  }

  .pt-xxl-56 {
    padding-top: 14rem !important;
  }

  .pt-xxl-64 {
    padding-top: 16rem !important;
  }

  .pt-xxl-72 {
    padding-top: 18rem !important;
  }

  .pt-xxl-80 {
    padding-top: 20rem !important;
  }

  .pt-xxl-px {
    padding-top: 1px !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: .75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3rem !important;
  }

  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-16 {
    padding-right: 4rem !important;
  }

  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-20 {
    padding-right: 5rem !important;
  }

  .pe-xxl-24 {
    padding-right: 6rem !important;
  }

  .pe-xxl-32 {
    padding-right: 8rem !important;
  }

  .pe-xxl-40 {
    padding-right: 10rem !important;
  }

  .pe-xxl-48 {
    padding-right: 12rem !important;
  }

  .pe-xxl-56 {
    padding-right: 14rem !important;
  }

  .pe-xxl-64 {
    padding-right: 16rem !important;
  }

  .pe-xxl-72 {
    padding-right: 18rem !important;
  }

  .pe-xxl-80 {
    padding-right: 20rem !important;
  }

  .pe-xxl-px {
    padding-right: 1px !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: .75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-24 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-32 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-40 {
    padding-bottom: 10rem !important;
  }

  .pb-xxl-48 {
    padding-bottom: 12rem !important;
  }

  .pb-xxl-56 {
    padding-bottom: 14rem !important;
  }

  .pb-xxl-64 {
    padding-bottom: 16rem !important;
  }

  .pb-xxl-72 {
    padding-bottom: 18rem !important;
  }

  .pb-xxl-80 {
    padding-bottom: 20rem !important;
  }

  .pb-xxl-px {
    padding-bottom: 1px !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: .75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3rem !important;
  }

  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-16 {
    padding-left: 4rem !important;
  }

  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-20 {
    padding-left: 5rem !important;
  }

  .ps-xxl-24 {
    padding-left: 6rem !important;
  }

  .ps-xxl-32 {
    padding-left: 8rem !important;
  }

  .ps-xxl-40 {
    padding-left: 10rem !important;
  }

  .ps-xxl-48 {
    padding-left: 12rem !important;
  }

  .ps-xxl-56 {
    padding-left: 14rem !important;
  }

  .ps-xxl-64 {
    padding-left: 16rem !important;
  }

  .ps-xxl-72 {
    padding-left: 18rem !important;
  }

  .ps-xxl-80 {
    padding-left: 20rem !important;
  }

  .ps-xxl-px {
    padding-left: 1px !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: .75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .gap-xxl-18 {
    gap: 4.5rem !important;
  }

  .gap-xxl-20 {
    gap: 5rem !important;
  }

  .gap-xxl-24 {
    gap: 6rem !important;
  }

  .gap-xxl-32 {
    gap: 8rem !important;
  }

  .gap-xxl-40 {
    gap: 10rem !important;
  }

  .gap-xxl-48 {
    gap: 12rem !important;
  }

  .gap-xxl-56 {
    gap: 14rem !important;
  }

  .gap-xxl-64 {
    gap: 16rem !important;
  }

  .gap-xxl-72 {
    gap: 18rem !important;
  }

  .gap-xxl-80 {
    gap: 20rem !important;
  }

  .gap-xxl-px {
    gap: 1px !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: .75rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xxl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xxl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xxl-20 {
    row-gap: 5rem !important;
  }

  .row-gap-xxl-24 {
    row-gap: 6rem !important;
  }

  .row-gap-xxl-32 {
    row-gap: 8rem !important;
  }

  .row-gap-xxl-40 {
    row-gap: 10rem !important;
  }

  .row-gap-xxl-48 {
    row-gap: 12rem !important;
  }

  .row-gap-xxl-56 {
    row-gap: 14rem !important;
  }

  .row-gap-xxl-64 {
    row-gap: 16rem !important;
  }

  .row-gap-xxl-72 {
    row-gap: 18rem !important;
  }

  .row-gap-xxl-80 {
    row-gap: 20rem !important;
  }

  .row-gap-xxl-px {
    row-gap: 1px !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: .75rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-xxl-8 {
    column-gap: 2rem !important;
  }

  .column-gap-xxl-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xxl-12 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xxl-16 {
    column-gap: 4rem !important;
  }

  .column-gap-xxl-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xxl-20 {
    column-gap: 5rem !important;
  }

  .column-gap-xxl-24 {
    column-gap: 6rem !important;
  }

  .column-gap-xxl-32 {
    column-gap: 8rem !important;
  }

  .column-gap-xxl-40 {
    column-gap: 10rem !important;
  }

  .column-gap-xxl-48 {
    column-gap: 12rem !important;
  }

  .column-gap-xxl-56 {
    column-gap: 14rem !important;
  }

  .column-gap-xxl-64 {
    column-gap: 16rem !important;
  }

  .column-gap-xxl-72 {
    column-gap: 18rem !important;
  }

  .column-gap-xxl-80 {
    column-gap: 20rem !important;
  }

  .column-gap-xxl-px {
    column-gap: 1px !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .rounded-xxl {
    border-radius: .375rem !important;
  }

  .rounded-xxl-0 {
    border-radius: 0 !important;
  }

  .rounded-xxl-1 {
    border-radius: .25rem !important;
  }

  .rounded-xxl-2 {
    border-radius: .5rem !important;
  }

  .rounded-xxl-3 {
    border-radius: .75rem !important;
  }

  .rounded-xxl-4 {
    border-radius: 1rem !important;
  }

  .rounded-xxl-5 {
    border-radius: 1.5rem !important;
  }

  .rounded-xxl-6 {
    border-radius: 3rem !important;
  }

  .rounded-xxl-pill {
    border-radius: 50rem !important;
  }

  .rounded-xxl-circle {
    border-radius: 50% !important;
  }

  .rounded-xxl-card {
    border-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-xxl {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-xxl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-xxl-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-xxl-2 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-xxl-3 {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-xxl-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-xxl-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-xxl-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-xxl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-xxl-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-end-xxl {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-xxl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-xxl-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-xxl-2 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-xxl-3 {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-end-xxl-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-xxl-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-end-xxl-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-xxl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-xxl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-xxl-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-xxl {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-xxl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-xxl-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-xxl-2 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-xxl-3 {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-xxl-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-xxl-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-xxl-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-xxl-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-xxl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-xxl-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-start-xxl {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-xxl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-xxl-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-xxl-2 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-xxl-3 {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-start-xxl-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-xxl-5 {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-start-xxl-6 {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-xxl-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-xxl-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-start-xxl {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-xxl-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-xxl-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-xxl-2 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-xxl-3 {
    border-top-left-radius: .75rem !important;
  }

  .rounded-top-start-xxl-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-xxl-5 {
    border-top-left-radius: 1.5rem !important;
  }

  .rounded-top-start-xxl-6 {
    border-top-left-radius: 3rem !important;
  }

  .rounded-top-start-xxl-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-xxl-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-xxl-card {
    border-top-left-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-top-end-xxl {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-xxl-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-xxl-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-xxl-2 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-xxl-3 {
    border-top-right-radius: .75rem !important;
  }

  .rounded-top-end-xxl-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-xxl-5 {
    border-top-right-radius: 1.5rem !important;
  }

  .rounded-top-end-xxl-6 {
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-end-xxl-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-xxl-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-xxl-card {
    border-top-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-end-xxl {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-xxl-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-xxl-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-xxl-2 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-xxl-3 {
    border-bottom-right-radius: .75rem !important;
  }

  .rounded-bottom-end-xxl-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-xxl-5 {
    border-bottom-right-radius: 1.5rem !important;
  }

  .rounded-bottom-end-xxl-6 {
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-end-xxl-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-xxl-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-xxl-card {
    border-bottom-right-radius: var(--x-border-radius-xl) !important;
  }

  .rounded-bottom-start-xxl {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-xxl-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-xxl-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-xxl-2 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-xxl-3 {
    border-bottom-left-radius: .75rem !important;
  }

  .rounded-bottom-start-xxl-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-xxl-5 {
    border-bottom-left-radius: 1.5rem !important;
  }

  .rounded-bottom-start-xxl-6 {
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-bottom-start-xxl-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-xxl-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-xxl-card {
    border-bottom-left-radius: var(--x-border-radius-xl) !important;
  }

  .shadow-soft-xxl-1, .shadow-soft-xxl-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-2, .shadow-soft-xxl-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-3, .shadow-soft-xxl-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-4, .shadow-soft-xxl-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-5, .shadow-soft-xxl-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-6, .shadow-soft-xxl-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-none, .shadow-soft-xxl-none-hover:hover {
    box-shadow: none !important;
  }

  .w-rem-xxl-0 {
    width: 0 !important;
  }

  .w-rem-xxl-1 {
    width: .25rem !important;
  }

  .w-rem-xxl-2 {
    width: .5rem !important;
  }

  .w-rem-xxl-3 {
    width: .75rem !important;
  }

  .w-rem-xxl-4 {
    width: 1rem !important;
  }

  .w-rem-xxl-5 {
    width: 1.25rem !important;
  }

  .w-rem-xxl-6 {
    width: 1.5rem !important;
  }

  .w-rem-xxl-7 {
    width: 1.75rem !important;
  }

  .w-rem-xxl-8 {
    width: 2rem !important;
  }

  .w-rem-xxl-10 {
    width: 2.5rem !important;
  }

  .w-rem-xxl-12 {
    width: 3rem !important;
  }

  .w-rem-xxl-14 {
    width: 3.5rem !important;
  }

  .w-rem-xxl-16 {
    width: 4rem !important;
  }

  .w-rem-xxl-18 {
    width: 4.5rem !important;
  }

  .w-rem-xxl-20 {
    width: 5rem !important;
  }

  .w-rem-xxl-24 {
    width: 6rem !important;
  }

  .w-rem-xxl-32 {
    width: 8rem !important;
  }

  .w-rem-xxl-40 {
    width: 10rem !important;
  }

  .w-rem-xxl-48 {
    width: 12rem !important;
  }

  .w-rem-xxl-56 {
    width: 14rem !important;
  }

  .w-rem-xxl-64 {
    width: 16rem !important;
  }

  .w-rem-xxl-72 {
    width: 18rem !important;
  }

  .w-rem-xxl-80 {
    width: 20rem !important;
  }

  .w-rem-xxl-px {
    width: 1px !important;
  }

  .w-rem-xxl-25 {
    width: 25% !important;
  }

  .w-rem-xxl-50 {
    width: 50% !important;
  }

  .w-rem-xxl-75 {
    width: 75% !important;
  }

  .w-rem-xxl-100 {
    width: 100% !important;
  }

  .w-rem-xxl-screen-sm {
    width: 640px !important;
  }

  .w-rem-xxl-screen-md {
    width: 768px !important;
  }

  .w-rem-xxl-screen-lg {
    width: 1024px !important;
  }

  .w-rem-xxl-screen-xl {
    width: 1280px !important;
  }

  .w-rem-xxl-screen-xxl {
    width: 1536px !important;
  }

  .w-rem-xxl-auto {
    width: auto !important;
  }

  .w-rem-xxl-full {
    width: 100% !important;
  }

  .w-rem-xxl-screen {
    width: 100vw !important;
  }

  .w-rem-xxl-min {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .w-rem-xxl-max {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .h-rem-xxl-0 {
    height: 0 !important;
  }

  .h-rem-xxl-1 {
    height: .25rem !important;
  }

  .h-rem-xxl-2 {
    height: .5rem !important;
  }

  .h-rem-xxl-3 {
    height: .75rem !important;
  }

  .h-rem-xxl-4 {
    height: 1rem !important;
  }

  .h-rem-xxl-5 {
    height: 1.25rem !important;
  }

  .h-rem-xxl-6 {
    height: 1.5rem !important;
  }

  .h-rem-xxl-7 {
    height: 1.75rem !important;
  }

  .h-rem-xxl-8 {
    height: 2rem !important;
  }

  .h-rem-xxl-10 {
    height: 2.5rem !important;
  }

  .h-rem-xxl-12 {
    height: 3rem !important;
  }

  .h-rem-xxl-14 {
    height: 3.5rem !important;
  }

  .h-rem-xxl-16 {
    height: 4rem !important;
  }

  .h-rem-xxl-18 {
    height: 4.5rem !important;
  }

  .h-rem-xxl-20 {
    height: 5rem !important;
  }

  .h-rem-xxl-24 {
    height: 6rem !important;
  }

  .h-rem-xxl-32 {
    height: 8rem !important;
  }

  .h-rem-xxl-40 {
    height: 10rem !important;
  }

  .h-rem-xxl-48 {
    height: 12rem !important;
  }

  .h-rem-xxl-56 {
    height: 14rem !important;
  }

  .h-rem-xxl-64 {
    height: 16rem !important;
  }

  .h-rem-xxl-72 {
    height: 18rem !important;
  }

  .h-rem-xxl-80 {
    height: 20rem !important;
  }

  .h-rem-xxl-px {
    height: 1px !important;
  }

  .transition-xxl {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-xxl-none {
    transition-property: none !important;
  }

  .transition-xxl-all {
    transition-property: all !important;
  }

  .transition-xxl-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-xxl-opacity {
    transition-property: opacity !important;
  }

  .transition-xxl-shadow {
    transition-property: box-shadow !important;
  }

  .transition-xxl-transform {
    transition-property: transform !important;
  }

  .ease-xxl-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-xxl-linear {
    transition-timing-function: linear !important;
  }

  .ease-xxl-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-xxl-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-xxl-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-xxl-none {
    transform: none !important;
  }

  .scale-xxl-0, .scale-xxl-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }

  .scale-xxl-25, .scale-xxl-25-hover:hover {
    --bs-scale-x: .25 !important;
    --bs-scale-y: .25 !important;
  }

  .scale-xxl-50, .scale-xxl-50-hover:hover {
    --bs-scale-x: .5 !important;
    --bs-scale-y: .5 !important;
  }

  .scale-xxl-75, .scale-xxl-75-hover:hover {
    --bs-scale-x: .75 !important;
    --bs-scale-y: .75 !important;
  }

  .scale-xxl-100, .scale-xxl-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }

  .scale-xxl-105, .scale-xxl-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }

  .scale-xxl-110, .scale-xxl-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }

  .rotate-xxl-0, .rotate-xxl-0-hover:hover {
    --bs-rotate: 0deg !important;
  }

  .rotate-xxl-1, .rotate-xxl-1-hover:hover {
    --bs-rotate: 1deg !important;
  }

  .rotate-xxl-2, .rotate-xxl-2-hover:hover {
    --bs-rotate: 2deg !important;
  }

  .rotate-xxl-3, .rotate-xxl-3-hover:hover {
    --bs-rotate: 3deg !important;
  }

  .rotate-xxl-6, .rotate-xxl-6-hover:hover {
    --bs-rotate: 6deg !important;
  }

  .rotate-xxl-12, .rotate-xxl-12-hover:hover {
    --bs-rotate: 12deg !important;
  }

  .rotate-xxl-30, .rotate-xxl-30-hover:hover {
    --bs-rotate: 30deg !important;
  }

  .rotate-xxl-45, .rotate-xxl-45-hover:hover {
    --bs-rotate: 45deg !important;
  }

  .rotate-xxl-90, .rotate-xxl-90-hover:hover {
    --bs-rotate: 90deg !important;
  }

  .rotate-xxl-180, .rotate-xxl-180-hover:hover {
    --bs-rotate: 180deg !important;
  }

  .rotate-xxl-n1, .rotate-xxl-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }

  .rotate-xxl-n2, .rotate-xxl-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }

  .rotate-xxl-n3, .rotate-xxl-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }

  .rotate-xxl-n6, .rotate-xxl-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }

  .rotate-xxl-n12, .rotate-xxl-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }

  .rotate-xxl-n30, .rotate-xxl-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }

  .rotate-xxl-n45, .rotate-xxl-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }

  .rotate-xxl-n90, .rotate-xxl-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }

  .rotate-xxl-n180, .rotate-xxl-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }

  .rotate-y-xxl-n1, .rotate-y-xxl-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }

  .rotate-y-xxl-n2, .rotate-y-xxl-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }

  .rotate-y-xxl-n3, .rotate-y-xxl-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }

  .rotate-y-xxl-n6, .rotate-y-xxl-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }

  .rotate-y-xxl-n12, .rotate-y-xxl-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }

  .rotate-y-xxl-n30, .rotate-y-xxl-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }

  .rotate-y-xxl-n45, .rotate-y-xxl-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }

  .rotate-y-xxl-n90, .rotate-y-xxl-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }

  .rotate-y-xxl-n180, .rotate-y-xxl-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }

  .rotate-x-xxl-n1 {
    --bs-rotate-x: -1deg !important;
  }

  .rotate-x-xxl-n2 {
    --bs-rotate-x: -2deg !important;
  }

  .rotate-x-xxl-n3 {
    --bs-rotate-x: -3deg !important;
  }

  .rotate-x-xxl-n6 {
    --bs-rotate-x: -6deg !important;
  }

  .rotate-x-xxl-n12 {
    --bs-rotate-x: -12deg !important;
  }

  .rotate-x-xxl-n30 {
    --bs-rotate-x: -30deg !important;
  }

  .rotate-x-xxl-n45 {
    --bs-rotate-x: -45deg !important;
  }

  .rotate-x-xxl-n90 {
    --bs-rotate-x: -90deg !important;
  }

  .rotate-x-xxl-n180 {
    --bs-rotate-x: -180deg !important;
  }

  .translate-y-xxl-0, .translate-y-xxl-0-hover:hover {
    --bs-translate-y: 0 !important;
  }

  .translate-y-xxl-25, .translate-y-xxl-25-hover:hover {
    --bs-translate-y: 25% !important;
  }

  .translate-y-xxl-50, .translate-y-xxl-50-hover:hover {
    --bs-translate-y: 50% !important;
  }

  .translate-y-xxl-75, .translate-y-xxl-75-hover:hover {
    --bs-translate-y: 75% !important;
  }

  .translate-y-xxl-100, .translate-y-xxl-100-hover:hover {
    --bs-translate-y: 100% !important;
  }

  .translate-y-xxl-n25, .translate-y-xxl-n25-hover:hover {
    --bs-translate-y: -25% !important;
  }

  .translate-y-xxl-n50, .translate-y-xxl-n50-hover:hover {
    --bs-translate-y: -50% !important;
  }

  .translate-y-xxl-n75, .translate-y-xxl-n75-hover:hover {
    --bs-translate-y: -75% !important;
  }

  .translate-y-xxl-n100, .translate-y-xxl-n100-hover:hover {
    --bs-translate-y: -100% !important;
  }

  .translate-x-xxl-0, .translate-x-xxl-0-hover:hover {
    --bs-translate-x: 0 !important;
  }

  .translate-x-xxl-25, .translate-x-xxl-25-hover:hover {
    --bs-translate-x: 25% !important;
  }

  .translate-x-xxl-50, .translate-x-xxl-50-hover:hover {
    --bs-translate-x: 50% !important;
  }

  .translate-x-xxl-75, .translate-x-xxl-75-hover:hover {
    --bs-translate-x: 75% !important;
  }

  .translate-x-xxl-100, .translate-x-xxl-100-hover:hover {
    --bs-translate-x: 100% !important;
  }

  .translate-x-xxl-n25, .translate-x-xxl-n25-hover:hover {
    --bs-translate-x: -25% !important;
  }

  .translate-x-xxl-n50, .translate-x-xxl-n50-hover:hover {
    --bs-translate-x: -50% !important;
  }

  .translate-x-xxl-n75, .translate-x-xxl-n75-hover:hover {
    --bs-translate-x: -75% !important;
  }

  .translate-x-xxl-n100, .translate-x-xxl-n100-hover:hover {
    --bs-translate-x: -100% !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.75rem !important;
  }

  .fs-3 {
    font-size: 1.375rem !important;
  }

  .text-xl {
    font-size: 1.5rem !important;
  }

  .text-2xl {
    font-size: 2rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

/*# sourceMappingURL=utility.css.map */
